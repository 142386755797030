import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Autocomplete from '@mui/joy/Autocomplete';

import CircularProgress from '@mui/material/CircularProgress';

//Иконки
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Компоненты 
import AddEditeForm from './AddEditeForm.jsx';
import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';

export default function AMP(props) {

	//Стили
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'16px',color:'black'};
	const filtersStyles = {position:'absolute',width:'100%',top:0,minWidth:0,minHeight:0,height: '100%',px: 0.5,m: 0,borderRadius: '0px',"--Input-decoratorChildHeight": "24px",};
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'18px'};

	const headers =[{id:'acFamily',name:'AC_Family'}, {id:'operatorCodeIcao',name:'Operator_Code_ICAO'},{id:'operatorName',name:'Operator_Name'},{id:'remarks',name:'Remarks'},{id:'logModelId',name:'Log_Model_ID'},{id:'logModelName',name:'Log_Model_Name'},{id:'maintModelId',name:'Maint_Model_ID'},{id:'maintModelName',name:'Maint_Model_Name'},{id:'createUserId',name:'Create_User_ID'},{id:'createDate',name:'Create_Date'},{id:'revision',name:'Revision'},{id:'revisionDate',name:'Revision_Date'},{id:'draft',name:'Draft'},{id:'closeDate',name:'Close_Date'},{id:'closeUserId',name:'Close_User_ID'},{id:'maintenanceData',name:'Maintenance_Data'},{id:'fls56',name:'Supported fls 56'},{id:'fls75',name:'Supported fls 75'}]

	const [data, setData] = React.useState<{id:number, acFamily:string, operatorCodeIcao:string, operatorName:string, remarks:string, logModelId:number, logModelName:string, maintModelId:number, maintModelName:string, createUserId:string, createDate:string, revision:string, revisionDate:string, draft:string, closeDate:string, closeUserId:string, maintenanceData:string, fls56:boolean, fls75:boolean}[]>([]);
	const [filteredData, setFilteredData] = React.useState <{id:number, acFamily:string, operatorCodeIcao:string, operatorName:string, remarks:string, logModelId:number, logModelName:string, maintModelId:number, maintModelName:string, createUserId:string, createDate:string, revision:string, revisionDate:string, draft:string, closeDate:string, closeUserId:string, maintenanceData:string, fls56:boolean, fls75:boolean}[]>([]);
	const [selectedRow, setSelectedRow] = React.useState({id:0});
	const [filters, setFilters] = React.useState({id:0, acFamily:'', operatorCodeIcao:'', operatorName:'', remarks:'', logModelId:0, logModelName:'', maintModelId:0, maintModelName:'', createUserId:'', createDate:'', revision:'', revisionDate:'', draft:'', closeDate:'', closeUserId:'', maintenanceData:''});
	const [showFilters, setShowFilters] = React.useState(false);
	const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
	const [acTypes, setAcTypes] = React.useState([{}]);
	const [acFamilyData, setAcFamilyData] = React.useState([{}]);
	const [showEditor, setShowEditor] = React.useState (true);
	
	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	//Запрос списка AMP
	const fetchData = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/Amp?$filter=acFamily eq '${props.selectedACFamily}'`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setData(result.value)
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};
	
	//Запрос списка Aircraft Types
	const fetchTypes = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/AircraftModels?$select=model,type,engineType,apuType`,
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setAcTypes(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	//Запрос списка Aircraft Types
	const fetchACFamilyData = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/AircraftFamilies/AdditionalInfo()?family='${props.selectedACFamily}'&propertyName=AcFamily`,
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setAcFamilyData(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	React.useEffect(() => {	
		fetchData();
		fetchTypes();
		fetchACFamilyData();
	}, []);

	React.useEffect(() => {
		setSelectedRow(props.selectedAmp)
	}, [props.selectedAmp])

	//Выбор строки
	const handleRowSelection = (row) => {
		if(row.id === selectedRow?.id){
			setSelectedRow({id:0});
			props.setSelectedAmp()
			sessionStorage.removeItem('selectedAmp')
		} else {
			setSelectedRow(row);
			props.setSelectedAmp(row)
			sessionStorage.setItem('selectedAmp', JSON.stringify(row))
		}  
	};
	
	//Фильтрация данных
	React.useEffect(() => {
		let filtered = data;
		for (const key in filters) {
			if(filters[key] != ''){
				filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
			}
		}
        setFilteredData(filtered);
    }, [data, filters]);

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters({id:0, acFamily:'', operatorCodeIcao:'', operatorName:'', remarks:'', logModelId:0, logModelName:'', maintModelId:0, maintModelName:'', createUserId:'', createDate:'', revision:'', revisionDate:'', draft:'', closeDate:'', closeUserId:'', maintenanceData:''});
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Сортировка данных
	const handleSort = (property, value) => {
		let filtered = filteredData
		if (property === 'id'){
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
		} else {
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
		}
		setFilteredData(filtered)
		setSort({lable:property, order:value})
	};

	//Проверка наличия фильтров
	const checkFilters = () => {
		const initialState = {id:0, acFamily:'', operatorCodeIcao:'', operatorName:'', remarks:'', logModelId:0, logModelName:'', maintModelId:0, maintModelName:'', createUserId:'', createDate:'', revision:'', revisionDate:'', draft:'', closeDate:'', closeUserId:'', maintenanceData:''};
		return Object.keys(initialState).every(key => filters[key] === initialState[key]);
	}

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}

	const checkRowColor = (row,index) => {
		if(selectedRow?.id === row.id) return '#c0c0ff'
		if(row.draft === 'Y') return '#ffffc5'
		if(index % 2 === 0){
			return '#EDF2F7'
		} else {
			return 'white'
		}
	}

	//Создание PDF отчёта
	{/*const createReport = () => {
		setReportLoading(true);
		return fetch(`${URL}/reports/Reports`, {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
			},
			body:JSON.stringify({
				"templateName": "Templates/PostgreSQL/Admin/UserRegistrationList",
				"isExportAsPicture": false,
				"parameters": [
					{
						"name": 'userId',
						"value": `${filters.login}`
					},
					{
						"name": 'name',
						"value": `${filters.name}`
					},
					{
						"name": 'code',
						"value": `${filters.code}`
					},
					{
						"name": 'userGroup',
						"value": `${filters.userGroup}`
					},
					{
						"name": 'licence',
						"value": `${filters.licenceNum}`
					},
					{
						"name": 'skill',
						"value": `${filters.skill}`
					},
					{
						"name": 'ac',
						"value": ``
					},
					{
						"name": 'engCategories',
						"value": ``
					},
					{
						"name": 'secGroup',
						"value": `${filters.securityGroup}`
					},
					{
						"name": 'active',
						"value": `${filters.activate}`
					},
					{
						"name": 'storeDbName',
						"value": `Store`
					},
				]
			})
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				errors(response.status);
				setReportLoading(false);
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'UserRegistrationList.pdf'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);

				setReportLoading(false);
			} else {
				setReportLoading(false);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
			setReportLoading(false);
		});
	};
*/}

	return (
		<React.Fragment>
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			<Box
				id='main'
				sx={{
					position:'relative',
					height:'100%',
					display:'grid',
					gridTemplateColumns:showEditor?'auto 600px':'100%',
				}}
			>
				<Box
					id='tableArea'
					sx={{
						position:'relative',
					}}
				>
					<Typography 
						id='tableAreaTitle'
						sx={{ 
							position:'absolute',
							fontSize:'16px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif',
							left:16
						}}
					>
						Aircraft Maintenance Program:
					</Typography>	
					<Box
						id='buttons'
						sx={{
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							position:'absolute',
							right:16,
							height:40
						}}
					>
						<Tooltip title='Reset Filters'>
							<IconButton
								id='refreshButton'
								variant='plain'
								onClick={refreshFilters}
								sx={{
									m:0,
								}}
							>
								<img 
									width='35px' 
									src="/images/svg/refresh.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
						<Tooltip title= {showEditor? 'Hide Editor' : 'Show Editor'}>
							<IconButton
								id='editoreButton'
								variant='plain'
								onClick={toggleEditore} 
								sx={{
									m:0,
									transition: '0.3s',
									transform: showEditor? 'rotate(0deg)' : 'rotate(180deg)',
								}}
							>
								<img 
									width='35px' 
									src="/images/svg/arrowBack.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
					</Box>	
					<Box
						onClick={() => setShowFilters(!showFilters)}
						sx={{
							position:'absolute',
							width: `calc(100% - 32px)`,
							mx:'16px',
							mt:'44px',
							mb:'10px',
							border:'1px solid black',
							borderBottom:'none',
							height:'24px',
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							bgcolor:checkFilters()?'transparent':'#DDE7EE',
							
						}}
					>
						<ArrowDropDownIcon 
							sx={{
								fontSize: '24px',
								transition: '0.2s',
								transform: !showFilters ? 'rotate(0deg)' : 'rotate(180deg)',
							}}
						/>
					</Box>	
					<Sheet
						id='tablesSheet'
						variant="outlined"
						sx={{
							position:'absolute',
							width: `calc(100% - 32px)`,
							height:`calc(100% - 88px)`,
							borderRadius: '0px',
							flexShrink: 1,
							overflow: 'auto',
							border: '1px solid black',
							bgcolor:'#848284',
							mx:'16px',
							mt:'68px',
							mb:'10px'
						}}
					>
						
					
						<Table
							id='table'
							borderAxis="both"
							stickyHeader
							hoverRow
							sx={{
								'--TableCell-headBackground': '#d6d3ce',
								'--Table-headerUnderlineThickness': '1px',
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '1px',
								'--TableCell-height': '29px',
								'--TableCell-paddingX': '5px',
								'--TableCell-borderColor': '#939293',
								tableLayout: 'auto', 
								width:'auto',
								borderRadius:'0px'
							}}
						>
							<thead>
								<tr>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('id', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'id' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
												fontWeight:filters.id != 0 ? 'bold' : 'normal'
											}}
										>
											ID
										</Typography>
									</th>
									{headers.map((row) => (
										<th style={headerStyle}>
											<Typography
												onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
												endDecorator={<ArrowDropDownIcon />}
												sx={{
													'& svg': {
														color:sort.lable === row.id ? '#636b74' : 'transparent',
														transition: '0.2s',
														transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
													},
													fontWeight:filters[row.id] ? 'bold' : 'normal'
												}}
											>
												{row.name}
											</Typography>
										</th>
									))}
								</tr>
								{showFilters && (
									<tr>
										<th style={{padding:0}}>
											<Input
												value={filters.id}
												onChange={(event) => handleChange('id', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}
												}}
												sx={filtersStyles}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.acFamily}
												onChange={(event) => handleChange('acFamily', event.target.value)}
												sx={filtersStyles}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.operatorCodeIcao}
												onChange={(event) => handleChange('operatorCodeIcao', event.target.value)}
												sx={filtersStyles}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.operatorName}
												onChange={(event) => handleChange('operatorName', event.target.value)}
												sx={filtersStyles}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.remarks}
												onChange={(event) => handleChange('remarks', event.target.value)}
												sx={filtersStyles}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.logModelId}
												onChange={(event) => handleChange('logModelId', event.target.value)}
												sx={filtersStyles}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.logModelName}
												onChange={(event) => handleChange('logModelName', event.target.value)}
												sx={filtersStyles}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.maintModelId}
												onChange={(event) => handleChange('maintModelId', event.target.value)}
												sx={filtersStyles}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.maintModelName}
												onChange={(event) => handleChange('maintModelName', event.target.value)}
												sx={filtersStyles}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.createUserId}
												onChange={(event) => handleChange('createUserId', event.target.value)}
												sx={filtersStyles}
											/>
										</th>
										<th style={{padding:0}}>
											<Box
												sx={{
													display:'flex',
													alignItems:'center'
												}}
											>
												From:
												<Input
													type="date"
													sx={{
														width:'130px',
														minWidth:'0px',
														height:'100%',
														pl:0.5,
														borderRadius:'0px',
														ml:0.2,
														mr:2
													}}
													value={filters.createDateFrom}  
													onChange={(event) => handleChange('createDateFrom', event.target.value)}
												/>
												-
												To:
												<Input
													type="date"
													sx={{
														width:'130px',
														minWidth:'0px',
														height:'100%',
														pl:0.2,
														borderRadius:'0px',
														ml:0.2,
													}}
													value={filters.createDateTo} 
													onChange={(event) => handleChange('createDateTo', event.target.value)}
												/>
											</Box>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.revision}
												onChange={(event) => handleChange('revision', event.target.value)}
												sx={filtersStyles}
											/>
										</th>
										<th style={{padding:0}}>
											<Box
												sx={{
													display:'flex',
													alignItems:'center'
												}}
											>
												From:
												<Input
													type="date"
													sx={{
														width:'130px',
														minWidth:'0px',
														height:'100%',
														pl:0.5,
														borderRadius:'0px',
														ml:0.2,
														mr:2
													}}
													value={filters.revisionDateFrom}  
													onChange={(event) => handleChange('revisionDateFrom', event.target.value)}
												/>
												-
												To:
												<Input
													type="date"
													sx={{
														width:'130px',
														minWidth:'0px',
														height:'100%',
														pl:0.2,
														borderRadius:'0px',
														ml:0.2,
													}}
													value={filters.revisionDateTo} 
													onChange={(event) => handleChange('revisionDateTo', event.target.value)}
												/>
											</Box>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.draft))].filter(row => row).map(draft => ({ label: draft }))}
												onChange={(event, value) => handleChange('draft', value ? value.label : '')}
												value={filters.draft === '' ? null : { label: filters.draft }}
												sx={filtersStyles}
											/>
										</th>
										<th style={{padding:0}}>
											<Box
												sx={{
													display:'flex',
													alignItems:'center'
												}}
											>
												From:
												<Input
													type="date"
													sx={{
														width:'130px',
														minWidth:'0px',
														height:'100%',
														pl:0.5,
														borderRadius:'0px',
														ml:0.2,
														mr:2
													}}
													value={filters.closeDateFrom}  
													onChange={(event) => handleChange('closeDateFrom', event.target.value)}
												/>
												-
												To:
												<Input
													type="date"
													sx={{
														width:'130px',
														minWidth:'0px',
														height:'100%',
														pl:0.2,
														borderRadius:'0px',
														ml:0.2,
													}}
													value={filters.revisionDateTo} 
													onChange={(event) => handleChange('closeDateTo', event.target.value)}
												/>
											</Box>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.closeUserId}
												onChange={(event) => handleChange('closeUserId', event.target.value)}
												sx={filtersStyles}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.maintenanceData}
												onChange={(event) => handleChange('maintenanceData', event.target.value)}
												sx={filtersStyles}
											/>
										</th>
									</tr>
								)}
							</thead>
							<tbody>
								{filteredData.length == 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
									</th>
								)}
								{filteredData.map((row, index) => (
									<tr
										key={row.id}
										onClick={() => handleRowSelection(row)}
										style={{ 
											backgroundColor: checkRowColor(row, index),
											color:'black',
										}}
									>
										<td style={{...rowStyle, backgroundColor:'#c6c3c6', zIndex: 1}}>{row.id}</td>
										<td style={rowStyle}>{row.acFamily}</td>
										<td style={rowStyle}>{row.operatorCodeIcao}</td>
										<td style={rowStyle}>{row.operatorName}</td>
										<td style={rowStyle}>{row.remarks}</td>
										<td style={rowStyle}>{row.logModelId}</td>
										<td style={rowStyle}>{row.logModelName}</td>
										<td style={rowStyle}>{row.maintModelId}</td>
										<td style={rowStyle}>{row.maintModelName}</td>
										<td style={rowStyle}>{row.createUserId}</td>
										<td style={rowStyle}>{row.createDate.slice(0,10)}</td>
										<td style={rowStyle}>{row.revision}</td>
                                        <td style={rowStyle}>{row.revisionDate.slice(0,10)}</td>
                                        <td style={rowStyle}>{row.draft}</td>
										<td style={rowStyle}>{row.closeDate}</td>
										<td style={rowStyle}>{row.closeUserId}</td>
                                        <td style={rowStyle}>{row.maintenanceData}</td>
										<td style={rowStyle}>{row.fls56.toString()}</td>
										<td style={rowStyle}>{row.fls75.toString()}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
							position:'absolute',
							bottom:0,
							left:'16px',
							fontSize:'12px'
						}}
					>
						Found {filteredData.length} Records
					</Box>
				</Box>
				<Box
					id='editFormArea'
					sx={{
						display:showEditor?'flex':'none',
						position:'relative',
					}}
				>
					<AddEditeForm
						URL={props.URL}
						selectedRow={selectedRow} 
						reloadTable={fetchData}
						setResponseStatus={setResponseStatus}
						acTypes={acTypes}
						activeAMP={data.find(data => data.draft === 'N')?.id}
						selectedACFamily={props.selectedACFamily}
					/>
				</Box>
			</Box>
		</React.Fragment>
	);
}
