import React from "react";

import URL from '../../../../../../URL.js';
import Tree, { useTreeState } from "../../../../../Base/TreeView/index.tsx";

import { ITreeViewIcon } from "../../../../../Base/TreeView/IconInterpterer/ITreeViewIcon.ts";
import { AmpModelIconInterpreter } from "./IconInterpreters/AmpModelIconInterpreter.ts";
import { Interpreter } from "../../../../../Base/TreeView/IconInterpterer/Interpreter.ts";
import { IIconContext } from "../../../../../Base/TreeView/IconInterpterer/IIconContext.ts";
import { filledInputClasses } from "@mui/material";

export default function AmpPlanTree(props) {
	const [responseStatus, setResponseStatus] = React.useState({
		errorStatus:0, 
		successfulStatus:''
	});
	const [treeData, setTree] = React.useState<{
		id: number, 
		image: ITreeViewIcon, 
		header: string, 
		children: any
	}[]>([]);
	const { required, handlers } = useTreeState({ 
		id: 'tree', 
		data: treeData, 
		defaultOpened: [1, 2], 
		multipleSelect: false ,
	});

	const [data, setData] = React.useState([])
	// Задание интерпретатора для выбора иконок и базовой структуры дерева
	const iconInterpreter = new AmpModelIconInterpreter();
	const treeBaseData: any[] = [{
		isRootData: true,
		id: 1,
		image: iconInterpreter.Interpret(getTreeViewIconParams(undefined, true)),
		header: { id: { value: `${props.acFamily}; ${props.selectedOperator}` } },
		children: [{
			id: 2,
			image: iconInterpreter.Interpret(getTreeViewIconParams(undefined, undefined, true)),
			header: { id: { value: `${props.acFamily}; ${props.selectedOperator}: AMP Checks Model`, width: 350 } },
			children: []
		}]
	}];

	// Обновление данных
	React.useEffect(() => {
		//setTree([]);
        async function temp() {
			await fetchData();
		}
		temp();
		//fetchTreeData(iconInterpreter);
		
    }, [props.taskFilter, props.checkFilter, props.ampId]);

	const fetchData = async() => {
		const response = await fetchResponseAsync();
		const responeOutCheck = await fetchOutCheckTasks()
		if (response.ok && responeOutCheck.ok) {
			const result = await response.json();
			const resultOutCheck = await responeOutCheck.json();
			const combineResult = [...result,...resultOutCheck]
			setData(combineResult)
			props.setChecksCount(result.length)
			props.setOutOfChecksCount(resultOutCheck.length)
		} 
		else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	}

	React.useEffect(() => {
		fetchTreeData(iconInterpreter);
    }, [data, props.sliceCount]);

	// Получение общей информации для дерева
	function fetchTreeData (iconInterpreter: Interpreter<IIconContext>) {
		if (treeBaseData.length == 0) return;

		treeBaseData[0].children[0].children = [];
		
		data?.slice(0, props.sliceCount)?.forEach(element => {
			const treeViewIconParams = getTreeViewIconParams(element);
			const headerParams = getNodeHeaderParams(element);

			const child = getChildNode(
				iconInterpreter.Interpret(treeViewIconParams),
				headerParams,
				true
			);
			child.model = element;
			treeBaseData[0].children[0].children.push(child);
		});

		setTree(treeBaseData);
			
	};

	// Получение информации о children
	const fetchTreeChildrenData = async (node: any, iconInterpreter: Interpreter<IIconContext>) : Promise<any> => {
		if(node?.data?.header?.checkId){
			const result = await fetchChildElements(node);

			let childNode = { children: [{}] };
			childNode.children = [];

			var childAdditions: { [id: string]: any } = {};
			result?.forEach(element => {
				const treeViewIconParams = getTreeViewIconParams(element);
				const headerParams = getChildrenNodeHeaderParams(element);

				if(element?.task){
					const child = {
						id: 4,
						image:iconInterpreter.Interpret(treeViewIconParams),
						header: headerParams,
						maxWidth: 500,
						children:[{id:4, header:''}]
					};
					childNode.children.push(child);	
				} else {
					const child = {
						id: 4,
						header: headerParams,
						maxWidth: 500
					};
					childNode.children.push(child);	
				}
				
				if (element.description)
					childAdditions[element.description] = getModel(element);
			});

			return { childNode: childNode, childAdditions: childAdditions };
		} else {
			const response = await fetchResponseAsync(true, node);
			if (response.ok) {
				const result = await response.json();
				let childNode = { children: [{}] };
				childNode.children = [];
	
				var childAdditions: { [id: string]: any } = {};
				result?.forEach(element => {
					const headerParams = getChildrenNodeHeaderParams(element);
	
					const child = {
						id: 5,
						header: headerParams,
						maxWidth: 500
					};
					childNode.children.push(child);
	
					if (element.description)
						childAdditions[element.description] = getModel(element);
				});
	
				return { childNode: childNode, childAdditions: childAdditions };
			} 
			else {
				setResponseStatus({...responseStatus, errorStatus:response.status});
			}
		}
	};

	function getModel(element: any = null): any {
		return {
			description: element.description,
			fh: element.fh,
			fc: element.fc,
			dy: element.calendar == 'DY',
			mo: element.calendar == 'MO',
			yr: element.calendar == 'YR',
			calendarValue: element.calendarValue
		};
	}

	const fetchResponseAsync = async (isChild: boolean = false, node: any = undefined): Promise<Response> => {
		var url = isChild
			? node.data?.header?.task 
			?`${URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/TaskTreatments(taskId=${node.data?.header?.id?.value})`
			:`${URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/CheckTreatments(checkId=${node.data?.header?.id?.value})`
			: `${URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/CheckRoot(ampId=${props.ampId},taskFilter='${props?.taskFilter || ''}',checkFilter='${props?.checkFilter || ''}',fhFilter=${props?.fhFilter || 0},fcFilter=${props?.fcFilter || 0},calendarKindFilter='${props?.calendarKindFilter || 'DY'}',calendarValueFilter=${props?.calendarValueFilter || 0},resourceOrFIlter=${props?.resourceOrFIlter || false})`;
		const headers = { 
			headers: { 'Authorization': `Bearer ${localStorage.getItem('asid')}` }
		};

		return await fetch(url, headers);
    }

	const fetchChildElements = async (node: any = undefined) => {
		const treatmentsRespons = await fetch (
			`${URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/CheckTreatments(checkId=${node.data?.header?.id?.value})`,
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}}
		)
		const treatments = await treatmentsRespons.json()
		const tasksRespons = await fetch (
			`${URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/CheckInnerTasks(ampId=${props.ampId},checkId=${node.data?.header?.id?.value},taskFilter='')`,
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}}
		)
		const tasks = await tasksRespons.json()

		return [...treatments,...tasks];
    }

	const fetchOutCheckTasks = async (): Promise<Response> => {
		var url = `${URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/OutCheckTasks(ampId=${props.ampId},taskFilter='',fhFilter=${0},fcFilter=${0},calendarKindFilter='DY',calendarValueFilter=${0},resourceOrFIlter=false)`;
		const headers = { 
			headers: { 'Authorization': `Bearer ${localStorage.getItem('asid')}` }
		};

		return await fetch(url, headers);
    }

	function getTreeViewIconParams(element: any = null, isRootProject: boolean = false, isRootPlane: boolean = false): any {
		return { 
			isCyclicCheck: element?.isCyclicCheck,
			isRootProject: isRootProject,
			isRootPlane: isRootPlane,
			isInnerTask: element?.task ? true:false
		};
	}

	function getNodeHeaderParams(element: any): any {
		if (element?.task){
			return { 
				id: { value: element.id, width: 70 }, 
				task: { value: element.task, width: 130 }, 
				taskTitle: { value: element.taskTitle, width: 350 }, 
				taskEffectivity: { value: element.taskEffectivity, width: 130 }, 
				note: { value: element.note, width: 350 }, 
			}
		} else {
			return { 
				id: { value: element.id, width: 70 }, 
				checkId: { value: element.checkId, width: 130 }, 
				checkDescription: { value: element.checkDescription, width: 350 }, 
				isMajorCheck: element.isMajorCheck
					? { value: `(Major Check)`, width: 110 }
					: { value: '', width: 110 }
			}
		}
	}

	function getChildrenNodeHeaderParams(element: any): any {
		if(element?.description){
			var typeString = '';
			var description = `${element.description}: `;
			var fhString = `${element.fh} FH; `;
			var fcString = `${element.fc} FC; `;
			var calendarString = `${element.calendarValue} ${element.calendar}; `;
			
			typeString = typeString.concat(typeString, 
				element.description != null ? description : '',
				element.fh != null ? fhString : '',
				element.fc != null ? fcString : '',
				element.calendarValue != null ? calendarString : ''
			);

			return { 
				type: { value: typeString, width: 500 }, 
				pnEff: element.pn != null 
					? { value: `PN Eff.: ${element.pn}` }
					: { value: '' }
			};
		} else {
			return { 
				id: { value: element.id, width: 70 }, 
				task: { value: element.task, width: 130 }, 
				taskTitle: { value: element.taskTitle, width: 350 }, 
				taskEffectivity: { value: element.taskEffectivity, width: 130 }, 
			};
		}
	}

	function getChildNode(interpreterResult: any, headerParams: any, conditionItem: any = undefined): any {
		const isExtended = conditionItem;
		const simpleChild = {
			id: 3,
			image: interpreterResult,
			header: headerParams
		};
		const extendedChild = {
			...simpleChild,
			children: [{
				...simpleChild,
				header: { id: { value: 'No content' } }
			}]
		};

		return isExtended ? extendedChild : simpleChild;
	}

	const openNodeAsync = async (node) => {
		if (!node.isOpened() && node.data.header?.id?.value > 0) {
			const child = await fetchTreeChildrenData(node, iconInterpreter);
			handlers.setRawChildren(node, child.childNode.children, undefined, true);
		}
		handlers.setOpen(node);
	}

	// Lazy loading
	React.useEffect(() => {
		let data = required?.data[0]?.children[0]?.children?.slice(0,props.sliceCount)
		if(required?.data[0]?.children[0]?.children){
			required.data[0].children[0].children = data;
		}
		
    }, [props.sliceCount,required]);

	return (
      	<Tree
			{...required}
			{...handlers}
			gapMode="padding" 
			depthGap={20}
			setSelected={async (node, selected) => {
				handlers.setSelected(node, selected);
				if(selected){
					if(node.id === 3 && node.data.header.checkId){
						props.setSelectedCheckId(node?.data?.header?.id?.value)	
						props.setSelectedTaskId(null)
					} else if (node.id === 4 && node.options.parent.data.header.checkId){
						props.setSelectedCheckId(node.options.parent.data.header.id.value)	
						if(node.data.header.task){
							props.setSelectedTaskId(node.data.header.id.value)
						} else {
							props.setSelectedTaskId(null)
						}
					} else if (node.id === 5 && node.options.parent.options.parent.data.header.checkId){
						props.setSelectedCheckId(node.options.parent.options.parent.data.header.id.value)
						props.setSelectedTaskId(node.options.parent.data.header.id.value)
					} else {
						props.setSelectedCheckId(null)
						props.setSelectedTaskId(null)
					}
				}else{
					props.setSelectedCheckId(null)
					props.setSelectedTaskId(null)
				}
				
			}}
			setOpen={openNodeAsync}
		/>
    );
}