import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Radio from '@mui/joy/Radio';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

//Иконки
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

//Компоненты 
import Requirements from './Requirements.tsx';
import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';
import AmpPlanTree from './TreeViews/AmpPlanTree.tsx';
import Spiner from '../../../Components/Spiner.tsx';
import ConfirmAction from '../ConfirmAction.tsx';

export default function Plan(props) {

	//Стили
	const inputStyle = {borderRadius:'0px', minHeight:'0px', height:'26px', border:'1px solid black',color:'#000078', fontWeight:'bold',fontSize:'14px', mb:1,}
	const lableStyle = {m:0, fontFamily:'Arial, sans-serif', fontSize:'16px',alignSelf:'center'};

	const [filters, setFilters] = React.useState<{task:string, check:string}>();
	const [intervalFilters, setIntervalFilters] = React.useState<{ fh:number, fc:number, calendarKind:string, calendarValue:number, resourceOr:boolean}>();
	const [checksList, setChecksList] = React.useState ([]);
	const [outOfChecksCount, setOutOfChecksCount] = React.useState(0)
	const [checksCount, setChecksCount] = React.useState(0)
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [showEditor, setShowEditor] = React.useState (true);
	const [showIntervalFilters, setShowIntervalFilters] = React.useState(false)
	const [selectedCheckId,setSelectedCheckId] = React.useState<number>()
	const [selectedTaskId,setSelectedTaskId] = React.useState<number>()

	const [showConfirm, setShowConfirm] = React.useState(false)
	const [confirmData, setConfirmData] = React.useState({title:'title', icon:'question', info:'info'})

	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	const [showSpinner, setShowSpinner] = React.useState(0)

	//Запрос списка Checks
	React.useEffect(() => {
		const fetchChecks = async () => {
			setShowSpinner(value => value + 1);
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks?$filter=ampid eq ${props?.selectedAmp?.id}&$select=check`,
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setChecksList(result.value);
			} else {
				setResponseStatus({...responseStatus, errorStatus:response.status});
			}
			setShowSpinner(value => value - 1);
		};
		if(props?.selectedAmp?.id) fetchChecks();
	}, [props?.selectedAmp])

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters(undefined);
		setIntervalFilters(undefined);
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Изменение фильтров interval
	const handleChangeInterval = (property, value) => {
		setIntervalFilters({ ...intervalFilters, [property]: value });
	};

	//Подтверждение Interval Filters
	const confirmIntervalFilters = () => {

		setShowIntervalFilters(false)
	}

	//Закрытие окна Interval Filters
	const cancelIntervalFilters = () => {
		setIntervalFilters(undefined)
		setShowIntervalFilters(false)
	}

	//Сброс Interval Filters
	const resetIntervalFilters = () => {
		setIntervalFilters(undefined)
		setShowIntervalFilters(false)
	}

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}

	//Обработчик события добавления новой записи
	const handleAddToPlan = async () =>  {
		selectedRows.map(mrid=> {
			fetch(
				`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/MoveTaskIntoMp`, 
				{
					method: 'POST',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						request : 
						{
							ampid : props.selectedAmp.id,
							mrid : mrid,
							checkid : selectedCheckId
						}
					})
				}
			)
			.then((response) => {
				if (!response.ok){
					//props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					//props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
				}
				//props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка создания группы:', error));
		})
	}

	//Проверка используется ли таска в планах обслеживания
	const fetchIsTaskUsed = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/TaskUsedOnActual(ampId=${props.selectedAmp.id},mrId=${selectedTaskId})`,
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			return result.value
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}

	};

	//Обработчик события удаления записи
	const handleRemoveFromPlan = async () => {
		if(await fetchIsTaskUsed()){
			fetch(
				`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/RemoveTaskFromMp(ampId=${props.selectedAmp.id},mrId=${selectedTaskId},removeFromActual={true/false})`, 
				{
					method: 'DELETE',
					headers: {
						'Authorization': `Bearer ${localStorage.getItem('asid')}`,
						'Content-Type': 'application/json'
					},
				}
			)
			.then((response) => {
				if (!response.ok){
					//props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					//props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
				}
				//props.reloadTable();
				
			})
			.catch(error => console.error('Ошибка создания группы:', error));
		} else {
			fetch(
				`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/RemoveTaskFromMp(ampId=${props.selectedAmp.id},mrId=${selectedTaskId},removeFromActual=true)`, 
				{
					method: 'DELETE',
					headers: {
						'Authorization': `Bearer ${localStorage.getItem('asid')}`,
						'Content-Type': 'application/json'
					},
				}
			)
			.then((response) => {
				if (!response.ok){
					//props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					//props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
				}
				//props.reloadTable();
			})
			.catch(error => console.error('Ошибка создания группы:', error));
		}
	}
	
	//#region Lazy loading для дерева
	const [loading, setLoading] = React.useState(false); // Состояние загрузки
	const tableRef = React.useRef(null); // Ссылка на элемент таблицы
	const [sliceCount, setSliceCount] = React.useState(100)
	
	const handleScroll = () => {
		if (tableRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
			if (scrollTop + clientHeight >= scrollHeight - 5 && !loading) {
				setSliceCount(value=>value+50)
			}
		}
	};

	React.useEffect(() => {
		const tableElement = tableRef.current;
		if (tableElement) {
			tableElement.addEventListener('scroll', handleScroll);
		}
		return () => {
			if (tableElement) {
				tableElement.removeEventListener('scroll', handleScroll);
			}
		};
	}, [loading]);
	//#endregion
	return (
		<React.Fragment>
			<ConfirmAction showConfirm={showConfirm} setShowConfirm={setShowConfirm} confirmData={confirmData}/>
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			{showSpinner !== 0 && (<Spiner/>)}
			<Box
				id='main'
				sx={{
					position:'relative',
					height:'100%',
					display:'grid',
					gridTemplateColumns:showEditor?'50% 50%':'100%',
				}}
			>
				<Box
					id='tableArea'
					sx={{
						position:'relative',
					}}
				>
					<Typography 
						id='tableAreaTitle'
						sx={{ 
							position:'absolute',
							fontSize:'16px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif',
							left:16
						}}
					>
						Maintenance Plan:
					</Typography>
					<Box
						id='buttons'
						sx={{
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							position:'absolute',
							right:16,
							height:40
						}}
					>
						<Tooltip title='Reset Filters'>
							<IconButton
								variant='plain'
								onClick={refreshFilters}
								sx={{
									m:0,
								}}
							>
								<img 
									width='35px' 
									src="/images/svg/refresh.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
						<Tooltip title= {showEditor? 'Hide Editor' : 'Show Editor'}>
							<IconButton
								variant='plain'
								onClick={toggleEditore} 
								sx={{
									m:0,
									transition: '0.3s',
									transform: showEditor? 'rotate(0deg)' : 'rotate(180deg)',
								}}
							>
								<img 
									width='35px' 
									src="/images/svg/arrowBack.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
					</Box>	
					<Box
						id='filters'
						sx={{
							display:'flex',
							flexWrap: 'nowrap',
							gap: 0.5,
							alignItems:'flex-end',
							mt:'20px',
							mx:'16px',
						}}
					>
						<FormControl>
							<FormLabel sx={lableStyle}>Task:</FormLabel>
							<Input
								value={filters?.task || ''}
								onChange={(event) => handleChange('task', event.target.value)}
								sx={{...inputStyle, width:200}}
							/>
						</FormControl>
						<Box
							sx={{
								display:'flex',
								flexDirection:'column',
								mb:1,
								textOverflow: 'ellipsis', 
								overflow: 'hidden',
								width:'40px'
							}}
						>
							<Typography
								sx={{
									fontSize:10,
									lineHeight:1,
									display: !intervalFilters ? 'flex':'none'
								}}
							>
								No Filter
							</Typography>
							<Typography
								sx={{
									fontSize:10,
									lineHeight:1,
									display: intervalFilters?.fh ? 'flex':'none',
								}}
							>
								FH:{intervalFilters?.fh}
							</Typography>
							<Typography
								sx={{
									fontSize:10,
									lineHeight:1,
									display: intervalFilters?.fc ? 'flex':'none'
								}}
							>
								FC:{intervalFilters?.fc}
							</Typography>
							<Typography
								sx={{
									fontSize:10,
									lineHeight:1,
									display: intervalFilters?.calendarKind && intervalFilters?.calendarValue ? 'flex':'none'
								}}
							>
								{intervalFilters?.calendarKind}:{intervalFilters?.calendarValue}
							</Typography>
							<Typography
								sx={{
									fontSize:10,
									lineHeight:1,
									display: intervalFilters?.resourceOr && (intervalFilters?.fh || intervalFilters?.fc ) ? 'flex':'none'
								}}
							>
								Logic:OR
							</Typography>
						</Box>
						<Box
							sx={{
								position:'relative'
							}}
						>
							<Tooltip title= 'Open Interval, Check Filters'>
								<Button	
									onClick={()=>setShowIntervalFilters(!showIntervalFilters)}	
									color='neutral'
									sx={{
										borderRadius:0,
										height: 30, 
										minHeight: 0,
										width:50,
										whiteSpace:'wrap',
										fontSize:12,
										mx:1,
										mb:1,
										bgcolor:'#c7c3fe',
										color:'black',
										border:'2px outset grey',
										lineHeight:1
									}}
								>
									Interval
								</Button>
							</Tooltip>
							{showIntervalFilters && (
								<Box
									sx={{
										position:'absolute',
										height:240,
										width:260,
										bgcolor:'#d6d3ce',
										border:'1px solid black',
										zIndex:999,
										left:'-100px',
										top:40,
										alignItems:'center'
									}}
								>
									<Grid container spacing={1} sx={{ flexGrow: 1, px:2, py:1 }}>
										<Grid xs={2} sx={{color:'black'}}>
											FH:
										</Grid>
										<Grid xs={10}>
											<Input
												value={intervalFilters?.fh || ''}
												onChange={(event) => handleChangeInterval('fh', event.target.value)}
												sx={{...inputStyle}}
											/>
										</Grid>

										<Grid xs={2} sx={{color:'black'}}>
											FC:
										</Grid>
										<Grid xs={10}>
											<Input
												value={intervalFilters?.fc || ''}
												onChange={(event) => handleChangeInterval('fc', event.target.value)}
												sx={{...inputStyle}}
											/>
										</Grid>

										<Grid xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:0.5}}>
											<Typography sx={{color:'black',fontSize:16}}>
												DY:
											</Typography>
											<Tooltip title='Days Selector'>
												<Checkbox 
													variant="outlined"
													color="neutral"
													sx={{
														fontSize:'14px',
														'--Checkbox-size':'15px',
														gap:0.4,
														'& .css-r4cpcz-JoyCheckbox-checkbox': {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
														'& .css-n110n' : {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
													}}
													checked={intervalFilters?.calendarKind === 'DY'}
													onChange={() => handleChangeInterval('calendarKind', 'DY')}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:0.5}}>
											<Typography sx={{color:'black',fontSize:16}}>
												MO:
											</Typography>
											<Tooltip title='Months Selector'>
												<Checkbox 
													variant="outlined"
													color="neutral"
													sx={{
														fontSize:'14px',
														'--Checkbox-size':'15px',
														gap:0.4,
														'& .css-r4cpcz-JoyCheckbox-checkbox': {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
														'& .css-n110n' : {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
													}}
													checked={intervalFilters?.calendarKind === 'MO'}
													onChange={() => handleChangeInterval('calendarKind', 'MO')}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:0.5}}>
											<Typography sx={{color:'black',fontSize:16}}>
												YR:
											</Typography>
											<Tooltip title='Years Selector'>
												<Checkbox 
													variant="outlined"
													color="neutral"
													sx={{
														fontSize:'14px',
														'--Checkbox-size':'15px',
														gap:0.4,
														'& .css-r4cpcz-JoyCheckbox-checkbox': {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
														'& .css-n110n' : {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
													}}
													checked={intervalFilters?.calendarKind === 'YR'}
													onChange={() => handleChangeInterval('calendarKind', 'YR')}
												/>
											</Tooltip>
										</Grid>
												
										<Grid xs={2}></Grid>
										<Grid xs={8}>
											<Input
												value={intervalFilters?.calendarValue || ''}
												onChange={(event) => handleChangeInterval('calendarValue', event.target.value)}
												sx={{...inputStyle}}
											/>
										</Grid>
										<Grid xs={2}></Grid>

										<Grid xs={2}></Grid>
										<Grid xs={4}>
											<Radio
												color='neutral' 
												variant='outlined'
												label='And'
												checked={!intervalFilters?.resourceOr || false}
												onChange={() => handleChangeInterval('resourceOr', false)}
											/>
										</Grid>	
										<Grid xs={4}>
											<Radio
												color='neutral' 
												variant='outlined'
												label='Or'
												checked={intervalFilters?.resourceOr || false}
												onChange={() => handleChangeInterval('resourceOr', true)}
											/>
										</Grid>	
										<Grid xs={2}></Grid>

										<Grid xs={12} sx={{display:'flex', justifyContent:'center'}}>
											<Button
												onClick={confirmIntervalFilters}	
												sx={{
													borderRadius:0,
													height: 25, 
													minHeight: 0,
													bgcolor:'#d6d3ce',
													color:'black',
													border:'2px outset grey',
												}}
											>
												Ok
											</Button>
											<Button
												onClick={cancelIntervalFilters}	
												sx={{
													borderRadius:0,
													height: 25, 
													minHeight: 0,
													bgcolor:'#d6d3ce',
													color:'black',
													border:'2px outset grey',
												}}
											>
												Cancel
											</Button>
											<Button
												onClick={resetIntervalFilters}
												sx={{
													borderRadius:0,
													height: 25, 
													minHeight: 0,
													bgcolor:'#d6d3ce',
													color:'black',
													border:'2px outset grey',
												}}
											>
												Reset
											</Button>
										</Grid>
									</Grid>
								</Box>
							)}
						</Box>
						<FormControl>
							<FormLabel sx={lableStyle}>Check:</FormLabel>
							<Autocomplete
								options={checksList.map(data=> ({ label: data?.check}))}
								sx={{...inputStyle, width:200}}
								onChange={(event, value) => handleChange('check', value ? value.label : '')}
								value={{ label: filters?.check || ''}}
							/>
						</FormControl>
					</Box>
					<Box 
						sx={{
							width: '100%',
						}}
					>
						<Sheet
							id='tablesSheet'
							variant="outlined"
							ref={tableRef}
							sx={{
								position:'absolute',
								width: showEditor?`calc(100% - 86px)`:`calc(100% - 32px)`,
								height:`calc(100% - 105px)`,
								borderRadius: '0px',
								flexShrink: 1,
								overflow: 'auto',
								border: '1px solid black',
								bgcolor:'white',
								mx:'16px',
							}}
						>
							<AmpPlanTree
								setSelectedCheckId={setSelectedCheckId}
								setSelectedTaskId={setSelectedTaskId}
								setOutOfChecksCount={setOutOfChecksCount}
								setChecksCount={setChecksCount}
								selectedOperator={props.selectedOperator}
								acFamily={props.selectedACFamily}
								ampId={props?.selectedAmp?.id}
								taskFilter={filters?.task ?? ''}
								checkFilter={filters?.check ?? ''}
								fhFilter={0}
								fcFilter={0}
								calendarKindFilter=''
								calendarValueFilter={0}
								resourceOrFIlter={true}
								sliceCount={sliceCount}
							/>
						</Sheet>
						<Box
							sx={{
								display: 'flex',
								position:'absolute',
								width: `calc(100% - 32px)`,
								height:`20px`,
								bottom:5,
								mx:'16px',
								alignItems:'center',
								justifyContent:'flex-start',
							}}
						>
							{checksCount !== 0 ? (
								<Typography
									sx={{
										fontSize:'12px',
									}}
								>
									Found {checksCount} Cheks;
								</Typography>
							):(
								<Typography
									sx={{
										fontSize:'12px',
									}}
								>
									No Cheks were Found!;
								</Typography>
							)}
							{outOfChecksCount !== 0 ? (
								<Typography
									sx={{
										fontSize:'12px',
									}}
								>
									Found {outOfChecksCount} Out of Check Tasks
								</Typography>
							):(
								<Typography
									sx={{
										fontSize:'12px',
									}}
								>
									No Out of Check Tasks were Found!
								</Typography>
							)}

							<Typography
								sx={{
									fontSize:'12px',
								}}
							>
								Displayed {sliceCount}
							</Typography>
						</Box>
						<Box
							sx={{
								position:'absolute',
								right:0,
								display:showEditor?'flex':'none',
								flexDirection:'column',

							}}
						>
							<Button	
								onClick={()=>handleAddToPlan()}	
								disabled={selectedRows?.length === 0}
								color='neutral'
								sx={{
									borderRadius:0,
									height: 30, 
									minHeight: 0,
									width:50,
									whiteSpace:'wrap',
									fontSize:12,
									mx:1,
									mb:1,
									bgcolor:'#c7c3fe',
									color:'black',
									border:'2px outset grey',
									lineHeight:1
								}}
							>
								<KeyboardArrowLeftIcon/>
							</Button>
							<Button	
								onClick={()=>handleRemoveFromPlan()}	
								disabled={!selectedTaskId}
								color='neutral'
								sx={{
									borderRadius:0,
									height: 30, 
									minHeight: 0,
									width:50,
									whiteSpace:'wrap',
									fontSize:12,
									mx:1,
									mb:1,
									bgcolor:'#c7c3fe',
									color:'black',
									border:'2px outset grey',
									lineHeight:1
								}}
							>
								<KeyboardArrowRightIcon/>
							</Button>
						</Box>
					</Box>
				</Box>
				<Box
					id='requirementsArea'
					sx={{
						display:showEditor?'flex':'none',
						position:'relative',
					}}
				>
					<Requirements
						URL={props.URL}
						selectedAmp={props.selectedAmp}
						setShowSpinner={setShowSpinner}
						setSelectedRows={setSelectedRows}
						selectedRows={selectedRows}
					/>
				</Box>
			</Box>
		</React.Fragment>
	);
}
