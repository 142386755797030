import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Radio from '@mui/joy/Radio';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';
import { ToastContainer, toast, Zoom, ToastOptions} from "react-toastify";

//Иконки
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Компоненты 
import AddEditeForm from './AddEditeForm.tsx';
import TaskInitializing from './TaskInitializing.tsx';
import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';
import Spiner from '../../../Components/Spiner.tsx'

export default function MR(props) {

	//Стили
	const inputStyle = {borderRadius:'0px', minHeight:'0px', height:'26px', border:'1px solid black',color:'#000078', fontWeight:'bold',fontSize:'14px', mb:1,  '--Icon-fontSize': '14px'}
	const lableStyle = {m:0, fontFamily:'Arial, sans-serif', fontSize:'16px',alignSelf:'center'};
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'16px',color:'black',};
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'16px', maxWidth:'500px', textOverflow: 'ellipsis', overflow: 'hidden',};
	const filtersStyle = {position:'absolute',width:'100%',top:0,minWidth:0,minHeight:0,height:'100%',px: 0.5,m: 0,borderRadius: '0px',"--Input-decoratorChildHeight": "24px",};

	const headers =[
		{id:'ata',name:'ATA'}, 
		{id:'task',name:'TASK'},
		{id:'basicTask',name:'BASIC_TASK'},
		{id:'jic',name:'JIC'},
		{id:'taskTitle',name:'TASK_Title'},
		{id:'taskDescription',name:'TASK_Description'},
		{id:'taskType',name:'Task_Type:'},
		{id:'taskEffectivity',name:'Task_Effectivity:'},
		{id:'mainZone',name:'Main_Zone:'},
		{id:'zones',name:'Zones:'},
		{id:'mrbCode',name:'MRB_Code:'},
		{id:'note',name:'Note:'},
		{id:'base',name:'Base:'},
		{id:'mhnr',name:'MNHR:'},
		{id:'fhStart',name:'FH_Start:'},
		{id:'fcStart',name:'FC_Start:'},
		{id:'calendarStart',name:'Calendar_Start:'},
		{id:'calendarValueStart',name:'Calendar_Value_Start:'},
		{id:'docReferenceStart',name:'Doc_Reference_Start:'},
		{id:'fhInterval',name:'FH_Interval:'},
		{id:'fcInterval',name:'FC_Interval:'},
		{id:'calendarInterval',name:'Calendar_Interval:'},
		{id:'calendarValueInterval',name:'Calendar_Value_Interval:'},
		{id:'docReferenceInterval',name:'Doc_Reference_Interval:'},
		{id:'completedByComponentReplacement',name:'Completed_By_Component_Replacement:'},
		{id:'ipcPositions',name:'IPC_Positions:'},
		{id:'fhFinish',name:'FH_Finish:'},
		{id:'fcFinish',name:'FC_Finish:'},
		{id:'calendarFinish',name:'Calendar_Finish:'},
		{id:'calendarValueFinish',name:'Calendar_Value_Finish:'},
		{id:'docReferenceFinish',name:'Doc_Reference_Finish:'},
		{id:'etops',name:'ETOPS:'},
		{id:'etopsII',name:'ETOPS_II:'},
		{id:'di',name:'DI:'},
		{id:'cat3',name:'CAT_3:'},
		{id:'cr',name:'CR:'},
		{id:'cdccl',name:'CDCCL:'},
		{id:'ali',name:'ALI:'},
		{id:'ewis',name:'EWIS:'},
		{id:'cpcp',name:'CPCP:'},
		{id:'cmr',name:'CMR:'},
		{id:'ad',name:'AD:'},
		{id:'instructionReference',name:'Instruction_Reference'},
		{id:'apuUtilization',name:'APU_Utilization:'},
		{id:'nonScheduled',name:'NON_Scheduled:'},
	]

	const [data, setData] = React.useState<{id:number, acFamily:string, ata:string, task:string, basicTask:string, taskTitle:string, taskDescription:string, jic:string, taskType:string, taskEffectivity:string, mainZone:string, zones:string, mrbCode:string, note:string, base:string, mnhr:number, fhStart:number, fcStart:number, calendarStart:string, calendarValueStart:number, docReferenceStart:string, fhInterval:number, fcInterval:number, calendarInterval:string, calendarValueInterval:number, docReferenceInterval:string, completedByComponentReplacement:string, ipcPositions:string, whicheverLast:string, fhFinish:number, fcFinish:number, calendarFinish:string, calendarValueFinish:number, docReferenceFinish:string, earlyReschedMethod:string, earlyToleranceEm:number, lateToleranceEm:number, lateReschedMethod:number, lateToleranceLm:number, etops:string, etopsII:string, di:string, cat3:string, cr:string, cdccl:string, ali:string, ewis:string, cmr:string, ad:string, instructionReference:string, apuUtilization:boolean, nonScheduled:boolean, status:string, ampId:number, transferId:number, conditional:boolean, fls56fc:boolean, fls75fc:boolean, postThreshold:boolean, cpcp:string, revisionDate:string, prefix:string}[]>([]);
	const [filteredData, setFilteredData] = React.useState <{id:number, acFamily:string, ata:string, task:string, basicTask:string, taskTitle:string, taskDescription:string, jic:string, taskType:string, taskEffectivity:string, mainZone:string, zones:string, mrbCode:string, note:string, base:string, mnhr:number, fhStart:number, fcStart:number, calendarStart:string, calendarValueStart:number, docReferenceStart:string, fhInterval:number, fcInterval:number, calendarInterval:string, calendarValueInterval:number, docReferenceInterval:string, completedByComponentReplacement:string, ipcPositions:string, whicheverLast:string, fhFinish:number, fcFinish:number, calendarFinish:string, calendarValueFinish:number, docReferenceFinish:string, earlyReschedMethod:string, earlyToleranceEm:number, lateToleranceEm:number, lateReschedMethod:number, lateToleranceLm:number, etops:string, etopsII:string, di:string, cat3:string, cr:string, cdccl:string, ali:string, ewis:string, cmr:string, ad:string, instructionReference:string, apuUtilization:boolean, nonScheduled:boolean, status:string, ampId:number, transferId:number, conditional:boolean, fls56fc:boolean, fls75fc:boolean, postThreshold:boolean, cpcp:string, revisionDate:string, prefix:string}[]>([]);
	const [selectedRow, setSelectedRow] = React.useState({id:0});
	const [filters, setFilters] = React.useState<{id?:number, acFamily?:string, ata?:string, task?:string, basicTask?:string, taskTitle?:string, taskDescription?:string, jic?:string, taskType?:string, taskEffectivity?:string, mainZone?:string, zones?:string, mrbCode?:string, note?:string, base?:string, mnhr?:number, fhStart?:number, fcStart?:number, calendarStart?:string, calendarValueStart?:number, docReferenceStart?:string, fhInterval?:number, fcInterval?:number, calendarInterval?:string, calendarValueInterval?:number, docReferenceInterval?:string, completedByComponentReplacement?:string, ipcPositions?:string, whicheverLast?:string, fhFinish?:number, fcFinish?:number, calendarFinish?:string, calendarValueFinish?:number, docReferenceFinish?:string, earlyReschedMethod?:string, earlyToleranceEm?:number, lateToleranceEm?:number, lateReschedMethod?:number, lateToleranceLm?:number, etops?:string, etopsII?:string, di?:string, cat3?:string, cr?:string, cdccl?:string, ali?:string, ewis?:string, cmr?:string, ad?:string, instructionReference?:string, apuUtilization?:boolean, nonScheduled?:boolean, status?:string, ampId?:number, transferId?:number, conditional?:boolean, fls56fc?:boolean, fls75fc?:boolean, postThreshold?:boolean, cpcp?:string, revisionDate?:string, prefix?:string}>();
	const [intervalFilters, setIntervalFilters] = React.useState<{check?:string, fh?:number, fc?:number, calendarKind?:string, calendarValue?:number, resourceOr?:boolean}>();
	const [checkBoxesFilters, setCheckBoxesFilters] = React.useState<{mandLim?:boolean, apuUtilization?:boolean, tcAssociated?:boolean, tcRelated?:boolean, nonSchedule?:boolean, tcInstruction?:boolean, fls75?:boolean, fls56?:boolean, del?:boolean, comp?: boolean, post?:boolean}>()
	const [showFilters, setShowFilters] = React.useState(false);
	const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
	const [checksList, setChecksList] = React.useState <{check:string}[]>([]);
	const [showEditor, setShowEditor] = React.useState (true);
	const [showIntervalFilters, setShowIntervalFilters] = React.useState(false)
	const [showTaskInitializing, setShowTaskInitializing] = React.useState(false)
	const [selectedForInitializingTask, setSelectedForInitializinTask] = React.useState()
	const [isFlsSupported, setIsFlsSupported] = React.useState<{fls56:boolean, fls75:boolean}>()
	
	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	const [showSpinner, setShowSpinner] = React.useState(0)

	const toastOptions:ToastOptions = {
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: false,
		theme: "light",
		transition: Zoom
	};

	//Запрос данных для таблицы
	const fetchData = async () => {
		setShowSpinner(value => value + 1);
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/View(ampId=${props.selectedAmp.id},taskFilter='${filters?.task ?? ''}',ata='${filters?.ata ?? ''}',taskDescription='${filters?.taskDescription ?? ''}',apu=${checkBoxesFilters?.apuUtilization ?? false},taskType='${filters?.taskType ?? ''}',outOfMp=false,jic='${filters?.jic ?? ''}',taskEffectivity='${filters?.taskEffectivity ?? ''}',checkfilter='${intervalFilters?.check ?? ''}',fhFilter=${intervalFilters?.fh ?? 0},fcFilter=${intervalFilters?.fc ?? 0},calendarKindFilter='${intervalFilters?.calendarKind ?? 'DY'}',calendarValueFilter=${intervalFilters?.calendarValue ?? 0},resourceOrFilter=${intervalFilters?.resourceOr ?? false},mandLim=${checkBoxesFilters?.mandLim ?? false},tcInstruction=${checkBoxesFilters?.tcInstruction ?? false},comp=${checkBoxesFilters?.comp ?? false},nonSchelduled=${checkBoxesFilters?.nonSchedule ?? false},checkRelated=${checkBoxesFilters?.tcRelated ?? false},checkAssosiated=${checkBoxesFilters?.tcAssociated ?? false},postThreshold=${checkBoxesFilters?.post ?? false},fls56=${checkBoxesFilters?.fls56 ?? false},fls75=${checkBoxesFilters?.fls75 ?? false},deleted=${checkBoxesFilters?.del ?? false})`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setData(result.value);
		} else {
			try{
				const answer = await response.json()
				if(answer.message){
					toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
				} else {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			} catch {
				toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
			}
		}
		setShowSpinner(value => value - 1);
	};

	//Проверка поддержки fls
	const fetchIsFlsSupported = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/Amp?$filter=(acFamily eq '${props.selectedACFamily}') and (id eq ${props.selectedAmp?.id})&select=fls56,fls75`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setIsFlsSupported(result.value)
		} else {
			try{
				const answer = await response.json()
				if(answer.message){
					toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
				} else {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			} catch {
				toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
			}
		}
	};

	//Запрос списка Checks
	React.useEffect(() => {
		const fetchChecks = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks?$filter=ampid eq ${props?.selectedAmp?.id}&$select=check`,
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setChecksList(result.value);
			} else {
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			}
		};
		if(props?.selectedAmp?.id) fetchChecks();
	}, [props?.selectedAmp])

	React.useEffect(() => {
		if(props?.selectedAmp?.id){
			fetchData()
			fetchIsFlsSupported()
		};
	}, [props?.selectedAmp, checkBoxesFilters]);

	//Выбор строки
	const handleRowSelection = (row) => {
		if(row.id === selectedRow.id){
			setSelectedRow({id:0});
		} else {
			setSelectedRow(row);
		}  
	};
	
	//Фильтрация данных
	React.useEffect(() => {
		let filtered = data;
		for (const key in filters) {
			if(filters[key] != ''){
				filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
			}
		}
        setFilteredData(filtered);
    }, [data, filters]);

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters(undefined);
		handleSort('id', 'asc')
		setIntervalFilters(undefined);
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Изменение фильтров interval
	const handleChangeInterval = (property, value) => {
		setIntervalFilters({ ...intervalFilters, [property]: value });
	};

	//Изменение фильтров interval
	const handleChangeCheckboxes = (property, value) => {
		setCheckBoxesFilters({ ...checkBoxesFilters, [property]: value });
	};

	//Подтверждение Interval Filters
	const confirmIntervalFilters = () => {
		fetchData()
		setShowIntervalFilters(false)
	}

	//Закрытие окна Interval Filters
	const cancelIntervalFilters = () => {
		setIntervalFilters(undefined)
		setShowIntervalFilters(false)
	}

	//Сброс Interval Filters
	const resetIntervalFilters = () => {
		setIntervalFilters(undefined)
		setShowIntervalFilters(false)
	}

	//Сортировка данных
	const handleSort = (property, value) => {
		let filtered = filteredData
		if (typeof data[property] === "number") {
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
		} else {
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
		} 
		setFilteredData(filtered)
		setSort({lable:property, order:value})
	};

	//Проверка наличия фильтров
	const checkFilters = () => {
		if (filters === undefined) {
			return true;
		}
	
		// Проверяем, все ли свойства объекта пустые
		return Object.values(filters).every(value => 
			value === '' || value === null || value === undefined || 
			(typeof value === 'number' && value === 0)
		);
	}

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}
	
	//Создание Excel отчёта
	const createExcel = () => {
		return fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/FilteredXlsx(ampId=${props.selectedAmp.id},taskFilter='${filters?.task ?? ''}',ata='${filters?.ata ?? ''}',taskDescription='${filters?.taskDescription ?? ''}',apu=${checkBoxesFilters?.apuUtilization ?? false},taskType='${filters?.taskType ?? ''}',outOfMp=false,jic='${filters?.jic ?? ''}',taskEffectivity='${filters?.taskEffectivity ?? ''}',checkfilter='${intervalFilters?.check ?? ''}',fhFilter=${intervalFilters?.fh ?? 0},fcFilter=${intervalFilters?.fc ?? 0},calendarKindFilter='${intervalFilters?.calendarKind ?? 'DY'}',calendarValueFilter=${intervalFilters?.calendarValue ?? 0},resourceOrFilter=${intervalFilters?.resourceOr ?? false},mandLim=${checkBoxesFilters?.mandLim ?? false},tcInstruction=${checkBoxesFilters?.tcInstruction ?? false},comp=${checkBoxesFilters?.comp ?? false},nonSchelduled=${checkBoxesFilters?.nonSchedule ?? false},checkRelated=${checkBoxesFilters?.tcRelated ?? false},checkAssosiated=${checkBoxesFilters?.tcAssociated ?? false},postThreshold=${checkBoxesFilters?.post ?? false},fls56=${checkBoxesFilters?.fls56 ?? false},fls75=${checkBoxesFilters?.fls75 ?? false},deleted=${checkBoxesFilters?.del ?? false})`,
		{
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then(async (response) => {
			if (response.ok) {
				return response.blob();
			} else {
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'MR_List.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	};

	const checkRowColor = (row,index) => {
		if(selectedRow.id === row.id) return '#c0c0ff'
		if(row.nonScheduled) return '#FFA2A0'
		if(row.etops || row.etopsII || row.cat3 || row.fls56fc || row.di || row.cr || row.cdccl || row.fls75fc || row.ali || row.ewis || row.cmr || row.cpcp || row.ad || row.conditional) return '#ffe3c6'
		if(index % 2 === 0){
			return '#EDF2F7'
		} else {
			return 'white'
		}
	}

	const handleRightClick = (row) => {
		const fetchInitializing = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/InitializeTask(taskId=${row.id})`,
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}, method:'POST'});
			if (response.status === 201) {	
				setSelectedForInitializinTask(row)
				setShowTaskInitializing(true)
			} else {
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			}
		};

		fetchInitializing()
	}

	//Lazy loading 
	const tableRef = React.useRef<HTMLTableElement | null>(null); // Ссылка на элемент таблицы
	const [sliceCount, setSliceCount] = React.useState(100)

	const handleScroll = () => {
		if (tableRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
			if (scrollTop + clientHeight >= scrollHeight - 100) {
				setSliceCount(value=>value+100)
			}
		}
	};

	React.useEffect(() => {
		const tableElement = tableRef.current;
		if (tableElement) {
			tableElement.addEventListener('scroll', handleScroll);
		}
		return () => {
			if (tableElement) {
				tableElement.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);

	return (
		<React.Fragment>
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			<TaskInitializing
				setShowTaskInitializing={setShowTaskInitializing}
				showTaskInitializing={showTaskInitializing}
				setShowSpinner={setShowSpinner}
				showSpinner={showSpinner}
				URL={props.URL}
				selectedForInitializingTask={selectedForInitializingTask}
			/>
			{showSpinner !== 0 && (<Spiner/>)}
			<ToastContainer/>
			<Box
				id='main'
				sx={{
					position:'relative',
					height:'100%',
					display:'grid',
					gridTemplateColumns:showEditor?'auto 700px':'100%',
				}}
			>
				<Box
					id='tableArea'
					sx={{
						position:'relative',
					}}
				>
					<Typography 
						id='tableAreaTitle'
						sx={{ 
							position:'absolute',
							fontSize:'16px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif',
							left:16
						}}
					>
						Maintenance Requirements:
					</Typography>
					<Box
						id='buttons'
						sx={{
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							position:'absolute',
							right:16,
							height:40
						}}
					>
						<Tooltip title='Create Excel Report'>
							<IconButton
								id='excelButton'
								variant='plain'
								onClick={createExcel}
								sx={{
									m:0,
								}}
							>
								<img 
									width='35px' 
									src="/images/svg/excel.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
						<Tooltip title='Reset Filters'>
							<IconButton
								id='refreshButton'
								variant='plain'
								onClick={refreshFilters}
								sx={{
									m:0,
								}}
							>
								<img 
									width='35px' 
									src="/images/svg/refresh.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
						<Tooltip title= {showEditor? 'Hide Editor' : 'Show Editor'}>
							<IconButton
								id='editorButton'
								variant='plain'
								onClick={toggleEditore} 
								sx={{
									m:0,
									transition: '0.3s',
									transform: showEditor? 'rotate(0deg)' : 'rotate(180deg)',
								}}
							>
								<img 
									width='35px' 
									src="/images/svg/arrowBack.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
					</Box>	
					<Box
						id='filters'
						sx={{
							display:'flex',
							flexWrap: 'nowrap',
							gap: 0.5,
							alignItems:'flex-end',
							mt:'40px',
							mx:'16px',
						}}
					>
						<FormControl>
							<FormLabel sx={lableStyle}>Task:</FormLabel>
							<Input
								value={filters?.task || ''}
								onChange={(event) => handleChange('task', event.target.value)}
								sx={{...inputStyle, width:200}}
							/>
						</FormControl>
						<FormControl>
							<FormLabel sx={lableStyle}>JIC:</FormLabel>
							<Input
								value={filters?.jic || ''}
								onChange={(event) => handleChange('jic', event.target.value)}
								sx={{...inputStyle, width:100}}
							/>
						</FormControl>
						<FormControl>
							<FormLabel sx={lableStyle}>ATA:</FormLabel>
							<Input
								value={filters?.ata || ''}
								onChange={(event) => handleChange('ata', event.target.value)}
								sx={{...inputStyle, width:70}}
							/>
						</FormControl>
						<FormControl>
							<FormLabel sx={lableStyle}>Task Description:</FormLabel>
							<Input
								value={filters?.taskDescription || ''}
								onChange={(event) => handleChange('taskDescription', event.target.value)}
								sx={{...inputStyle, width:150}}
							/>
						</FormControl>
						<FormControl>
							<FormLabel sx={lableStyle}>Type:</FormLabel>
							<Autocomplete
								options={[...new Set(data.map(data => data.taskType))].filter(row => row).map(taskType=> ({ label: taskType}))}
								sx={{...inputStyle, width:100}}
								onChange={(event, value) => handleChange('taskType', value ? value.label : '')}
								value={{ label: filters?.taskType || ''}}
							/>
						</FormControl>
						<FormControl>
							<FormLabel sx={lableStyle}>Eff.:</FormLabel>
							<Input
								value={filters?.taskEffectivity || ''}
								onChange={(event) => handleChange('taskEffectivity', event.target.value)}
								sx={{...inputStyle, width:80}}
							/>
						</FormControl>
						<Box
							sx={{
								display:'flex',
								flexDirection:'column',
								mb:1,
								textOverflow: 'ellipsis', 
								overflow: 'hidden',
								width:'40px'
							}}
						>
							<Typography
								sx={{
									fontSize:10,
									lineHeight:1,
									display: !intervalFilters ? 'flex':'none'
								}}
							>
								No Filter
							</Typography>
							<Typography
								sx={{
									fontSize:10,
									lineHeight:1,
									display: intervalFilters?.check ? 'flex':'none'
								}}
							>
								Check:{intervalFilters?.check}
							</Typography>
							<Typography
								sx={{
									fontSize:10,
									lineHeight:1,
									display: intervalFilters?.fh ? 'flex':'none',
								}}
							>
								FH:{intervalFilters?.fh}
							</Typography>
							<Typography
								sx={{
									fontSize:10,
									lineHeight:1,
									display: intervalFilters?.fc ? 'flex':'none'
								}}
							>
								FC:{intervalFilters?.fc}
							</Typography>
							<Typography
								sx={{
									fontSize:10,
									lineHeight:1,
									display: intervalFilters?.calendarKind && intervalFilters?.calendarValue ? 'flex':'none'
								}}
							>
								{intervalFilters?.calendarKind}:{intervalFilters?.calendarValue}
							</Typography>
							<Typography
								sx={{
									fontSize:10,
									lineHeight:1,
									display: intervalFilters?.resourceOr && (intervalFilters?.fh || intervalFilters?.fc ) ? 'flex':'none'
								}}
							>
								Logic:OR
							</Typography>
						</Box>
						<Box
							sx={{
								position:'relative'
							}}
						>
							<Tooltip title= 'Open Interval, Check Filters'>
								<Button	
									onClick={()=>setShowIntervalFilters(!showIntervalFilters)}	
									color='neutral'
									sx={{
										borderRadius:0,
										height: 30, 
										minHeight: 0,
										width:50,
										whiteSpace:'wrap',
										fontSize:12,
										mx:1,
										mb:1,
										bgcolor:intervalFilters?'#ffc3c6':'#c7c3fe',
										color:'black',
										border:'2px outset grey',
										lineHeight:1
									}}
								>
									Interval Check
								</Button>
							</Tooltip>
							{showIntervalFilters && (
								<Box
									sx={{
										position:'absolute',
										height:300,
										width:260,
										bgcolor:'#d6d3ce',
										border:'1px solid black',
										zIndex:999,
										left:'-100px',
										top:40,
										alignItems:'center'
									}}
								>
									<Grid container spacing={1} sx={{ flexGrow: 1, px:2, py:1 }}>
										<Grid xs={12}>
											<FormControl>
												<FormLabel sx={lableStyle}>Check:</FormLabel>
												<Autocomplete
													options={checksList.map(data=> ({ label: data.check}))}
													sx={{...inputStyle}}
													onChange={(event, value) => handleChangeInterval('check', value ? value.label : '')}
													value={{ label: intervalFilters?.check || ''}}
												/>
											</FormControl>
										</Grid>

										<Grid xs={2} sx={{color:'black'}}>
											FH:
										</Grid>
										<Grid xs={10}>
											<Input
												value={intervalFilters?.fh || ''}
												onChange={(event) => handleChangeInterval('fh', event.target.value)}
												sx={{...inputStyle}}
											/>
										</Grid>

										<Grid xs={2} sx={{color:'black'}}>
											FC:
										</Grid>
										<Grid xs={10}>
											<Input
												value={intervalFilters?.fc || ''}
												onChange={(event) => handleChangeInterval('fc', event.target.value)}
												sx={{...inputStyle}}
											/>
										</Grid>

										<Grid xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:0.5}}>
											<Typography sx={{color:'black',fontSize:16}}>
												DY:
											</Typography>
											<Tooltip title='Days Selector'>
												<Checkbox 
													variant="outlined"
													color="neutral"
													sx={{
														fontSize:'14px',
														'--Checkbox-size':'15px',
														gap:0.4,
														'& span': {
															borderRadius: '0px',
															borderWidth:'1px',
															borderColor:'black',
															color:'black'
														}, 
													}}
													checked={intervalFilters?.calendarKind === 'DY'}
													onChange={() => handleChangeInterval('calendarKind', 'DY')}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:0.5}}>
											<Typography sx={{color:'black',fontSize:16}}>
												MO:
											</Typography>
											<Tooltip title='Months Selector'>
												<Checkbox 
													variant="outlined"
													color="neutral"
													sx={{
														fontSize:'14px',
														'--Checkbox-size':'15px',
														gap:0.4,
														'& span': {
															borderRadius: '0px',
															borderWidth:'1px',
															borderColor:'black',
															color:'black'
														}, 
													}}
													checked={intervalFilters?.calendarKind === 'MO'}
													onChange={() => handleChangeInterval('calendarKind', 'MO')}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:0.5}}>
											<Typography sx={{color:'black',fontSize:16}}>
												YR:
											</Typography>
											<Tooltip title='Years Selector'>
												<Checkbox 
													variant="outlined"
													color="neutral"
													sx={{
														fontSize:'14px',
														'--Checkbox-size':'15px',
														gap:0.4,
														'& span': {
															borderRadius: '0px',
															borderWidth:'1px',
															borderColor:'black',
															color:'black'
														}, 
													}}
													checked={intervalFilters?.calendarKind === 'YR'}
													onChange={() => handleChangeInterval('calendarKind', 'YR')}
												/>
											</Tooltip>
										</Grid>
												
										<Grid xs={2}></Grid>
										<Grid xs={8}>
											<Input
												value={intervalFilters?.calendarValue || ''}
												onChange={(event) => handleChangeInterval('calendarValue', event.target.value)}
												sx={{...inputStyle}}
											/>
										</Grid>
										<Grid xs={2}></Grid>

										<Grid xs={2}></Grid>
										<Grid xs={4}>
											<Radio
												color='neutral' 
												variant='outlined'
												label='And'
												checked={!intervalFilters?.resourceOr || false}
												onChange={() => handleChangeInterval('resourceOr', false)}
											/>
										</Grid>	
										<Grid xs={4}>
											<Radio
												color='neutral' 
												variant='outlined'
												label='Or'
												checked={intervalFilters?.resourceOr || false}
												onChange={() => handleChangeInterval('resourceOr', true)}
											/>
										</Grid>	
										<Grid xs={2}></Grid>

										<Grid xs={12} sx={{display:'flex', justifyContent:'center'}}>
											<Button
												onClick={confirmIntervalFilters}	
												sx={{
													borderRadius:0,
													height: 25, 
													minHeight: 0,
													bgcolor:'#d6d3ce',
													color:'black',
													border:'2px outset grey',
												}}
											>
												Ok
											</Button>
											<Button
												onClick={cancelIntervalFilters}	
												sx={{
													borderRadius:0,
													height: 25, 
													minHeight: 0,
													bgcolor:'#d6d3ce',
													color:'black',
													border:'2px outset grey',
												}}
											>
												Cancel
											</Button>
											<Button
												onClick={resetIntervalFilters}
												sx={{
													borderRadius:0,
													height: 25, 
													minHeight: 0,
													bgcolor:'#d6d3ce',
													color:'black',
													border:'2px outset grey',
												}}
											>
												Reset
											</Button>
										</Grid>
									</Grid>
								</Box>
							)}
						</Box>
						<Box
							sx={{
								display:showEditor ? 'none':'flex',
								ml:6,
							}}
						>
							<Grid container spacing={1}>
								<Grid xs={2} sx={{overflow:'hidden',textOverflow: 'ellipsis',}}>
									<Tooltip title='Filter Task with Mandatory Instraction (AD, ALI, CMR) Only'>
										<Checkbox 
											label=':Mand-Lim'
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& span': {
													borderRadius: '0px',
													borderWidth:'1px',
													borderColor:'black',
													color:'black'
												}, 
											}}
											checked={checkBoxesFilters?.mandLim}
											onChange={(event) => handleChangeCheckboxes('mandLim', event.target.checked)}
										/>
									</Tooltip>
								</Grid>
								<Grid xs={2} sx={{overflow:'hidden',textOverflow: 'ellipsis',}}>
									<Tooltip title='Filter Task which has Associated TC'>
										<Checkbox 
											label=':TC Associated'
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& span': {
													borderRadius: '0px',
													borderWidth:'1px',
													borderColor:'black',
													color:'black'
												}, 
											}}
											checked={checkBoxesFilters?.tcAssociated}
											onChange={(event) => handleChangeCheckboxes('tcAssociated', event.target.checked)}
										/>
									</Tooltip>
								</Grid>
								<Grid xs={2} sx={{overflow:'hidden',textOverflow: 'ellipsis',}}>
									<Tooltip title='Filter Task which has Related TC'>
										<Checkbox 
											label=':NON-Scheduled'
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& span': {
													borderRadius: '0px',
													borderWidth:'1px',
													borderColor:'black',
													color:'black'
												}, 
												
											}}
											checked={checkBoxesFilters?.nonSchedule}
											onChange={(event) => handleChangeCheckboxes('nonShedule', event.target.checked)}
										/>
									</Tooltip>
								</Grid>
								<Grid xs={2} sx={{overflow:'hidden',textOverflow: 'ellipsis',}}>
									{isFlsSupported?.fls75 && (
										<Tooltip title='Filter - Flight Length Sensitive - 75.000 FC Maximum'>
											<Checkbox 
												label=':FLS-75'
												variant="outlined"
												color="neutral"
												sx={{
													fontSize:'16px',
													fontWeight:'bold',
													'--Checkbox-size':'15px',
													color:'#3c0203',
													gap:0.4,
													'& span': {
														borderRadius: '0px',
														borderWidth:'1px',
														borderColor:'black',
														color:'black'
													}, 
												}}
												checked={checkBoxesFilters?.fls75}
												onChange={(event) => handleChangeCheckboxes('fls75', event.target.checked)}
											/>
										</Tooltip>
									)}
								</Grid>
								<Grid xs={2} sx={{overflow:'hidden',textOverflow: 'ellipsis',}}>
									<Tooltip title='filter Task with Mandatory Instraction (AD, ALI, CMR) Only'>
										<Checkbox 
											label=':DEL'
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'16px',
												fontWeight:'bold',
												'--Checkbox-size':'15px',
												color:'#77040c',
												gap:0.4,
												'& span': {
													borderRadius: '0px',
													borderWidth:'1px',
													borderColor:'black',
													color:'black'
												}, 
											}}
											checked={checkBoxesFilters?.del}
											onChange={(event) => handleChangeCheckboxes('del', event.target.checked)}
										/>
									</Tooltip>
								</Grid>
								<Grid xs={2}/>

								<Grid xs={2} sx={{overflow:'hidden',textOverflow: 'ellipsis',}}>
									<Tooltip title='Filter Task with APU Flight Data Utilization'>
										<Checkbox 
											label=':APU Utiliz.'
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& span': {
													borderRadius: '0px',
													borderWidth:'1px',
													borderColor:'black',
													color:'black'
												}, 
											}}
											checked={checkBoxesFilters?.apuUtilization}
											onChange={(event) => handleChangeCheckboxes('apuUtilization', event.target.checked)}
										/>
									</Tooltip>
								</Grid>
								<Grid xs={2} sx={{overflow:'hidden',textOverflow: 'ellipsis',}}>
									<Tooltip title='Filter Task which has Related TC'>
										<Checkbox 
											label=':TC Related'
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& span': {
													borderRadius: '0px',
													borderWidth:'1px',
													borderColor:'black',
													color:'black'
												}, 
											}}
											checked={checkBoxesFilters?.tcRelated}
											onChange={(event) => handleChangeCheckboxes('tcRelated', event.target.checked)}
										/>
									</Tooltip>
								</Grid>
								<Grid xs={2} sx={{overflow:'hidden',textOverflow: 'ellipsis',}}>
									<Tooltip title='Filter Task which Inctruction'>
										<Checkbox 
											label=':TC Instruction'
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& span': {
													borderRadius: '0px',
													borderWidth:'1px',
													borderColor:'black',
													color:'black'
												}, 
											}}
											checked={checkBoxesFilters?.tcInstruction}
											onChange={(event) => handleChangeCheckboxes('tcInstruction', event.target.checked)}
										/>
									</Tooltip>
								</Grid>
								<Grid xs={2} sx={{overflow:'hidden',textOverflow: 'ellipsis',}}>
									{isFlsSupported?.fls56 && (
										<Tooltip title='Filter - Flight Length Sensitive - 56.000 FC Maximum'>
											<Checkbox 
												label=':FLS-56'
												variant="outlined"
												color="neutral"
												sx={{
													fontSize:'16px',
													fontWeight:'bold',
													'--Checkbox-size':'15px',
													color:'#3c0203',
													gap:0.4,
													'& span': {
														borderRadius: '0px',
														borderWidth:'1px',
														borderColor:'black',
														color:'black'
													}, 
												}}
												checked={checkBoxesFilters?.fls56}
												onChange={(event) => handleChangeCheckboxes('fls56', event.target.checked)}
											/>
										</Tooltip>
									)}
									
								</Grid>
								<Grid xs={2} sx={{overflow:'hidden',textOverflow: 'ellipsis',}}>
									<Tooltip title='Filter - Completed By Component Replacement'>
										<Checkbox 
											label=':COMP'
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'16px',
												fontWeight:'bold',
												'--Checkbox-size':'15px',
												color:'#3c0203',
												gap:0.4,
												'& span': {
													borderRadius: '0px',
													borderWidth:'1px',
													borderColor:'black',
													color:'black'
												}, 
											}}
											checked={checkBoxesFilters?.comp}
											onChange={(event) => handleChangeCheckboxes('comp', event.target.checked)}
										/>
									</Tooltip>
								</Grid>
								<Grid xs={2} sx={{overflow:'hidden',textOverflow: 'ellipsis',}}>
									<Tooltip title='Filter - Post Threshold Interval'>
										<Checkbox 
											label=':POST'
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'16px',
												fontWeight:'bold',
												'--Checkbox-size':'15px',
												color:'#3c0203',
												gap:0.4,
												'& span': {
													borderRadius: '0px',
													borderWidth:'1px',
													borderColor:'black',
													color:'black'
												}, 
											}}
											checked={checkBoxesFilters?.post}
											onChange={(event) => handleChangeCheckboxes('post', event.target.checked)}
										/>
									</Tooltip>
								</Grid>
							</Grid>
						</Box>
					</Box>
					<Box
						onClick={() => setShowFilters(!showFilters)}
						sx={{
							border:'1px solid black',
							borderBottom:'none',
							height:'24px',
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							bgcolor:checkFilters()?'transparent':'#DDE7EE',
							mx:'16px',
						}}
					>
						<ArrowDropDownIcon 
							sx={{
								fontSize: '24px',
								transition: '0.2s',
								transform: !showFilters ? 'rotate(0deg)' : 'rotate(180deg)',
							}}
						/>
					</Box>
					<Sheet
						id='tablesSheet'
						variant="outlined"
						sx={{
							position:'absolute',
							width: `calc(100% - 32px)`,
							height:`calc(100% - 150px)`,
							borderRadius: '0px',
							flexShrink: 1,
							overflow: 'auto',
							border: '1px solid black',
							bgcolor:'#848284',
							mx:'16px',
							mt:'0px',
							mb:'10px'
						}}
						ref={tableRef}
					>
						<Table
							id='table'
							borderAxis="both"
							stickyHeader
							hoverRow
							sx={{
								'--TableCell-headBackground': '#d6d3ce',
								'--Table-headerUnderlineThickness': '1px',
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '1px',
								'--TableCell-height': '29px',
								'--TableCell-paddingX': '5px',
								'--TableCell-borderColor': '#939293',
								tableLayout: 'auto', 
								width:'auto',
								borderRadius:'0px'
							}}
						>
							<thead>
								<tr>
									<th style={{...headerStyle,left:0, top:0}}>
										<Typography
											onClick={() => handleSort('id', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'id' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
												fontWeight:filters?.id ? 'bold' : 'normal',							
											}}
										>
											ID
										</Typography>
									</th>
									{headers.map((row) => (
										<th style={headerStyle}>
											<Typography
												onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
												endDecorator={<ArrowDropDownIcon />}
												sx={{
													'& svg': {
														color:sort.lable === row.id ? '#636b74' : 'transparent',
														transition: '0.2s',
														transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
													},
													fontWeight:filters?.[row.id] ? 'bold' : 'normal'
												}}
											>
												{row.name}
											</Typography>
										</th>
									))}
								</tr>
								{showFilters && (
									<tr>
										<th style={{padding:0}}>
											<Input
												value={filters?.id || ''}
												onChange={(event) => handleChange('id', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}
												}}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.ata || ''}
												onChange={(event) => handleChange('ata', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.task || ''}
												onChange={(event) => handleChange('task', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.basicTask || ''}
												onChange={(event) => handleChange('basicTaska', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.jic || ''}
												onChange={(event) => handleChange('jic', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.taskTitle || ''}
												onChange={(event) => handleChange('taskTitle', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.taskDescription || ''}
												onChange={(event) => handleChange('taskDescription', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.taskType))].filter(row => row).map(taskType=> ({ label: taskType}))}
												sx={filtersStyle}
												onChange={(event, value) => handleChange('taskType', value ? value.label : '')}
												value={{ label: filters?.taskType || ''}}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.taskEffectivity))].filter(row => row).map(taskEffectivity => ({ label: taskEffectivity}))}
												sx={filtersStyle}
												onChange={(event, value) => handleChange('taskEffectivity', value ? value.label : '')}
												value={{ label: filters?.taskEffectivity || ''}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.mainZone || ''}
												onChange={(event) => handleChange('mainZone', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.zones || ''}
												onChange={(event) => handleChange('zones', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.mrbCode || ''}
												onChange={(event) => handleChange('mrbCode', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.note || ''}
												onChange={(event) => handleChange('note', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.base))].filter(row => row).map(base => ({ label: base}))}
												sx={filtersStyle}
												onChange={(event, value) => handleChange('base', value ? value.label : '')}
												value={{ label: filters?.base || ''}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.mnhr || ''}
												onChange={(event) => handleChange('mnhr', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.fhStart || ''}
												onChange={(event) => handleChange('fhStart', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.fcStart || ''}
												onChange={(event) => handleChange('fcStart', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.calendarStart))].filter(row => row).map(calendarStart => ({ label: calendarStart}))}
												sx={filtersStyle}
												onChange={(event, value) => handleChange('calendarStart', value ? value.label : '')}
												value={{ label: filters?.calendarStart || ''}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.calendarValueStart || ''}
												onChange={(event) => handleChange('calendarValueStart', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.docReferenceStart || ''}
												onChange={(event) => handleChange('docReferenceStart', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.fhInterval || ''}
												onChange={(event) => handleChange('fhInterval', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.fcInterval || ''}
												onChange={(event) => handleChange('fcInterval', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.calendarInterval))].filter(row => row).map(calendarInterval => ({ label: calendarInterval}))}
												sx={filtersStyle}
												onChange={(event, value) => handleChange('calendarInterval', value ? value.label : '')}
												value={{ label: filters?.calendarInterval || ''}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.calendarValueInterval || ''}
												onChange={(event) => handleChange('calendarValueInterval', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.docReferenceInterval || ''}
												onChange={(event) => handleChange('docReferenceInterval', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th/>
										<th style={{padding:0}}>
											<Input
												value={filters?.ipcPositions || ''}
												onChange={(event) => handleChange('ipcPositions', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.fhFinish || ''}
												onChange={(event) => handleChange('fhFinish', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.fcFinish || ''}
												onChange={(event) => handleChange('fcFinish', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.calendarFinish))].filter(row => row).map(calendarFinish => ({ label: calendarFinish}))}
												sx={filtersStyle}
												onChange={(event, value) => handleChange('calendarFinish', value ? value.label : '')}
												value={{ label: filters?.calendarFinish || ''}}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters?.calendarValueFinish || ''}
												onChange={(event) => handleChange('calendarValueFinish', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th/>
										<th/>
										<th/>
										<th/>
										<th/>
										<th/>
										<th/>
										<th/>
										<th/>
										<th/>
										<th/>
										<th/>
										<th/>
										<th/>
										<th/>
									</tr>
								)}
							</thead>
							<tbody>
								{filteredData.length == 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
									</th>
								)}
								{filteredData.slice(0,sliceCount).map((row, index) => (
									<tr
										key={row.id}
										onClick={() => handleRowSelection(row)}
										onContextMenu={(e) => {
											e.preventDefault(); 
											handleRightClick(row); 
										}}
										style={{ 
											backgroundColor: checkRowColor(row, index),
											color:'black',
										}}
									>
										<td style={{...rowStyle, backgroundColor:row.apuUtilization ? '#ffc3ff' : '#c6c3c6'}}>{row.id}</td>
										<td style={rowStyle}>{row.ata}</td>
										<td style={rowStyle}>{row.task}</td>
										<td style={rowStyle}>{row.basicTask}</td>
										<td style={rowStyle}>{row.jic}</td>
										<td style={rowStyle}>{row.taskTitle}</td>
										<td style={rowStyle}>{row.taskDescription}</td>
										<td style={rowStyle}>{row.taskType}</td>
										<td style={rowStyle}>{row.taskEffectivity}</td>
										<td style={rowStyle}>{row.mainZone}</td>
										<td style={rowStyle}>{row.zones}</td>
										<td style={rowStyle}>{row.mrbCode}</td>
										<td style={rowStyle}>{row.note}</td>
										<td style={rowStyle}>{row.base}</td>
										<td style={rowStyle}>{row.mnhr}</td>
										<td style={rowStyle}>{row.fhStart}</td>
										<td style={rowStyle}>{row.fcStart}</td>
										<td style={rowStyle}>{row.calendarStart}</td>
										<td style={rowStyle}>{row.calendarValueStart}</td>
										<td style={rowStyle}>{row.docReferenceStart}</td>
										<td style={rowStyle}>{row.fhInterval}</td>
										<td style={rowStyle}>{row.fcInterval}</td>
										<td style={rowStyle}>{row.calendarInterval}</td>
										<td style={rowStyle}>{row.calendarValueInterval}</td>
										<td style={rowStyle}>{row.docReferenceInterval}</td>
										<td style={rowStyle}>{row.completedByComponentReplacement}</td>
										<td style={rowStyle}>{row.ipcPositions}</td>
										<td style={rowStyle}>{row.fhFinish}</td>
										<td style={rowStyle}>{row.fcFinish}</td>
										<td style={rowStyle}>{row.calendarFinish}</td>
										<td style={rowStyle}>{row.calendarValueFinish}</td>
										<td style={rowStyle}>{row.docReferenceFinish}</td>
										<td style={rowStyle}>{row.etops}</td>
										<td style={rowStyle}>{row.etopsII}</td>
										<td style={rowStyle}>{row.di}</td>
										<td style={rowStyle}>{row.cat3}</td>
										<td style={rowStyle}>{row.cr}</td>
										<td style={rowStyle}>{row.cdccl}</td>
										<td style={rowStyle}>{row.ali}</td>
										<td style={rowStyle}>{row.ewis}</td>
										<td style={rowStyle}>{row.cpcp}</td>
										<td style={rowStyle}>{row.cmr}</td>
										<td style={rowStyle}>{row.ad}</td>
										<td style={rowStyle}>{row.instructionReference}</td>
										<td style={rowStyle}>{row.apuUtilization}</td>
										<td style={rowStyle}>{row.nonScheduled}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
				</Box>
				<Box
					sx={{
						display: 'flex',
						position:'absolute',
						width: `calc(100% - 32px)`,
						height:`20px`,
						bottom:5,
						mx:'16px',
						alignItems:'center',
						justifyContent:'space-between',
					}}
				>
					{filteredData?.length !== 0 ? (
						<Typography
							sx={{
								fontSize:'12px',
							}}
						>
							Found {filteredData?.length} Records
						</Typography>
					):(
						<Typography
							sx={{
								fontSize:'12px',
							}}
						>
							No Records were Found!
						</Typography>
					)}
				</Box>
				<Box
					id='editFormArea'
					sx={{
						display:showEditor?'flex':'none',
						position:'relative',
					}}
				>
					<AddEditeForm
						URL={props.URL}
						selectedAmpId={props.selectedAmp?.id}
						selectedRow={selectedRow} 
						reloadTable={fetchData}
						setResponseStatus={setResponseStatus}
						selectedACFamily={props.selectedACFamily}
						selectedOperator={props.selectedOperator}
						isFlsSupported={isFlsSupported}
					/>
				</Box>
			</Box>
		</React.Fragment>
	);
}
