import * as React from 'react';

import Modal from '@mui/joy/Modal';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Tab from '@mui/joy/Tab';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import Autocomplete from '@mui/joy/Autocomplete';
import Grid from '@mui/joy/Grid';
import Tooltip from '@mui/joy/Tooltip';
import Textarea from '@mui/joy/Textarea';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Checkbox from '@mui/joy/Checkbox';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import CloseIcon from '@mui/icons-material/Close';

export default function Forecast (props) {
	const tabStyle = {fontSize:'14px', lineHeight:1,'--ListItem-minHeight':1};
    const inputStyle = { borderRadius: 0, px:0.5, mx: 0.5, height: 22, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'12px',"--Input-decoratorChildHeight": "20px", lineHeight:1,};
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'12px', alignSelf:'center', lineHeight:1 };
    const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'14px',color:'black',};
    const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'14px', maxWidth:'500px', textOverflow: 'ellipsis', overflow: 'hidden',lineHeight:1};
    const filtersStyle = {position:'absolute',width:'100%',top:0,minWidth:0,minHeight:0,height:'100%',px: 0.5,m: 0,borderRadius: '0px',"--Input-decoratorChildHeight": "24px",};
    const formControlStyle = { my: 0.2 };

    return (
		<Modal 
			open={props.showForecastWnd} 
			disableRestoreFocus={true}
			disableEnforceFocus={true}
            sx={{backdropFilter:'blur(0px)'}}
		>
			<ModalDialog 
				variant="outlined" 
				role="alertdialog"
				sx={{
					p:0,
					borderRadius:0,
                    width:'60%',
                    height:'70%',
					gap:0,
                    minWidth:'1000px',
                    minHeight:'690px'
				}}
			>
				<DialogTitle
					sx={{
						py:0.5,
						pl:1,
						bgcolor:'#08246b',
						color:'white',
                        fontSize:'12px'
					}}
				>
                    Forecast Planning
					<IconButton 
						onClick={() => props.setShowForecastWnd(false)}
						sx={{
							mr:1,
							ml:'auto',
							height:'12px',
							minHeight:'0px',
							width:'12px',
							minWidth:'0px',
							'--Icon-color':'white',
                            borderRadius:0,
                            p:1
						}}
					>
						<CloseIcon/>
					</IconButton>
				</DialogTitle>
				<DialogContent 
					sx={{
						m:0,
						p:0.5,
                        bgcolor:'#d6d3ce',
                        display:'grid',
                        gridTemplateRows:'40px auto 12px 20px',
                        gap:0.5
					}}
				>
					<Box
                        sx={{
                            display:'flex',
                            flexDirection:'row',
                            alignItems:'flex-end'
                        }}
                    >
                        <FormControl>
                            <FormLabel sx={labelStyle}>AC Reg.:</FormLabel>
                            <Autocomplete
                                disableClearable
                                options={[]}
                                value={'RA-00002'}
                                //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                                sx={{...inputStyle, width:'90px'}}
                                
                            />
                        </FormControl>
                        <FormControl sx={{}}>
                            <FormLabel sx={labelStyle}>ID Number:</FormLabel>
                            <Input  
                                sx={{
                                    ...inputStyle,
                                    width:'160px',
                                }} 
                                //value={selectedAc?.acfamily ?? ''}
                            />
                        </FormControl>
                        <RadioGroup 
                            orientation='horizontal' 
                            sx={{
                                '--RadioGroup-gap':'8px',
                                border:'1px solid black',
                                borderRadius:'0px',
                                p:0.5,
                                zIndex:1,
                                height:'30px',
                                alignItems:'center'
                            }}
                        >
                            <Radio
                                label="All:" 
                                variant="outlined" 
                                color='neutral'
                                sx={{
                                    '--Radio-size':'12px',
                                    gap:0.4,
                                    fontSize:12
                                }}
                                //checked={filters.status === 'All'}
                                //onChange={() => handleChange('status', 'All')}
                            />
                            <Radio
                                label="Tasks:" 
                                variant="outlined" 
                                color='neutral'
                                sx={{
                                    '--Radio-size':'12px',
                                    gap:0.4,
                                    fontSize:12,
                                    
                                }}
                                //checked={filters.status === 'Over'}
                                //onChange={() => handleChange('status', 'Over')}
                            />
                            <Radio
                                label="Checks:" 
                                variant="outlined" 
                                color='neutral'
                                sx={{
                                    '--Radio-size':'12px',
                                    gap:0.4,
                                    fontSize:12,
                                    
                                }}
                                //checked={filters.status === 'Sch'}
                                //onChange={() => handleChange('status', 'Sch')}
                            />
                            <Radio
                                label="EC:" 
                                variant="outlined" 
                                color='neutral'
                                sx={{
                                    '--Radio-size':'12px',
                                    gap:0.4,
                                    fontSize:12,
                                    
                                }}
                                //checked={filters.status === 'Fin'}
                                //onChange={() => handleChange('status', 'Fin')}
                            />
                            <Radio
                                label="NRC:" 
                                variant="outlined" 
                                color='neutral'
                                sx={{
                                    '--Radio-size':'12px',
                                    gap:0.4,
                                    fontSize:12,
                                    
                                }}
                                //checked={filters.status === 'Na'}
                                //onChange={() => handleChange('status', 'Na')}
                            />
                        </RadioGroup>
                        <RadioGroup 
                            orientation='horizontal' 
                            sx={{
                                '--RadioGroup-gap':'8px',
                                border:'1px solid black',
                                borderRadius:'0px',
                                p:0.5,
                                zIndex:1,
                                height:'30px',
                                alignItems:'center',
                                ml:0.5
                            }}
                        >
                            <Radio
                                label="2 W:" 
                                variant="outlined" 
                                color='neutral'
                                sx={{
                                    '--Radio-size':'12px',
                                    gap:0.4,
                                    fontSize:12
                                }}
                                //checked={filters.status === 'All'}
                                //onChange={() => handleChange('status', 'All')}
                            />
                            <Radio
                                label="1 M:" 
                                variant="outlined" 
                                color='neutral'
                                sx={{
                                    '--Radio-size':'12px',
                                    gap:0.4,
                                    fontSize:12,
                                    
                                }}
                                //checked={filters.status === 'Over'}
                                //onChange={() => handleChange('status', 'Over')}
                            />
                            <Radio
                                label="2 M:" 
                                variant="outlined" 
                                color='neutral'
                                sx={{
                                    '--Radio-size':'12px',
                                    gap:0.4,
                                    fontSize:12,
                                    
                                }}
                                //checked={filters.status === 'Sch'}
                                //onChange={() => handleChange('status', 'Sch')}
                            />
                            <Radio
                                label="3 M:" 
                                variant="outlined" 
                                color='neutral'
                                sx={{
                                    '--Radio-size':'12px',
                                    gap:0.4,
                                    fontSize:12,
                                    
                                }}
                                //checked={filters.status === 'Fin'}
                                //onChange={() => handleChange('status', 'Fin')}
                            />
                            <Radio
                                label="6 M:" 
                                variant="outlined" 
                                color='neutral'
                                sx={{
                                    '--Radio-size':'12px',
                                    gap:0.4,
                                    fontSize:12,
                                    
                                }}
                                //checked={filters.status === 'Na'}
                                //onChange={() => handleChange('status', 'Na')}
                            />
                            <Radio
                                label="1 Y:" 
                                variant="outlined" 
                                color='neutral'
                                sx={{
                                    '--Radio-size':'12px',
                                    gap:0.4,
                                    fontSize:12,
                                    
                                }}
                                //checked={filters.status === 'Na'}
                                //onChange={() => handleChange('status', 'Na')}
                            />
                        </RadioGroup>
                        <FormControl sx={{}}>
                            <FormLabel sx={labelStyle}>From Date:</FormLabel>
                            <Input
                                type="date"
                                sx={inputStyle}
                                //value={editedData?.employedSince || ''}
                                //onChange={(event) => handleChange('employedSince', event.target.value)}
                            />
                        </FormControl>
                        <FormControl sx={{}}>
                            <FormLabel sx={labelStyle}>To Date:</FormLabel>
                            <Input
                                type="date"
                                sx={inputStyle}
                                //value={editedData?.employedSince || ''}
                                //onChange={(event) => handleChange('employedSince', event.target.value)}
                            />
                        </FormControl>
                        <Button
                            //onClick={()=>{setIsApuSelection(true)}}
                            variant="outlined"
                            color="neutral"
                            sx={{
                                borderRadius: '0px',
                                fontSize: '12px',
                                fontWeight:100,
                                backgroundColor:'#c6c3ff',
                                border:'2px outset white',
                                height:32,
                                width:50,
                                lineHeight:1,
                                minHeight:0,
                                p:0,
                                ml:'auto'
                            }}
                        >
                            Excel
                        </Button>
                        
                    </Box>
                    <Box 
                        sx={{
                            border:'1px solid black'
                        }}
                    >
                        
                    </Box>
                    <Box
                        sx={{
                           fontSize:'12px',
                           color:'black'
                        }}
                    >
                        Found 100 Records
                    </Box>
                    <Box
                        sx={{
                           display:'flex',
                           flexDirection:'row',
                           alignItems:'center'
                        }}
                    >
                        <Box
                            sx={{
                                display:'flex',
                                flexDirection:'row',
                                width:'170px',
                                color:'black',
                                fontStyle:'italic',
                                fontSize:'12px',
                                alignItems:'center'
                            }}
                        >
                            <Box
                                sx={{
                                    border:'1px solid black',
                                    bgcolor:'#ffc3c6',
                                    width:'15px',
                                    height:'15px',
                                    mr:0.5
                                }}
                            />
                            - overdue WP not Exist
                        </Box>
                        <Box
                            sx={{
                                display:'flex',
                                flexDirection:'row',
                                width:'170px',
                                color:'black',
                                fontStyle:'italic',
                                fontSize:'12px',
                                alignItems:'center'
                            }}
                        >
                            <Box
                                sx={{
                                    border:'1px solid black',
                                    bgcolor:'#ffe3c6',
                                    width:'15px',
                                    height:'15px',
                                    mr:0.5
                                }}
                            />
                            - overdue WP Exist
                        </Box>
                        <Box
                            sx={{
                                display:'flex',
                                flexDirection:'row',
                                width:'170px',
                                color:'black',
                                fontStyle:'italic',
                                fontSize:'12px',
                                alignItems:'center'
                            }}
                        >
                            <Box
                                sx={{
                                    border:'1px solid black',
                                    bgcolor:'#ffffc6',
                                    width:'15px',
                                    height:'15px',
                                    mr:0.5
                                }}
                            />
                            - less then 21 days
                        </Box>
                        <Box
                            sx={{
                                display:'flex',
                                flexDirection:'row',
                                width:'170px',
                                color:'black',
                                fontStyle:'italic',
                                fontSize:'12px',
                                alignItems:'center'
                            }}
                        >
                            <Box
                                sx={{
                                    border:'1px solid black',
                                    bgcolor:'#c6ffc6',
                                    width:'15px',
                                    height:'15px',
                                    mr:0.5
                                }}
                            />
                            - more then 21 days
                        </Box>
                    </Box>
				</DialogContent>
			</ModalDialog>
     	</Modal>
	);
}