import * as React from 'react';

import Modal from '@mui/joy/Modal';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';

import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default function ConfirmChangeTab(props) {

    const handleYes = () => {
        
    };
    
    return (
        <Modal 
            open={props.showConfirmChangeTab} 
            disableRestoreFocus={true}
            disableEnforceFocus={true}
        >
            <ModalDialog 
                variant="outlined" 
                role="alertdialog"
                sx={{
                    p:0,
                    borderRadius:0
                }}
            >
                <DialogTitle
                    sx={{
                        py:0.5,
                        pl:1,
                        bgcolor:'#08246b',
                        color:'white',
                    }}
                >
                    <IconButton 
                        onClick={() => props.setShowConfirmChangeTab(false)}
                        sx={{
                            mr:1,
                            ml:'auto',
                            height:'19px',
                            minHeight:'0px',
                            width:'19px',
                            minWidth:'0px',
                            color:'black'
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent 
                    sx={{
                        display:'flex',
                        flexDirection:'row',
                        alignItems:'center',
                        justifyContent:'center',
                        fontSize:'18px',
                        color:'black',
                        px:1
                    }}
                >
                    <HelpOutlineIcon sx={{width:'40px', height:'40px'}}/>
                    <div>
                        The changes are not saved. <br/> Are you sure you're going to change the window?
                    </div>
                </DialogContent>
                <DialogActions
                
                    sx={{
                        bgcolor:'#d6d3ce',
                        pt:0,
                    }}
                >
                    <Input 
                        key='No'
                        type = 'Button' 
                        onClick={props.onCancel}
                        autoFocus
                        value='No'
                        sx={{
                            borderRadius:0,
                            minHeight:0,
                            minWidth:0,
                            height:'26px',
                            width:'70px',
                            border:'2px solid black',
                            bgcolor:'#d6d3ce',
                            color:'black',
                            m:1,
                            '&:hover': {
                                backgroundColor: '#a8a5e6',
                            }
                        }}         
                    />
                    <Button
                        onClick={props.onConfirm}
                        color='neutral'
                        sx={{
                            borderRadius:0,
                            minHeight:0,
                            minWidth:0,
                            height:'26px',
                            width:'70px',
                            border:'1px solid black',
                            bgcolor:'#d6d3ce',
                            color:'black',
                            m:1,
                            '&:hover': {
                                backgroundColor: '#a8a5e6',
                            }
                        }}                            
                    >
                        Yes
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}