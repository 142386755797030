import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Divider from '@mui/joy/Divider';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import { ToastContainer, toast, Zoom} from "react-toastify";

//Компоненты 
import PartEffectivityEditore from './PartEffectivityEditore.tsx'
import MaintenanceAddEditeForm from './PartMaintenancePlanEditor.tsx';

import AmpPartEffictivityTree from './TreeViews/AmpPartEffectivityTree.tsx';
import AmpPlanMaintenanceTree from './TreeViews/AmpPlanMaintenanceTree.tsx';
import AmpPositionsTree from './TreeViews/AmpPositionsTree.tsx';
import Spiner from '../../../Components/Spiner.tsx'

export default function Pos_Struct(props) {
	//Стили
	const inputStyle = {borderRadius:'0px', minHeight:'0px', height:'26px', border:'1px solid black',color:'#000078', fontWeight:'bold',fontSize:'14px', mb:1, minWidth:0,width:'100%'}
	const lableStyle = {m:0, fontFamily:'Arial, sans-serif', fontSize:'14px',alignSelf:'center',lineHeight:1, color:'black'};

	const [filters, setFilters] = React.useState({subAssy:false, ipc:'', position:'', partEff:'', fin:'', description:''});
	const [showEditor, setShowEditor] = React.useState (true);
	const [editorVariant, setEditorVariant] = React.useState ('PE');
	const [positionId, setPositionId] = React.useState(null);
	const [selectedMP, setSelectedMP] = React.useState(null);
	const [selectedPE, setSelectedPE] = React.useState(null);
	const [showSpinner, setShowSpinner] = React.useState(0)
	const [positionsCount, setPositionsCount] = React.useState(0)
	
	const toastOptions = {
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: false,
		theme: "light",
		transition: Zoom
	};

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}
	
	//#region Изменение высоты таблицы
	const [isDragging, setIsDragging] = React.useState(false);
	const [defHeight, setDefHeight] = React.useState(200)
	const [tableAreaHeight, setTableAreaHeight] = React.useState(defHeight);
	const [startPosition, setStartPosition] = React.useState({ y: 0});

	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({ y: e.clientY});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		const deltaY = e.clientY - startPosition.y;
		setTableAreaHeight(defHeight+deltaY)
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
		setDefHeight(tableAreaHeight)
	};
	//#endregion

	//#region Lazy loading для дерева
		const [loading, setLoading] = React.useState(false); // Состояние загрузки
		const tableRef = React.useRef(null); // Ссылка на элемент таблицы
		const [sliceCount, setSliceCount] = React.useState(100)
		
		const handleScroll = () => {
			if (tableRef.current) {
				const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
				if (scrollTop + clientHeight >= scrollHeight - 5 && !loading) {
					setSliceCount(value=>value+50)
				}
			}
		};
	
		React.useEffect(() => {
			const tableElement = tableRef.current;
			if (tableElement) {
				tableElement.addEventListener('scroll', handleScroll);
			}
			return () => {
				if (tableElement) {
					tableElement.removeEventListener('scroll', handleScroll);
				}
			};
		}, [loading]);
	//#endregion

	return (
		<React.Fragment>
			<ToastContainer/>
			{showSpinner !== 0 && (<Spiner/>)}
			<Box
				id='main'
				sx={{
					position:'relative',
					height:'100%',
					display:'grid',
					gridTemplateColumns:showEditor?'auto 660px':'100%',
				}}
			>
				<Box
					id='tablesArea'
					onMouseMove={handleMouseMove}
					onMouseUp={handleMouseUp}
					sx={{
						display: 'grid',
						gridTemplateRows:`${tableAreaHeight}px 10px auto`,
						position:'relative'
					}}
				>
					<Box
						id='topArea'
						sx={{
							position:'relative',
						}}
					>
						<Typography 
							id='topAreaTitle'
							sx={{ 
								position:'absolute',
								fontSize:'16px',
								fontWeight:'bold',
								color:'#000078',
								fontFamily:'Arial, sans-serif',
								left:16
							}}
						>
							Part Effectivity, Maintenance Plan:
						</Typography>	
						<Box
							id='buttons'
							sx={{
								display:'flex',
								alignItems:'center',
								justifyContent:'center',
								position:'absolute',
								right:16,
								height:40
							}}
						>
							<Tooltip title= {showEditor? 'Hide Editor' : 'Show Editor'}>
								<IconButton
									id='editoreButton'
									variant='plain'
									onClick={toggleEditore} 
									sx={{
										m:0,
										transition: '0.3s',
										transform: showEditor? 'rotate(0deg)' : 'rotate(180deg)',
									}}
								>
									<img 
										width='35px' 
										src="/images/svg/arrowBack.svg" 
										alt="" 
									/>
								</IconButton>
							</Tooltip>
						</Box>		
						<Sheet
							id='tablesSheet'
							variant="outlined"
							sx={{
								position:'absolute',
								width: `calc(100% - 32px)`,
								height:`calc(100% - 54px)`,
								borderRadius: '0px',
								flexShrink: 1,
								overflow: 'auto',
								border: '1px solid black',
								bgcolor:'white',
								mx:'16px',
								mt:'44px',
								mb:'10px'
							}}
						>
							<AmpPartEffictivityTree 
								ampId={props.selectedAmp?.id}
								positionId={positionId} 
								selectVariant={setEditorVariant}
								selectedEditorVariant={editorVariant}
								setSelectedPE={setSelectedPE}
								setShowSpinner={setShowSpinner}
							/>
							<AmpPlanMaintenanceTree 
								ampId={props.selectedAmp?.id}
								positionId={positionId} 
								selectVariant={setEditorVariant}
								setSelectedMP={setSelectedMP}
								selectedEditorVariant={editorVariant}
								setShowSpinner={setShowSpinner}
							/>
						</Sheet>
					</Box>
					<Box
						onMouseDown={handleMouseDown}
						onMouseMove={handleMouseMove}
						onMouseUp={handleMouseUp}
						sx={{
							cursor:'ns-resize',
							position:'relative',
						}}
					>
						<Divider 
							onMouseDown={handleMouseDown}
							onMouseMove={handleMouseMove}
							onMouseUp={handleMouseUp}
							orientation="horizontal"
							sx={{
								cursor:'ns-resize',
								position:'absolute',
								width:'100%'
							}}
						/>
					</Box>		
					<Box
						id='bottomArea'
						sx={{
							position:'relative',
						}}
					>
						<Typography 
							id='bottomAreaTitle'
							sx={{ 
								position:'absolute',
								fontSize:'16px',
								fontWeight:'bold',
								color:'#000078',
								fontFamily:'Arial, sans-serif',
								left:16
							}}
						>
							Positions:
						</Typography>	
						<Box
							id='buttons'
							sx={{
								display:'flex',
								alignItems:'center',
								justifyContent:'center',
								position:'absolute',
								right:16,
								height:40,
								width:`100%`,
							}}
						>
							<Box
								className="SearchAndFilters-tabletUp"
								sx={{
									width:'100%',
									display:'grid',
									gridTemplateColumns:'auto 100px repeat(5, 12%)',
									gap:1
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems:'flex-end',
										justifyContent:'center',
										gridColumn:2,
										mb:1
									}}
								>
									<Typography sx={{...lableStyle,alignSelf:'flex-end'}}>
										Sub-Assy:
									</Typography>
									<Checkbox
										checked={filters.subAssy}
										onChange={(event) => handleChange('subAssy', event.target.checked)}
										variant='outlined'
										color='neutral'
										sx={{
											ml:1,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
									/>
								</Box>
								<FormControl>
									<FormLabel sx={lableStyle}>IPS Pos.:</FormLabel>
									<Input
										value={filters.ipc}
										onChange={(event) => handleChange('ipc', event.target.value)}
										sx={inputStyle}
									/>
								</FormControl>
								<FormControl>
									<FormLabel sx={lableStyle}>Position:</FormLabel>
									<Input
										value={filters.position}
										onChange={(event) => handleChange('position', event.target.value)}
										sx={inputStyle}
									/>
								</FormControl>
								<FormControl>
									<FormLabel sx={lableStyle}>Part Eff.:</FormLabel>
									<Input
										value={filters.partEff}
										onChange={(event) => handleChange('partEff', event.target.value)}
										sx={inputStyle}
									/>
								</FormControl>
								<FormControl>
									<FormLabel sx={lableStyle}>FIN:</FormLabel>
									<Input
										value={filters.fin}
										onChange={(event) => handleChange('fin', event.target.value)}
										sx={inputStyle}
									/>
								</FormControl>
								<FormControl>
									<FormLabel sx={lableStyle}>Description:</FormLabel>
									<Input
										value={filters.description}
										onChange={(event) => handleChange('description', event.target.value)}
										sx={inputStyle}
									/>
								</FormControl>
							</Box>
						</Box>		
						<Sheet
							id='tablesSheet'
							variant="outlined"
							sx={{
								position:'absolute',
								width: `calc(100% - 32px)`,
								height:`calc(100% - 74px)`,
								borderRadius: '0px',
								flexShrink: 1,
								overflow: 'auto',
								border: '1px solid black',
								bgcolor:'white',
								mx:'16px',
								mt:'44px',
								mb:'10px'
							}}
							ref={tableRef}
						>
							<AmpPositionsTree 
								setPositionId={setPositionId}
								selectVariant={setEditorVariant}
								setShowSpinner={setShowSpinner}
								acFamily={props.selectedACFamily}
								ampId={props.selectedAmp?.id} 
								skip={0} 
								take={0} 
								isSubassy={filters.subAssy}
								partEff={filters.partEff}
								ipcPos={filters.ipc}
								description={filters.description}
								position={filters.position}
								fin={filters.fin} 
								sliceCount={sliceCount}
								selectedOperator={props.selectedOperator}
								setPositionsCount={setPositionsCount}
							/>
						</Sheet>
						<Box
							sx={{
								display: 'flex',
								position:'absolute',
								width: `calc(100% - 32px)`,
								height:`20px`,
								bottom:5,
								mx:'16px',
								alignItems:'center',
								justifyContent:'space-between',
							}}
						>
							{positionsCount !== 0 ? (
								<Typography
									sx={{
										fontSize:'12px',
									}}
								>
									Found {positionsCount} Positions
								</Typography>
							):(
								<Typography
									sx={{
										fontSize:'12px',
									}}
								>
									No Positions were Found!
								</Typography>
							)}
						</Box>
					</Box>
				</Box>
				<Box
					id='editFormArea'
					sx={{
						display:showEditor?'flex':'none',
						position:'relative',
					}}
				>
					{editorVariant === 'MP' ? (
						<MaintenanceAddEditeForm
							URL={props.URL}
							selectedMP={selectedMP} 
							selectedPosition={positionId}
							selectedAmp={props.selectedAmp}
						/>
					):(
						<PartEffectivityEditore
							URL={props.URL}
							selectedPosition={positionId} 
							selectedAmp={props.selectedAmp}
							selectedPE={selectedPE}
							setShowSpinner={setShowSpinner}
						/>
					)}
				</Box>
				
			</Box>
		</React.Fragment>
	);
}