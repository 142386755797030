import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import { ToastContainer, toast, Zoom, ToastOptions} from "react-toastify";

import ConfirmAction from '../../../Components/ConfirmAction.tsx';

export default function AddEditeForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, px:1, mx: 0.5, height: 28, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'18px',"--Input-decoratorChildHeight": "24px", lineHeight:1 };
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'18px', alignSelf:'center', lineHeight:1 };
	const formControlStyle = { my: 1 };

	const [editedData, setEditedData] = React.useState<{id?:number, acFamily?:string, mrbCode?:string, mrbCodeDescription?:string}>();
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [confirmActionType, setConfirmActionType] = React.useState('');
	const windowName = 'MRB Category'
	const [meta, setMeta] = React.useState([{name:'',max:0}]);

	const toastOptions: ToastOptions= {
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: false,
		theme: "light",
		transition: Zoom
	};

	React.useEffect(() => {
		setEditedData(props.selectedRow);
	}, [props.selectedRow]);

	//Запрос ограничений для полей
	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrbCodes/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
			}
		};
		fetchMeta();
	}, []);

	const handleAction = async (type) => {
		setConfirmActionType(type);
		if (type === 'Add'){
			setShowConfirm(true);
		} else if (type === 'Update') {
			setShowConfirm(true);
		} else if (type === 'Delete'){
			setShowConfirm(true);
		}
	};

	//Обработчик события добавления новой записи
	const handleAdd = () =>  {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrbCodes`, 
			{
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({...editedData, ampId:props.selectedAmpId, acFamily:props.selectedACFamily})
			}
		)
		.then(async (response) => {
			if (!response.ok){
				const answer = await response.json()
				if(answer.message){
					toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
				} else {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			} else {
				toast.success("MRB_Code added successfully", toastOptions);
				props.reloadTable();
			}
		})
		.catch(error => console.error('Ошибка добавления записи:', error));
	}

	//Обработчик события обновления выбранной записи
	const handleUpdate = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrbCodes/${props.selectedRow.id}`, 
			{
				method: 'PATCH',
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({...editedData, ampId:props.selectedAmpId, acFamily:props.selectedACFamily})
			}
		)
		.then(async (response) => {
			if (!response.ok){
				const answer = await response.json()
				if(answer.message){
					toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
				} else {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			} else {
				toast.success("MRB_Code updating successfully", toastOptions);
				props.reloadTable();
			}
		})
		.catch(error => console.error('Ошибка обновления записи:', error));
	}

	//Обработчик события удаления выбранной записи
	const handleDelete = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrbCodes/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then(async (response) => {
			if (!response.ok){
				const answer = await response.json()
				if(answer.message){
					toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
				} else {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			} else {
				toast.success("MRB_Code deleted successfully", toastOptions);
				props.reloadTable();
			}
		})
		.catch(error => console.error('Ошибка удаления записи:', error));
	}
	
	//Внесение изменений в editedData
	const handleChange = (property, value) => {
		//Проверка максимальной длинны строки
		const maxLengthDefault = 255;
		let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		if (value.length > maxLength) return;	
		
		setEditedData({ ...editedData, [property]: value });
	};

	//Проверка активности кнопок Add, Update, Delete
	const isButtonActive = (action) => {
		if (action === 'add'){
			return(!(editedData?.mrbCode && editedData?.mrbCodeDescription))
		} else if(action === 'update') {
			if(!(props.selectedRow.id !== 0 && editedData?.mrbCode && editedData?.mrbCodeDescription)){
				return(true)
			} else {
				return(JSON.stringify(props.selectedRow) === JSON.stringify(editedData))
			}
		} else if(action === 'delete') {
			return(props.selectedRow.id === 0)
		} else {
			return true;
		}
	}

	//Проверка наличия введенных данных
	React.useEffect(() => {
		if (editedData !== undefined) {
			if(JSON.stringify(props.selectedRow) === JSON.stringify(editedData)){
				props.setHaveChanges(false)
			} else {
				const temp = Object.entries(editedData).every(([key, value]) => 
					key === 'id' || value === '' || value === null || value === undefined
				);
				props.setHaveChanges(!temp)
			}
		}
	}, [editedData]);
	
	return (
		<Box>
			{showConfirm && (
				<ConfirmAction 
					actionType={confirmActionType} 
					windowName={windowName} 
					onConfirmAdd={handleAdd} 
					onConfirmUpdate={handleUpdate} 
					onConfirmDelete={handleDelete} 
					setShowConfirm={setShowConfirm}
					showConfirm={showConfirm}
				/>
			)}
			<Typography 
				sx={{
					position:'absolute',
					fontSize:'16px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif',
					ml:1,
					whiteSpace: 'nowrap', 
        			overflow: 'hidden',    
        			textOverflow: 'ellipsis',
					width:`calc(100% - 9px)`
				}}
			>
				MRB Category Code Editor:
			</Typography>
			<Box
				sx={{
					position:'absolute',
					whiteSpace: 'nowrap', 
        			overflow: 'hidden',    
        			top:'25px',
					width:`calc(100% - 9px)`
				}}
			>
				<Tooltip title='Add'>
					<IconButton
						variant='plain'
						disabled={isButtonActive('add')}
						onClick={()=>handleAction('Add')}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px' 
							src="/images/svg/add.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title='Update'>
					<IconButton 
						variant='plain'
						disabled={isButtonActive('update')}
						onClick={()=>handleAction('Update')}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px' 
							src="/images/svg/save.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title='Delete'>
					<IconButton 
						variant='plain'
						disabled={props.selectedRow?.id ? false : true}
						onClick={()=>handleAction('Delete')}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px'  
							src="/images/svg/trashcan.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
			</Box>
			
			<Box
				sx={{
					position:'absolute',
					height:`calc(100% - 70px)`,
					width:'100%',
					top:'70px',
					overflow:'auto',
				}}
			>
				<Grid container spacing={0} sx={{ flexGrow: 1, minWidth:'535px', }}>
					<Grid xs={4}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>AC Family:</FormLabel>
							<Input  
								sx={inputStyle} 
								value={props.selectedACFamily ?? ''}
							/>
						</FormControl>
					</Grid>
					<Grid xs={8}></Grid>
					<Grid xs={4}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>MRB Code:</FormLabel>
							<Input  
								sx={{
									...inputStyle, 
									'& input': {
										textAlign: 'center',
									}
								}} 
								value={editedData?.mrbCode ?? ''}
								onChange={(event) => handleChange('mrbCode', event.target.value)}
							/>
						</FormControl>
					</Grid>

					<Grid xs={11.8}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>MRB Code Description:</FormLabel>
							<Textarea 
								maxRows={1}
								sx={{...inputStyle,color:'#000078', height:100}}
								value={editedData?.mrbCodeDescription || ''}
								onChange={(event) => handleChange('mrbCodeDescription', event.target.value)}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}