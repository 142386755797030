import React from "react";

import URL from '../../../../../../URL.js';
import Tree, { useTreeState } from "../../../../../Base/TreeView/index.tsx";

import { ITreeViewIcon } from "../../../../../Base/TreeView/IconInterpterer/ITreeViewIcon.ts";
import { AmpModelIconInterpreter } from "./IconInterpreters/AmpModelIconInterpreter.ts";
import { Interpreter } from "../../../../../Base/TreeView/IconInterpterer/Interpreter.ts";
import { IIconContext } from "../../../../../Base/TreeView/IconInterpterer/IIconContext.ts";

export default function AmpModelTree(props) {
	const [responseStatus, setResponseStatus] = React.useState({
		errorStatus:0, 
		successfulStatus:''
	});
	const [treeData, setTree] = React.useState<{
		id: number, 
		image: ITreeViewIcon, 
		header: string, 
		children: any
	}[]>([]);
	const { required, handlers } = useTreeState({ 
		id: 'tree', 
		data: treeData, 
		defaultOpened: [1, 2], 
		multipleSelect: false 
	});
	
	// Задание интерпретатора для выбора иконок и базовой структуры дерева
	const iconInterpreter = new AmpModelIconInterpreter();
	const treeBaseData: [] = [];

	// Обновление данных
	React.useEffect(() => {
		if(props.ampId){
			setTree([]);
			fetchTreeRootData();
			fetchTreeData(iconInterpreter);	
		}
    }, [props.ampId]);

	// Корневой узел
	const treeRootData = [{
		isRootData: true,
		id: 1,
		image: iconInterpreter.Interpret(getTreeViewIconParams(undefined, true)),
		header: { id: { value: `${props.acFamily}; ${props.selectedOperator}` } },
		children: [{
			id: 2,
			image: iconInterpreter.Interpret(getTreeViewIconParams(undefined, undefined, true)),
			header: { id: { value: `${props.acFamily}; ${props.selectedOperator}: AMP Checks Model`, width: 350 } },
			children: []
		}]
	}];

    // Получение базовой информации в шапке
	const fetchTreeRootData = async () => {
		Object.assign(treeBaseData, treeRootData);
	}

	// Получение общей информации для дерева
	const fetchTreeData = async (iconInterpreter: Interpreter<IIconContext>) => {
		props.setShowSpinner(value => value + 1);
		if (treeBaseData?.length == 0) 
			return;

		const response = await fetchResponseAsync();
		if (response.ok) {
			const result = await response.json();
			treeBaseData[0].children[0].children = [];

			result?.forEach(element => {
				const treeViewIconParams = getTreeViewIconParams(element);
				const headerParams = getNodeHeaderParams(element);

				const child = getChildNode(
					iconInterpreter.Interpret(treeViewIconParams),
					headerParams,
					true
				);
				child.model = element;
				treeBaseData[0].children[0].children.push(child);
			});

			setTree(treeBaseData);
			props.setReacordsCount(result.length)
		} 
		else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
		props.setShowSpinner(value => value - 1);
	};

	// Получение информации о children
	const fetchTreeChildrenData = async (node: any, iconInterpreter: Interpreter<IIconContext>) : Promise<any> => {
		const response = await fetchResponseAsync(true, node);
		if (response.ok) {
			const result = await response.json();
			let childNode = { children: [{}] };
            childNode.children = [];

			var childAdditions: { [id: string]: any } = {};
			result?.forEach(element => {
				const headerParams = getChildrenNodeHeaderParams(element);

				const child = {
                    id: 4,
                    header: headerParams,
                    maxWidth: 500
                };
				childNode.children.push(child);

				if (element.description)
					childAdditions[element.description] = getModel(element);
			});

			return { childNode: childNode, childAdditions: childAdditions };
		} 
		else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	function getModel(element: any = null): any {
		return {
			description: element.description,
			fh: element.fh,
			fc: element.fc,
			dy: element.calendar == 'DY',
			mo: element.calendar == 'MO',
			yr: element.calendar == 'YR',
			calendarValue: element.calendarValue
		};
	}

	const fetchResponseAsync = async (isChild: boolean = false, node: any = undefined): Promise<Response> => {
		var url = isChild
			? `${URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/CheckTreatments(checkId=${node.data?.header?.id?.value})`
			: `${URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/CheckRoot(ampId=${props.ampId},taskFilter='',checkFilter='',fhFilter=${0},fcFilter=${0},calendarKindFilter='DY',calendarValueFilter=${0},resourceOrFIlter=true)`;

		const headers = { 
			headers: { 'Authorization': `Bearer ${localStorage.getItem('asid')}` }
		};

		return await fetch(url, headers);
    }

	function getTreeViewIconParams(element: any = null, isRootProject: boolean = false, isRootPlane: boolean = false): any {
		return { 
			isCyclicCheck: element?.isCyclicCheck,
			isRootProject: isRootProject,
			isRootPlane: isRootPlane
		};
	}

	function getNodeHeaderParams(element: any): any {
		return { 
			id: { value: element.id, width: 70 }, 
			checkId: { value: element.checkId, width: 130 }, 
			checkDescription: { value: element.checkDescription, width: 350 }, 
			isMajorCheck: element.isMajorCheck
				? { value: `(Major Check)`, width: 110 }
				: { value: '', width: 110 }
		};
	}

	function getChildrenNodeHeaderParams(element: any): any {
        var typeString = '';
		var description = `${element.description}: `;
        var fhString = `${element.fh} FH; `;
        var fcString = `${element.fc} FC; `;
        var calendarString = `${element.calendarValue} ${element.calendar}; `;
		
        typeString = typeString.concat(typeString, 
			element.description != null ? description : '',
            element.fh != null ? fhString : '',
            element.fc != null ? fcString : '',
            element.calendarValue != null ? calendarString : ''
        );

		return { 
			type: { value: typeString, width: 500 }, 
            pnEff: element.pn != null 
                ? { value: `PN Eff.: ${element.pn}` }
                : { value: '' }
		};
	}

	function getChildNode(interpreterResult: any, headerParams: any, conditionItem: any = undefined): any {
		const isExtended = conditionItem;
		const simpleChild = {
			id: 3,
			image: interpreterResult,
			header: headerParams
		};
		const extendedChild = {
			...simpleChild,
			children: [{
				...simpleChild,
				header: { id: { value: 'No content' } }
			}]
		};

		return isExtended ? extendedChild : simpleChild;
	}

	const openNodeAsync = async (node) => {
		if (!node.isOpened() && node.data.header?.id?.value > 0) {
			const child = await fetchTreeChildrenData(node, iconInterpreter);
			handlers.setRawChildren(node, child.childNode.children, undefined, true);
		}
		handlers.setOpen(node);
	}

	return (
      	<Tree
			{...required}
			{...handlers}
			gapMode="padding" 
			depthGap={20}
			setSelected={async (node, selected) => {
				handlers.setSelected(node, selected);
				if (selected) {
					if (node.id === 3) {
						props.setCheckId(node?.data?.header?.id?.value);
					}
					if (node.id === 4) {
						props.setCheckId(node?.options?.parent?.data?.header?.id?.value);
					}
				} else{
					props.setCheckId(null);
				}
			}}
			setOpen={openNodeAsync}
		/>
    );
}