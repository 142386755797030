import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Table from '@mui/joy/Table';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import {toast, Zoom, ToastOptions} from "react-toastify";

//Иконки
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';

export default function SubstitutionRegistration(props) {
	//Стили
	const inputStyle = { borderRadius: 0, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'16px' }
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'14px', alignSelf:'center' }
	const formControlStyle = { mb: 1 }
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'14px',color:'black'};
    const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'14px'};

	const toastOptions : ToastOptions = {
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: false,
		theme: "light",
		transition: Zoom
	};

	const headers =[{id:'pn',name:'PN'}, {id:'description',name:'Description'}, {id:'interchangeability',name:'Interchangeability'}, {id:'reference',name:'Reference'}]

	const [selectedData, setSelectedData] = React.useState<{pn?:string, description?:string}>()
	const [data, setData] = React.useState<{idSubst?:number, pn?:string, description?:string, interchangeability?:string, reference?:string}[]>([]);
	const [editedData, setEditedData] = React.useState<{idSubst?:number, pn?:string, description?:string, interchangeability?:string, reference?:string}>();
	const [pnsList, setPnsList] = React.useState([])
    const [sort, setSort] = React.useState ({lable:'idSubst', order:'asc'});
	const [selectedRow, setSelectedRow] = React.useState({idSubst:0});
	const [showListOfPn, setShowListOfPn] = React.useState(false)
	const rowRefs = React.useRef<(HTMLTableRowElement | null)[]>([]);

	const handleClose = () => {
		props.setOpen(false);
	};

	React.useEffect(() => {
		setSelectedData(props.selectedPositionRow);
	}, [props.selectedRow]);

	const fetchTreatmentDescription = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/PartsCatalogs/Desc/${selectedData?.pn}`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			if(response.status !== 204){
				const result = await response.json();
				handleChangeSelected('description', result.description)	
			} else {
				handleChangeSelected('description', undefined)	
			}
			
		} else {
			try{
				const answer = await response.json()
				if(answer.message){
					toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
				} else {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			} catch {
				toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
			}
		}
	};

	const fetchData = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/PartsSubstitutions/PnSubstitutions/${selectedData?.pn}`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setData(result)
		} else {
			try{
				const answer = await response.json()
				if(answer.message){
					toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
				} else {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			} catch {
				toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
			}
		}
	};

	const fetchPnsList = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/PartsCatalogs/Pns(pn=${selectedData?.pn},description=${selectedData?.description})`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setPnsList(result)
		} else {
			try{
				const answer = await response.json()
				if(answer.message){
					toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
				} else {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			} catch {
				toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
			}
		}
	};

	React.useEffect(() => {
		if(showListOfPn) {
			fetchPnsList()
		}
	}, [showListOfPn]);

	React.useEffect(() => {
		if(selectedData?.pn) {
			fetchTreatmentDescription();
			fetchData();
		}
	}, [selectedData?.pn]);



	//Внесение изменений в data
	const handleChangeSelected = (property, value) => {

		//Проверка максимальной длинны строки
		//const maxLengthDefault = 255;
		//let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		//if (value.length > maxLength) return;	
		
		setSelectedData({ ...selectedData, [property]: value });
	};

	//Внесение изменений в data
	const handleChange = (property, value) => {

		//Проверка максимальной длинны строки
		//const maxLengthDefault = 255;
		//let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		//if (value.length > maxLength) return;	
		
		setEditedData({ ...editedData, [property]: value });
	};

	//Выбор строки
	const handleRowSelection = (row) => {
		if(row.idSubst === selectedRow.idSubst){
			setSelectedRow({idSubst:0});
			setEditedData(undefined)
		} else {
			setSelectedRow(row);
			setEditedData(row)
		}  
	};

	//Сортировка данных
	const handleSort = (property, value) => {
		let filtered = data
		if (property === 'idSubst'){
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
		} else {
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
		}
		setData(filtered)
		setSort({lable:property, order:value})
	};

	//#region dnd
	let xcenter = (window.innerWidth/2)-1600;
	let ycenter = (window.innerHeight/2)-400;

	const [isDragging, setIsDragging] = React.useState(false);
  	const [position, setPosition] = React.useState({ x: xcenter, y: ycenter });
  	const [startPosition, setStartPosition] = React.useState({ x: 0, y: 0 });
	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({
			x: e.clientX - position.x,
			y: e.clientY - position.y,
		});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		setPosition({
			x: e.clientX - startPosition.x,
			y: e.clientY - startPosition.y,
		});
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
	};
	//#endregion	
	
	return (
		<React.Fragment>
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: 'rgba(0, 0, 0, 0.3)',
					zIndex: 10,
					overflow:'hidden',
				}}
       		/>
				<Sheet
					variant="plain"
					sx={{
						height:'600px',
						width:'800px',
						borderRadius: '0px',
						position: 'absolute',
						border:'1px solid black',
						zIndex:11,
						left: position.x,
						top: position.y,
					}}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'30px 100px 467px',
							bgcolor:'#d6d3ce'
						}}
					>
						<Box
							onMouseDown={handleMouseDown}
							onMouseMove={handleMouseMove}
							onMouseUp={handleMouseUp}
							sx={{
								display:'flex',
								alignItems:'center',
								background:'#08246b',
								cursor: isDragging ? 'grabbing' : 'grab',
							}}
						>
							<Typography 
								sx={{
									fontSize:'15px',
									color:'white',
                                    ml:1
								}}
							>
								Parts Substitution Registration
							</Typography>
							<IconButton 
							 	onClick={handleClose}
								sx={{
									mr:1,
									ml:'auto',
									'--Icon-color':'white',
									height:'19px',
									minHeight:'0px',
									width:'19px',
									minWidth:'0px'
								}}
							>
								<CloseIcon/>
							</IconButton>
						</Box>
						<Box>
							<Typography
								sx={{
									color:'black',
									ml:1,
									mt:1,
									fontWeight:'bold'
								}}							
							> 
								Selected Part:
							</Typography>
							<Grid container spacing={0} sx={{ flexGrow: 1 }}>
								<Grid xs={6}>
									<FormControl sx={formControlStyle}>
										<FormLabel sx={labelStyle}>
											Selected P/N:
										</FormLabel>
										<Input
                                            value={selectedData?.pn ?? ''}
                                            onChange={(event) => handleChangeSelected('pn', event.target.value)}
                                            //onKeyDown={(event) => handleAdditionalInfo(event, 'mfrCode')}
                                            sx={{
                                                ...inputStyle,
                                                backgroundColor:'#c6ffff',
                                            }}
                                        />
									</FormControl>
								</Grid>
								<Grid xs={6}>
									<FormControl sx={formControlStyle}>
										<FormLabel sx={labelStyle}>
											Description: 
										</FormLabel>
										<Input 
											size="sm" 
											disabled
											sx={{
												...inputStyle, 
												'--variant-outlinedDisabledColor':'black',
												'--variant-outlinedDisabledBorder':'black',
												bgcolor:'#d6d3ce',
											}} 
											value={selectedData?.description ?? ''}
										/>
									</FormControl>
								</Grid>
							</Grid>
						</Box>
						<Box
							sx={{
								display:'flex',
								flexDirection:'row',
								justifyContent:'center',
								maxHeight:'467px',
							}}
						>
							<Box
								sx={{
									height:'467px',
									maxHeight:'467px',
									width:'496px',
									px:1
								}}
							>
								<Typography
									sx={{
										fontWeight:'bold',
										color:'#000078',
										fontFamily:'Arial, sans-serif'
									}}							
								> 
									Selected Part's Substitution:
								</Typography>
								<Sheet
									className="OrderTableContainer"
									variant="outlined"
									sx={{
										minWidth: '0px',
										borderRadius: '0px',
										flexShrink: 1,
										overflow: 'auto',
										minHeight: 0,
										width:'100%',
										bgcolor:'#848284',
										height:'435px',
										border:'1px solid black',

									}}
								>
									<Table
										borderAxis="both"
										stickyHeader
										hoverRow
										sx={{
											'--TableCell-headBackground': '#d6d3ce',
											'--Table-headerUnderlineThickness': '1px',
											'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
											'--TableCell-paddingY': '1px',
											'--TableCell-height': '22px',
											'--TableCell-paddingX': '5px',
											'--TableCell-borderColor': '#939293',
											tableLayout: 'auto', 
											width:'auto',
											borderRadius:'0px'
										}}
									>
										<thead>
											<tr>
												<th style={headerStyle}>
													<Typography
														onClick={() => handleSort('id', sort.order === 'asc' ? 'desc':'asc' )}
														endDecorator={<ArrowDropDownIcon />}
														sx={{
															'& svg': {
																color:sort.lable === 'id' ? '#636b74' : 'transparent',
																transition: '0.2s',
																transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
															},
														}}
													>
														IDSubst
													</Typography>
												</th>
												{ headers.map((row) => (
													<th style={headerStyle}>
														<Typography
															onClick={() => handleSort(row.id, sort.order === 'asc' ? 'desc':'asc' )}
															endDecorator={<ArrowDropDownIcon />}
															sx={{
																'& svg': {
																	color:sort.lable === row.id ? '#636b74' : 'transparent',
																	transition: '0.2s',
																	transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
																},
															}}
														>
															{row.name}
														</Typography>
													</th>
												))}
											</tr>
										</thead>
										<tbody>
											{data.length === 0 && (
												<th colSpan={999}>
													<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
												</th>
											)}
											{data.map((row, index) => (
												<tr
													key={row.idSubst}
													onClick={() => handleRowSelection(row)}
													style={{ 
														backgroundColor: selectedRow.idSubst === row.idSubst ? '#c0c0ff': index % 2 === 0 ? '#EDF2F7' : 'white',
														color: 'black',
													}}
													ref={el => (rowRefs.current[index] = el)}
													tabIndex={0}
												>
													<td style={{...rowStyle, backgroundColor: '#c6c3c6'}}>{row.idSubst}</td>
													<td style={rowStyle}>{row.pn}</td>
													<td style={rowStyle}>{row.description}</td>
													<td style={rowStyle}>{row.interchangeability}</td>
													<td style={rowStyle}>{row.reference}</td>
												</tr>
											))}
										</tbody>
									</Table>
								</Sheet>
							</Box>
							<Box
								sx={{
									height:'467px',
									maxHeight:'467px',
									width:'300px'
								}}
							>
								<Typography 
									sx={{ 
										ml: 1,
										fontWeight:'bold',
										color:'#000078',
										fontFamily:'Arial, sans-serif'
									}}
								>
									Lower Part Editor:
								</Typography>
								<Tooltip title='Add'>
									<IconButton
										variant='plain'
										disabled={true}
										//onClick={handleAdd}
										sx={{
											m:0,
											'&:disabled img': {
												opacity: 0.5,
											}
										}}
									>
										<img 
											width='35px' 
											height='35px' 
											src="/images/svg/add.svg" 
											alt="" 
										/>
									</IconButton>
								</Tooltip>
								<Tooltip title='Update'>
									<IconButton 
										variant='plain'
										disabled={true}
										//onClick={handleUpdate}
										sx={{
											m:0,
											'&:disabled img': {
												opacity: 0.5,
											}
										}}
									>
										<img 
											width='35px' 
											height='35px' 
											src="/images/svg/save.svg" 
											alt="" 
										/>
									</IconButton>
								</Tooltip>
								<Tooltip title='Delete'>
									<IconButton 
										variant='plain'
										disabled={true}
										//onClick={handleDelete}
										sx={{
											m:0,
											'&:disabled img': {
												opacity: 0.5,
											}
										}}
									>
										<img 
											width='35px' 
											height='35px'  
											src="/images/svg/trashcan.svg" 
											alt="" 
										/>
									</IconButton>
								</Tooltip>
								<Grid container spacing={0} sx={{ flexGrow: 1 }}>
									<Grid xs={12} sx={{position:'relative'}}>
										<FormControl sx={formControlStyle} required>
											<FormLabel sx={labelStyle}>
												Substitution P/N:
											</FormLabel>
											<Input
												value={editedData?.pn ?? ''}
												sx={{...inputStyle, backgroundColor:'#c6ffff'}}
												onChange={(event) => handleChange('pn', event.target.value)}
												//onKeyDown={handleOperatorAdditionalInfo}
											/>
										</FormControl>
                                        <Button		
                                            color='neutral'
                                            sx={{
                                                borderRadius:0,
                                                height: 20, 
                                                minHeight: 0,
                                                fontWeight:100,
                                                fontSize:14,
                                                bgcolor:'#c7c3fe',
                                                color:'black',
                                                border:'2px outset grey',
                                                position:'absolute',
                                                top:0,
                                                right:8,
                                                py:0,
                                                px:1
                                            }}
                                            onClick={()=>setShowListOfPn(!showListOfPn)}
                                        >
                                            Select
                                        </Button>
									</Grid>
                                    {showListOfPn ? (
                                        <>
                                            <Typography sx={labelStyle}>
                                                List of PN:    
                                            </Typography>
                                            <Grid xs={12}>
                                                <Box
                                                    sx={{
                                                        border:'1px solid black',
                                                        height:'264px',
                                                        mx:1,
                                                        borderRadius:'0px',
                                                        bgcolor:'white',
                                                        overflow:'auto',
                                                    }}
                                                >
                                                    <Table
                                                        aria-labelledby="tableTitle"
                                                        borderAxis="none"
                                                        stickyHeader
                                                        hoverRow
                                                        sx={{
                                                            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                                                            '--TableCell-paddingY': '1px',
                                                            "--TableCell-height": "16px",
                                                            '--TableCell-paddingX': '5px',
                                                            tableLayout: 'auto', 
                                                            width:'auto',
                                                            borderRadius:'0px',
                                                            lineHeight:0,
                                                            backgroundColor:'#c6ffff',
                                                        }}
                                                    >
                                                        <tbody>
                                                            {/*positionPnList.map((row) => (
                                                                <tr
                                                                    key={row.pn}
                                                                    //onClick={() => setSelectedGroupForModule(row)}
                                                                    style={{ 
                                                                        //backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
                                                                        //color: selectedGroupForModule === row ? 'white' : 'black',
                                                                    }}
                                                                >
                                                                    <td style={rowStyle}>{row.pn}</td>
                                                                    <td style={rowStyle}>{row.description}</td>
                                                                </tr>
                                                            ))*/}
                                                        </tbody>
                                                    </Table>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <FormControl sx={formControlStyle}>
                                                    <FormLabel sx={labelStyle}>
                                                        Filter PN: 
                                                    </FormLabel>
                                                    <Input 
                                                        type="number"
                                                        slotProps={{
                                                            input: {
                                                                step: 1,
                                                                min:0
                                                            }
                                                        }}
                                                        sx={{
                                                            ...inputStyle, 
                                                        }} 
                                                        //value={editedData.code ? sta.find(item => item.code === editedData.code)?.country : ''}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid xs={6}>
                                                <FormControl sx={formControlStyle}>
                                                    <FormLabel sx={labelStyle}>
                                                        Filter Description: 
                                                    </FormLabel>
                                                    <Input 
                                                        type="number"
                                                        slotProps={{
                                                            input: {
                                                                step: 1,
                                                                min:0
                                                            }
                                                        }}
                                                        sx={{
                                                            ...inputStyle, 
                                                        }} 
                                                        //value={editedData.code ? sta.find(item => item.code === editedData.code)?.country : ''}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>
                                    ):(
                                        <>
                                            <Grid xs={12}>
                                                <FormControl sx={formControlStyle}>
                                                    <FormLabel sx={labelStyle}>
                                                        Description:
                                                    </FormLabel>
                                                    <Textarea
                                                        maxRows={7}
                                                        disabled
                                                        value={editedData?.description ?? ''}
                                                        sx={{ 
                                                            ...inputStyle, 
                                                            height: '50px', 
                                                            '--variant-outlinedDisabledColor':'black',
                                                            '--variant-outlinedDisabledBorder':'black',
                                                            bgcolor:'#d6d3ce',
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid xs={6}>
                                                <FormControl sx={formControlStyle} required>
                                                    <FormLabel sx={labelStyle}>
                                                    	Interchangeability: 
                                                    </FormLabel>
													<Autocomplete
														options={['Full', 'OneWay', 'NHA']}
														value={editedData?.interchangeability ?? ''}
														onChange={(event, value) => handleChange('interchangeability', value)}
														sx={{...inputStyle, backgroundColor:'#c6ffff', px:1}}
													/>
                                                </FormControl>
                                            </Grid>
                                            <Grid xs={12}>
                                                <FormControl sx={formControlStyle}>
                                                    <FormLabel sx={labelStyle}>
                                                        Reference:
                                                    </FormLabel>
                                                    <Textarea
                                                        maxRows={7}
                                                        value={editedData?.reference ?? ''}
														onChange={(event) => handleChange('reference', event.target.value)}
                                                        sx={{ ...inputStyle, height: '180px', }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>
                                    )}
								</Grid>	
							</Box>		
						</Box>
					</Box>
				</Sheet>
		</React.Fragment>
	);
}