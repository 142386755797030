import * as React from 'react';

import Modal from '@mui/joy/Modal';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Sheet from '@mui/joy/Sheet';


//Иконки
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';

import AmpPlanTree from './TreeViews/AmpPlanTree.tsx';
import AmpModelTree from './TreeViews/AmpModelTree.tsx';

export default function MoveTaskWnd (props) {
    const inputStyle = {borderRadius:'0px', minHeight:'0px', height:'26px', border:'1px solid black',color:'#000078', fontWeight:'bold',fontSize:'14px', mb:1,}
    const labelStyle = {m:0, fontFamily:'Arial, sans-serif', fontSize:'12px',alignSelf:'center',lineHeight:1};

    const [checksCount, setChecksCount] = React.useState(0)
    const [outOfChecksCount, setOutOfChecksCount] = React.useState(0)
    const [checkId, setCheckId] = React.useState(0);
    const [showSpinner, setShowSpinner] = React.useState(0)
    const [recordsCount, setReacordsCount] = React.useState(0);

    const handleMove = async () =>  {
        fetch(
            `${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelChecks/MoveTaskIntoMp`, 
            {
                method: 'POST',
                headers: {
                'Authorization': `Bearer ${localStorage.getItem('asid')}`,
                'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    request: 
                    {
                        ampid: props.selectedAmpId,
                        mrid: props.selectedRow.id,
                        checkid: checkId
                    }
                })
            }
        )
        .then((response) => {
            if (!response.ok){
                props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
            } else {
                props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
            }
            return response.json();
        })
        .catch(error => console.error('Ошибка создания группы:', error));
	}

    return (
		<Modal 
			open={props.showMoveTaskWnd} 
			disableRestoreFocus={true}
			disableEnforceFocus={true}
            sx={{backdropFilter:'blur(0px)'}}
		>
			<ModalDialog 
				variant="outlined" 
				role="alertdialog"
				sx={{
					p:0,
					borderRadius:0,
                    width:'60%',
                    height:'70%',
					gap:0,
                    minWidth:'1000px',
                    minHeight:'690px'
				}}
			>
				<DialogTitle
					sx={{
						py:0.5,
						pl:1,
						bgcolor:'#08246b',
						color:'white',
                        fontSize:'12px'
					}}
				>
                    Move Task to another Check
					<IconButton 
						onClick={() => props.setShowMoveTaskWnd(false)}
						sx={{
							mr:1,
							ml:'auto',
							height:'12px',
							minHeight:'0px',
							width:'12px',
							minWidth:'0px',
							'--Icon-color':'white',
                            borderRadius:0,
                            p:1
						}}
					>
						<CloseIcon/>
					</IconButton>
				</DialogTitle>
				<DialogContent 
					sx={{
						m:0,
						p:0,
                        bgcolor:'#d6d3ce',
                        position:'relative'
					}}
				>
                    <Box
                        sx={{
                            width:'100%',
                            height:'100%',
                            display:'grid',
                            gridTemplateColumns:'55% 45%',
                            gridTemplateRows:'10% 90%',
                        }}
                    >
                        <Box
                            sx={{
                                display:'flex',
                                alignItems:'flex-end'
                            }}
                        >
                            <Box
                                sx={{
                                    width:'50%',
                                    ml:'16px',
                                }}
                            >
                                <FormControl>
                                    <FormLabel sx={labelStyle}>Task:</FormLabel>
                                    <Input  
                                        disabled
                                        sx={{
                                            ...inputStyle, 
                                            '--variant-outlinedDisabledColor':'black',
                                            '--variant-outlinedDisabledBorder':'black',
                                            bgcolor:'#d6d3ce',
                                            color:'black',
                                            '& input':{
                                                textAlign:'center'
                                            }
                                        }} 
                                        value={props.selectedRow?.task}

                                    />
                                </FormControl>
                            </Box>
                        </Box>
                        <Box/>
                        <Box 
                            sx={{
                                position:'relative'
                            }}
                        >
                            <Sheet
                                id='tablesSheet'
                                variant="outlined"
                                sx={{
                                    position:'absolute',
                                    width:`calc(100% - 86px)`,
                                    height:`calc(100% - 30px)`,
                                    borderRadius: '0px',
                                    flexShrink: 1,
                                    overflow: 'auto',
                                    border: '1px solid black',
                                    bgcolor:'white',
                                    mx:'16px',
                                }}
                            >
                                <AmpPlanTree
                                    setOutOfChecksCount={setOutOfChecksCount}
                                    setChecksCount={setChecksCount}
                                    selectedOperator={props.selectedOperator}
                                    acFamily={props.selectedACFamily}
                                    ampId={props.selectedAmpId}
                                    taskId={props.selectedRow.id}
                                />
                            </Sheet>
                            <Box
                                sx={{
                                    display: 'flex',
                                    position:'absolute',
                                    width: `calc(100% - 32px)`,
                                    height:`20px`,
                                    bottom:5,
                                    mx:'16px',
                                    alignItems:'center',
                                    justifyContent:'flex-start',
                                }}
                            >
                                {checksCount !== 0 ? (
                                    <Typography
                                        sx={{
                                            fontSize:'12px',
                                        }}
                                    >
                                        Found {checksCount} Cheks;
                                    </Typography>
                                ):(
                                    <Typography
                                        sx={{
                                            fontSize:'12px',
                                        }}
                                    >
                                        No Cheks were Found!;
                                    </Typography>
                                )}
                                {outOfChecksCount !== 0 && (
                                    <Typography
                                        sx={{
                                            fontSize:'12px',
                                        }}
                                    >
                                        Found {outOfChecksCount} Out ofCheck Tasks
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    position:'absolute',
                                    right:0,
                                    display:'flex',
                                    flexDirection:'column',
                                }}
                            >
                                <Button	
                                    onClick={handleMove}	
                                    color='neutral'
                                    disabled={!checkId}
                                    sx={{
                                        borderRadius:0,
                                        height: 30, 
                                        minHeight: 0,
                                        width:50,
                                        whiteSpace:'wrap',
                                        fontSize:12,
                                        mx:1,
                                        mb:1,
                                        bgcolor:'#c7c3fe',
                                        color:'black',
                                        border:'2px outset grey',
                                        lineHeight:1
                                    }}
                                >
                                    <KeyboardArrowRightIcon/>
                                </Button>
                            </Box>
                        </Box>
                        <Box
                            id='tableArea'
                            sx={{
                                position:'relative',
                                minWidth:'100px'
                            }}
                        >
                            <Sheet
                                id='tablesSheet'
                                variant="outlined"
                                sx={{
                                    position:'absolute',
                                    width: `calc(100% - 32px)`,
                                    height:`calc(100% - 30px)`,
                                    borderRadius: '0px',
                                    flexShrink: 1,
                                    overflow: 'auto',
                                    border: '1px solid black',
                                    bgcolor:'white',
                                    mx:'16px',
                                }}
                            >
                                <AmpModelTree 
                                    ampId={props.selectedAmpId} 
                                    acFamily={props.selectedACFamily}
                                    setCheckId={setCheckId}		
                                    setShowSpinner={setShowSpinner}
                                    selectedOperator={props.selectedOperator}
                                    setReacordsCount={setReacordsCount}
                                />
                            </Sheet>
                            <Box
                                sx={{
                                    display: 'flex',
                                    position:'absolute',
                                    width: `calc(100% - 32px)`,
                                    height:`20px`,
                                    bottom:5,
                                    mx:'16px',
                                    alignItems:'center',
                                    justifyContent:'space-between',
                                }}
                            >
                                {recordsCount !== 0 ? (
                                    <Typography
                                        sx={{
                                            fontSize:'12px',
                                        }}
                                    >
                                        Found {recordsCount} Checks
                                    </Typography>
                                ):(
                                    <Typography
                                        sx={{
                                            fontSize:'12px',
                                        }}
                                    >
                                        No Checks were Found!
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
				</DialogContent>
				<DialogActions
					sx={{
						bgcolor:'#d6d3ce',
						pt:0,
                        display:'grid',
                        gridTemplateColumns:'auto 80px',
                        px:2,
					}}
				>
					<Button
                        variant="outlined"
                        color="neutral"
                        onClick={() => props.setShowMoveTaskWnd(false)}
                        sx={{
                            borderRadius: '0px',
                            fontSize: '14px',
                            fontWeight:100,
                            backgroundColor:'#dedff7',
                            border:'2px outset white',
                            height:'50px',
                            width:'80px',
                            display:'flex',
                            flexDirection:'column',
                            mr:1,
                            gridColumn:2
                        }}
                    >
                        <img 
                            width='27px' 
                            height='27px' 
                            src="/images/OUTLOOK7.ico" 
                            alt="" 
                        />
                        Close
                    </Button>
				</DialogActions>
			</ModalDialog>
     	</Modal>
	);
}