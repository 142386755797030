import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Autocomplete from '@mui/joy/Autocomplete';
import Radio from '@mui/joy/Radio'
import RadioGroup from '@mui/joy/RadioGroup';
import Tabs from '@mui/joy/Tabs';
import Tab from '@mui/joy/Tab'
import Input from '@mui/joy/Input'
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Typography from '@mui/joy/Typography';
import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider } from '@mui/joy/styles';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import { Height } from '@mui/icons-material';
import Textarea from '@mui/joy/Textarea';

export default function WP(props) {
    const tabStyle = {fontSize:'12px', lineHeight:1, py:0, px:1, minHeight:22};
    const inputStyle = {borderRadius: 0, px:0.5, height: 22, minHeight: 0, minWidth:0, width:'100%', border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'10px',"--Input-decoratorChildHeight": "18px", lineHeight:1,  '& input': {textAlign: 'center',}};
	const labelStyle = {my: 0, p: 0, fontSize:'10px', alignSelf:'center', lineHeight:1 };
    const formStyle = {width:'100%', px:0.5,overflow:'hidden', position:'relative'}
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'14px', lineHeight:1};

    const New = () => {
        return(
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows:'120px auto',
                    height:'100%',
                    pt:1,
                    px:0.5,
                    width:'100%',
                    position:'relative',
                    gap:1,
                }}
            >
                <Box
                    sx={{
                        border:'1px solid black',
                        height:'100%',
                        display:'grid',
                        position:'relative',
                        gridTemplateRows:'33% 33% 34%',
                        gridTemplateColumns:'auto 16% 10% 19% 19% 70px',
                        pt:1,
                        maxWidth:'100%',
                    }}
                >
                    <Box
                        sx={{
                            position:'absolute',
                            top:-8,
                            left:5,
                            fontSize:'10px',
                            bgcolor:'#d6d3ce',
                            color:'black'
                        }}
                    >
                        Work Package Editor (New):
                    </Box>
                   
                    <FormControl 
                        required
                        sx={{
                            ...formStyle,
                            gridColumnStart:1,
                            gridColumnEnd:3,
                        }}
                    >
                        <FormLabel sx={labelStyle}>WP Number:</FormLabel>
                        <Input  
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl> 
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>Rev.:</FormLabel>
                        <Input  
                            sx={{
                                ...inputStyle, 
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>WP Date:</FormLabel>
                        <Input  
                            type="date"
                            sx={{
                                ...inputStyle, 
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl> 
                    <FormControl required sx={{...formStyle}}>
                        <FormLabel sx={labelStyle}>Issued By:</FormLabel>
                        <Autocomplete
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle,
                                backgroundColor:'#c6ffff',
                            }}
                            
                        />
                    </FormControl>
                    <Box sx={{...formStyle,display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Checkbox 
                            label='BASE:'
                            variant="outlined"
                            color="neutral"
                            sx={{
                                fontSize:'12px',
                                '--Checkbox-size':'15px',
                                gap:0.4,
                                '& span': {
                                    borderRadius: '0px',
                                    borderWidth:'1px',
                                    borderColor:'black',
                                    color:'black'
                                }, 
                            }}
                            //checked={controls?.q}
                            //onChange={(event) => setControls({...controls, q:event.target.checked})}
                        />
                    </Box>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>Plan Date:</FormLabel>
                        <Input  
                            type='date'
                            sx={{
                                ...inputStyle, 
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl 
                        required
                        sx={{
                            ...formStyle,
                            gridColumnStart:2,
                            gridColumnEnd:4,
                        }}
                    >
                        <FormLabel sx={labelStyle}>Finish Date:</FormLabel>
                        <Input  
                            type='date'
                            sx={{
                                ...inputStyle, 
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>MRO Code:</FormLabel>
                        <Autocomplete
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle,
                                backgroundColor:'#c6ffff',
                            }}
                            
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle}}>
                        <FormLabel sx={labelStyle}>STA:</FormLabel>
                        <Autocomplete
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle,
                                backgroundColor:'#c6ffff',
                            }}
                            
                        />
                    </FormControl>
                    <Box sx={{...formStyle,}}></Box>
                    <FormControl 
                        sx={{
                            ...formStyle,
                            gridColumnStart:1,
                            gridColumnEnd:6,
                        }}
                    >
                        <FormLabel sx={labelStyle}>WP Description:</FormLabel>
                        <Textarea 
                            maxRows={1}
                            sx={{
                                ...inputStyle,
                                color:'#000078',
                                height:'100%',
                                lineHeight:1,
                                py:0,
                                px:0.3
                            }}
                            //value={editedData?.remarks ?? ''}
                            //onChange={(event) => handleChange('remarks', event.target.value)}
                        />
                    </FormControl>
                    <Box sx={{...formStyle,}}>
                        <Button
                            variant="outlined"
                            color="neutral"
                            sx={{
                                borderRadius: '0px',
                                fontSize: '10px',
                                backgroundColor:'#c6c3ff',
                                border:'2px outset white',
                                height:'100%',
                                width:'100%',
                                display:'flex',
                                flexDirection:'column',
                                mr:1,
                                lineHeight:1,
                                whiteSpace:'nowrap'
                            }}
                        >
                            <img 
                                width='16px' 
                                height='16px' 
                                src="/images/OUTLOOK7.ico" 
                                alt="" 
                            />
                            Save
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width:'100%',
                        height:'100%',
                        display:'grid',
                        gridTemplateRows:'20px auto 20px',
                    }}
                >
                    <Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
                        WO DETAILS:
                        <Box
                            sx={{
                                display:'flex',
                                alignItems:'center',
                                ml:'auto',
                                gap:0.2
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    backgroundColor:'#ffc3c6',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0,
                                    ml:1
                                }}
                            >
                                Defer
                            </Button>
                            <Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    bgcolor:'#d6d3ce',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0
                                }}
                            >
                                SUPP. WO
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            bgcolor:'white',
                            height:'100%',
                            width:'100%',
                            border:'1px solid black'
                        }}
                    >

                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
                        Found:
                    </Box>
                </Box>
            </Box>
        )
    }

	const Created = () => {
        return(
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows:'120px 120px auto',
                    height:'100%',
                    pt:0.1,
                    px:0.5,
                    width:'100%',
                    position:'relative',
                    gap:0.5,
                }}
            >
				<Box
					sx={{
						width:'100%',
						height:'100%',
						display:'grid',
						gridTemplateRows:'auto 20px',
					}}
				>
					<Box
						sx={{
							width:'100%',
							height:'100%',
							border:'1px solid black',
							bgcolor:'#848284',
						}}
					>

					</Box>
					<Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
                        Found:
                    </Box>
				</Box>
                <Box
                    sx={{
                        border:'1px solid black',
                        height:'100%',
                        display:'grid',
                        position:'relative',
                        gridTemplateRows:'33% 33% 34%',
                        gridTemplateColumns:'auto 16% 10% 19% 19% 70px',
                        pt:1,
                        maxWidth:'100%',
                    }}
                >
                    <Box
                        sx={{
                            position:'absolute',
                            top:-8,
                            left:5,
                            fontSize:'10px',
                            bgcolor:'#d6d3ce',
                            color:'black'
                        }}
                    >
                        Work Package Editor (New):
                    </Box>
                   
                    <FormControl 
                        required
                        sx={{
                            ...formStyle,
                            gridColumnStart:1,
                            gridColumnEnd:3,
                        }}
                    >
                        <FormLabel sx={labelStyle}>WP Number:</FormLabel>
                        <Input  
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl> 
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>Rev.:</FormLabel>
                        <Input  
                            sx={{
                                ...inputStyle, 
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>WP Date:</FormLabel>
                        <Input  
                            type="date"
                            sx={{
                                ...inputStyle, 
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl> 
                    <FormControl required sx={{...formStyle}}>
                        <FormLabel sx={labelStyle}>Issued By:</FormLabel>
                        <Autocomplete
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle,
                                backgroundColor:'#c6ffff',
                            }}
                            
                        />
                    </FormControl>
                    <Box sx={{...formStyle,display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Checkbox 
                            label='BASE:'
                            variant="outlined"
                            color="neutral"
                            sx={{
                                fontSize:'12px',
                                '--Checkbox-size':'15px',
                                gap:0.4,
                                '& span': {
                                    borderRadius: '0px',
                                    borderWidth:'1px',
                                    borderColor:'black',
                                    color:'black'
                                }, 
                            }}
                            //checked={controls?.q}
                            //onChange={(event) => setControls({...controls, q:event.target.checked})}
                        />
                    </Box>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>Plan Date:</FormLabel>
                        <Input  
                            type='date'
                            sx={{
                                ...inputStyle, 
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl 
                        required
                        sx={{
                            ...formStyle,
                            gridColumnStart:2,
                            gridColumnEnd:4,
                        }}
                    >
                        <FormLabel sx={labelStyle}>Finish Date:</FormLabel>
                        <Input  
                            type='date'
                            sx={{
                                ...inputStyle, 
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>MRO Code:</FormLabel>
                        <Autocomplete
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle,
                                backgroundColor:'#c6ffff',
                            }}
                            
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle}}>
                        <FormLabel sx={labelStyle}>STA:</FormLabel>
                        <Autocomplete
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle,
                                backgroundColor:'#c6ffff',
                            }}
                            
                        />
                    </FormControl>
                    <Box sx={{...formStyle,}}>
                        <Button
                            variant="outlined"
                            color="neutral"
                            sx={{
                                borderRadius: '0px',
                                fontSize: '10px',
                                backgroundColor:'#c6c3ff',
                                border:'2px outset white',
                                height:'90%',
								minHeight:0,
                                width:'90%',
                                display:'flex',
                                flexDirection:'column',
                                mr:1,
                                lineHeight:1,
                                whiteSpace:'nowrap',
								p:0
                            }}
                        >
                            <img 
                                width='16px' 
                                height='16px' 
                                src="/images/OUTLOOK7.ico" 
                                alt="" 
                            />
                            Save
                        </Button>
                    </Box>
                    <FormControl 
                        sx={{
                            ...formStyle,
                            gridColumnStart:1,
                            gridColumnEnd:6,
                        }}
                    >
                        <FormLabel sx={labelStyle}>WP Description:</FormLabel>
                        <Textarea 
                            maxRows={1}
                            sx={{
                                ...inputStyle,
                                color:'#000078',
                                height:'100%',
                                lineHeight:1,
                                py:0,
                                px:0.3
                            }}
                            //value={editedData?.remarks ?? ''}
                            //onChange={(event) => handleChange('remarks', event.target.value)}
                        />
                    </FormControl>
                    <Box sx={{...formStyle,}}>
                        <Button
                            variant="outlined"
                            color="neutral"
                            sx={{
                                borderRadius: '0px',
                                fontSize: '10px',
                                backgroundColor:'#c6c3ff',
                                border:'2px outset white',
                                height:'100%',
								minHeight:0,
                                width:'90%',
                                display:'flex',
                                flexDirection:'column',
                                mr:1,
                                lineHeight:1,
                                whiteSpace:'nowrap'
                            }}
                        >
                            <img 
                                width='16px' 
                                height='16px' 
                                src="/images/OUTLOOK7.ico" 
                                alt="" 
                            />
                            Planned
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width:'100%',
                        height:'100%',
                        display:'grid',
                        gridTemplateRows:'20px 20px auto 20px',
                    }}
                >
					<Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
						<Input  
                            sx={{
                                ...inputStyle, 
								height: 18,
								width:'60px'
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                        <Box
                            sx={{
                                display:'flex',
                                alignItems:'center',
                                ml:'auto',
                                gap:0.2
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    bgcolor:'#c6ffc6',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0
                                }}
                            >
                                Execute
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
						<Box>
                       		WO DETAILS:
							<Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    bgcolor:'#d6d3ce',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0,
									ml:1
                                }}
                            >
                                Print
                            </Button>
						</Box>
                        <Box
                            sx={{
                                display:'flex',
                                alignItems:'center',
                                ml:'auto',
                                gap:0.2
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    backgroundColor:'#ffc3c6',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0,
                                    ml:1
                                }}
                            >
                                Defer
                            </Button>
                            <Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    bgcolor:'#d6d3ce',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0
                                }}
                            >
                                SUPP. WO
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            bgcolor:'white',
                            height:'100%',
                            width:'100%',
                            border:'1px solid black'
                        }}
                    >

                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
                        Found:
                    </Box>
                </Box>
            </Box>
        )
    }

	const Planned = () => {
        return(
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows:'120px 120px auto',
                    height:'100%',
                    pt:0.1,
                    px:0.5,
                    width:'100%',
                    position:'relative',
                    gap:0.5,
                }}
            >
				<Box
					sx={{
						width:'100%',
						height:'100%',
						display:'grid',
						gridTemplateRows:'auto 20px',
					}}
				>
					<Box
						sx={{
							width:'100%',
							height:'100%',
							border:'1px solid black',
							bgcolor:'#848284',
						}}
					>

					</Box>
					<Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
                        Found:
                    </Box>
				</Box>
                <Box
                    sx={{
                        border:'1px solid black',
                        height:'100%',
                        display:'grid',
                        position:'relative',
                        gridTemplateRows:'33% 33% 34%',
                        gridTemplateColumns:'auto 16% 10% 19% 19% 70px',
                        pt:1,
                        maxWidth:'100%',
                    }}
                >
                    <Box
                        sx={{
                            position:'absolute',
                            top:-8,
                            left:5,
                            fontSize:'10px',
                            bgcolor:'#d6d3ce',
                            color:'black'
                        }}
                    >
                        Work Package Editor (New):
                    </Box>
                   
                    <FormControl 
                        required
                        sx={{
                            ...formStyle,
                            gridColumnStart:1,
                            gridColumnEnd:3,
                        }}
                    >
                        <FormLabel sx={labelStyle}>WP Number:</FormLabel>
                        <Input  
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl> 
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>Rev.:</FormLabel>
                        <Input  
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>WP Date:</FormLabel>
                        <Input  
                            type="date"
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}  
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl> 
                    <FormControl required sx={{...formStyle}}>
                        <FormLabel sx={labelStyle}>Issued By:</FormLabel>
                        <Autocomplete
							disabled
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle,
								'--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}
                            
                        />
                    </FormControl>
                    <Box sx={{...formStyle,display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Checkbox 
                            label='BASE:'
                            variant="outlined"
                            color="neutral"
                            sx={{
                                fontSize:'12px',
                                '--Checkbox-size':'15px',
                                gap:0.4,
                                '& span': {
                                    borderRadius: '0px',
                                    borderWidth:'1px',
                                    borderColor:'black',
                                    color:'black'
                                }, 
                            }}
                            //checked={controls?.q}
                            //onChange={(event) => setControls({...controls, q:event.target.checked})}
                        />
                    </Box>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>Plan Date:</FormLabel>
                        <Input  
                            type='date'
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl 
                        required
                        sx={{
                            ...formStyle,
                            gridColumnStart:2,
                            gridColumnEnd:4,
                        }}
                    >
                        <FormLabel sx={labelStyle}>Finish Date:</FormLabel>
                        <Input  
                            type='date'
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}  
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>MRO Code:</FormLabel>
                        <Autocomplete
							disabled
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle}}>
                        <FormLabel sx={labelStyle}>STA:</FormLabel>
                        <Autocomplete
                            disabled
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                        />
                    </FormControl>
                    <Box sx={{...formStyle,}}/>
                    <FormControl 
                        sx={{
                            ...formStyle,
                            gridColumnStart:1,
                            gridColumnEnd:6,
                        }}
                    >
                        <FormLabel sx={labelStyle}>WP Description:</FormLabel>
                        <Textarea 
							disabled
                            maxRows={1}
                            sx={{
                                ...inputStyle,
                                color:'#000078',
                                height:'100%',
                                lineHeight:1,
                                py:0,
                                px:0.3,
								'--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}
                            //value={editedData?.remarks ?? ''}
                            //onChange={(event) => handleChange('remarks', event.target.value)}
                        />
                    </FormControl>
                    <Box sx={{...formStyle,}}>
                        <Button
                            variant="outlined"
                            color="neutral"
                            sx={{
                                borderRadius: '0px',
                                fontSize: '10px',
                                backgroundColor:'#c6c3ff',
                                border:'2px outset white',
                                height:'100%',
								minHeight:0,
                                width:'90%',
                                display:'flex',
                                flexDirection:'column',
                                mr:1,
                                lineHeight:1,
                                whiteSpace:'nowrap'
                            }}
                        >
                            <img 
                                width='16px' 
                                height='16px' 
                                src="/images/OUTLOOK7.ico" 
                                alt="" 
                            />
                            Opened
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width:'100%',
                        height:'100%',
                        display:'grid',
                        gridTemplateRows:'20px 20px auto 20px',
                    }}
                >
					<Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
						<Input  
                            sx={{
                                ...inputStyle, 
								height: 18,
								width:'60px'
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                        <Box
                            sx={{
                                display:'flex',
                                alignItems:'center',
                                ml:'auto',
                                gap:0.2
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    bgcolor:'#c6ffc6',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0
                                }}
                            >
                                Execute
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
						<Box>
                       		WO DETAILS:
							<Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    bgcolor:'#d6d3ce',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0,
									ml:1
                                }}
                            >
                                Print
                            </Button>
						</Box>
                        <Box
                            sx={{
                                display:'flex',
                                alignItems:'center',
                                ml:'auto',
                                gap:0.2
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    backgroundColor:'#ffc3c6',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0,
                                    ml:1
                                }}
                            >
                                Defer
                            </Button>
                            <Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    bgcolor:'#d6d3ce',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0
                                }}
                            >
                                SUPP. WO
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            bgcolor:'white',
                            height:'100%',
                            width:'100%',
                            border:'1px solid black'
                        }}
                    >

                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
                        Found:
                    </Box>
                </Box>
            </Box>
        )
    }

	const Opened = () => {
        return(
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows:'120px 120px auto',
                    height:'100%',
                    pt:0.1,
                    px:0.5,
                    width:'100%',
                    position:'relative',
                    gap:0.5,
                }}
            >
				<Box
					sx={{
						width:'100%',
						height:'100%',
						display:'grid',
						gridTemplateRows:'auto 20px',
					}}
				>
					<Box
						sx={{
							width:'100%',
							height:'100%',
							border:'1px solid black',
							bgcolor:'#848284',
						}}
					>

					</Box>
					<Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
                        Found:
                    </Box>
				</Box>
                <Box
                    sx={{
                        border:'1px solid black',
                        height:'100%',
                        display:'grid',
                        position:'relative',
                        gridTemplateRows:'33% 33% 34%',
                        gridTemplateColumns:'auto 16% 10% 19% 19% 70px',
                        pt:1,
                        maxWidth:'100%',
                    }}
                >
                    <Box
                        sx={{
                            position:'absolute',
                            top:-8,
                            left:5,
                            fontSize:'10px',
                            bgcolor:'#d6d3ce',
                            color:'black'
                        }}
                    >
                        Work Package Editor (New):
                    </Box>
                   
                    <FormControl 
                        required
                        sx={{
                            ...formStyle,
                            gridColumnStart:1,
                            gridColumnEnd:3,
                        }}
                    >
                        <FormLabel sx={labelStyle}>WP Number:</FormLabel>
                        <Input  
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl> 
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>Rev.:</FormLabel>
                        <Input  
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>WP Date:</FormLabel>
                        <Input  
                            type="date"
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}  
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl> 
                    <FormControl required sx={{...formStyle}}>
                        <FormLabel sx={labelStyle}>Issued By:</FormLabel>
                        <Autocomplete
							disabled
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle,
								'--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}
                            
                        />
                    </FormControl>
                    <Box sx={{...formStyle,display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Checkbox 
                            label='BASE:'
                            variant="outlined"
                            color="neutral"
                            sx={{
                                fontSize:'12px',
                                '--Checkbox-size':'15px',
                                gap:0.4,
                                '& span': {
                                    borderRadius: '0px',
                                    borderWidth:'1px',
                                    borderColor:'black',
                                    color:'black'
                                }, 
                            }}
                            //checked={controls?.q}
                            //onChange={(event) => setControls({...controls, q:event.target.checked})}
                        />
                    </Box>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>Plan Date:</FormLabel>
                        <Input  
                            type='date'
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl 
                        required
                        sx={{
                            ...formStyle,
                            gridColumnStart:2,
                            gridColumnEnd:4,
                        }}
                    >
                        <FormLabel sx={labelStyle}>Finish Date:</FormLabel>
                        <Input  
                            type='date'
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}  
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>MRO Code:</FormLabel>
                        <Autocomplete
							disabled
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle}}>
                        <FormLabel sx={labelStyle}>STA:</FormLabel>
                        <Autocomplete
                            disabled
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                        />
                    </FormControl>
                    <Box sx={{...formStyle,}}/>
                    <FormControl 
                        sx={{
                            ...formStyle,
                            gridColumnStart:1,
                            gridColumnEnd:6,
                        }}
                    >
                        <FormLabel sx={labelStyle}>WP Description:</FormLabel>
                        <Textarea 
							disabled
                            maxRows={1}
                            sx={{
                                ...inputStyle,
                                color:'#000078',
                                height:'100%',
                                lineHeight:1,
                                py:0,
                                px:0.3,
								'--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}
                            //value={editedData?.remarks ?? ''}
                            //onChange={(event) => handleChange('remarks', event.target.value)}
                        />
                    </FormControl>
                    <Box sx={{...formStyle,}}>
                        <Button
                            variant="outlined"
                            color="neutral"
                            sx={{
                                borderRadius: '0px',
                                fontSize: '10px',
                                backgroundColor:'#c6c3ff',
                                border:'2px outset white',
                                height:'100%',
								minHeight:0,
                                width:'90%',
                                display:'flex',
                                flexDirection:'column',
                                mr:1,
                                lineHeight:1,
                                whiteSpace:'nowrap'
                            }}
                        >
                            <img 
                                width='16px' 
                                height='16px' 
                                src="/images/OUTLOOK7.ico" 
                                alt="" 
                            />
                            Execute
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width:'100%',
                        height:'100%',
                        display:'grid',
                        gridTemplateRows:'20px 20px auto 20px',
                    }}
                >
					<Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
						<Input  
                            sx={{
                                ...inputStyle, 
								height: 18,
								width:'60px'
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                        <Box
                            sx={{
                                display:'flex',
                                alignItems:'center',
                                ml:'auto',
                                gap:0.2
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    bgcolor:'#c6ffc6',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0
                                }}
                            >
                                Execute
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
						<Box>
                       		WO DETAILS:
							<Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    bgcolor:'#d6d3ce',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0,
									ml:1
                                }}
                            >
                                Print
                            </Button>
						</Box>
                        <Box
                            sx={{
                                display:'flex',
                                alignItems:'center',
                                ml:'auto',
                                gap:0.2
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    backgroundColor:'#ffc3c6',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0,
                                    ml:1
                                }}
                            >
                                Defer
                            </Button>
                            <Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    bgcolor:'#d6d3ce',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0
                                }}
                            >
                                SUPP. WO
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            bgcolor:'white',
                            height:'100%',
                            width:'100%',
                            border:'1px solid black'
                        }}
                    >

                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
                        Found:
                    </Box>
                </Box>
            </Box>
        )
    }

	const Execution = () => {
        return(
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows:'120px 120px auto',
                    height:'100%',
                    pt:0.1,
                    px:0.5,
                    width:'100%',
                    position:'relative',
                    gap:0.5,
                }}
            >
				<Box
					sx={{
						width:'100%',
						height:'100%',
						display:'grid',
						gridTemplateRows:'auto 20px',
					}}
				>
					<Box
						sx={{
							width:'100%',
							height:'100%',
							border:'1px solid black',
							bgcolor:'#848284',
						}}
					>

					</Box>
					<Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
                        Found:
                    </Box>
				</Box>
                <Box
                    sx={{
                        border:'1px solid black',
                        height:'100%',
                        display:'grid',
                        position:'relative',
                        gridTemplateRows:'33% 33% 34%',
                        gridTemplateColumns:'auto 16% 10% 19% 19% 70px',
                        pt:1,
                        maxWidth:'100%',
                    }}
                >
                    <Box
                        sx={{
                            position:'absolute',
                            top:-8,
                            left:5,
                            fontSize:'10px',
                            bgcolor:'#d6d3ce',
                            color:'black'
                        }}
                    >
                        Work Package Editor (New):
                    </Box>
                   
                    <FormControl 
                        required
                        sx={{
                            ...formStyle,
                            gridColumnStart:1,
                            gridColumnEnd:3,
                        }}
                    >
                        <FormLabel sx={labelStyle}>WP Number:</FormLabel>
                        <Input  
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl> 
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>Rev.:</FormLabel>
                        <Input  
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>WP Date:</FormLabel>
                        <Input  
                            type="date"
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}  
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl> 
                    <FormControl required sx={{...formStyle}}>
                        <FormLabel sx={labelStyle}>Issued By:</FormLabel>
                        <Autocomplete
							disabled
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle,
								'--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}
                            
                        />
                    </FormControl>
                    <Box sx={{...formStyle,display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Checkbox 
                            label='BASE:'
                            variant="outlined"
                            color="neutral"
                            sx={{
                                fontSize:'12px',
                                '--Checkbox-size':'15px',
                                gap:0.4,
                                '& span': {
                                    borderRadius: '0px',
                                    borderWidth:'1px',
                                    borderColor:'black',
                                    color:'black'
                                }, 
                            }}
                            //checked={controls?.q}
                            //onChange={(event) => setControls({...controls, q:event.target.checked})}
                        />
                    </Box>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>Plan Date:</FormLabel>
                        <Input  
                            type='date'
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl 
                        required
                        sx={{
                            ...formStyle,
                            gridColumnStart:2,
                            gridColumnEnd:4,
                        }}
                    >
                        <FormLabel sx={labelStyle}>Finish Date:</FormLabel>
                        <Input  
                            type='date'
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}  
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>MRO Code:</FormLabel>
                        <Autocomplete
							disabled
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle}}>
                        <FormLabel sx={labelStyle}>STA:</FormLabel>
                        <Autocomplete
                            disabled
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                        />
                    </FormControl>
                    <Box sx={{...formStyle,}}/>
                    <FormControl 
                        sx={{
                            ...formStyle,
                            gridColumnStart:1,
                            gridColumnEnd:6,
                        }}
                    >
                        <FormLabel sx={labelStyle}>WP Description:</FormLabel>
                        <Textarea 
							disabled
                            maxRows={1}
                            sx={{
                                ...inputStyle,
                                color:'#000078',
                                height:'100%',
                                lineHeight:1,
                                py:0,
                                px:0.3,
								'--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}
                            //value={editedData?.remarks ?? ''}
                            //onChange={(event) => handleChange('remarks', event.target.value)}
                        />
                    </FormControl>
                    <Box sx={{...formStyle,}}>
                        <Button
                            variant="outlined"
                            color="neutral"
                            sx={{
                                borderRadius: '0px',
                                fontSize: '10px',
                                backgroundColor:'#c6c3ff',
                                border:'2px outset white',
                                height:'100%',
								minHeight:0,
                                width:'90%',
                                display:'flex',
                                flexDirection:'column',
                                mr:1,
                                lineHeight:1,
                                whiteSpace:'nowrap'
                            }}
                        >
                            <img 
                                width='16px' 
                                height='16px' 
                                src="/images/OUTLOOK7.ico" 
                                alt="" 
                            />
                            Back Open
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width:'100%',
                        height:'100%',
                        display:'grid',
                        gridTemplateRows:'20px 20px auto 20px',
                    }}
                >
					<Input  
						sx={{
							...inputStyle, 
							height: 18,
							width:'60px'
						}} 
						//value={selectedAc?.acfamily ?? ''}
					/>
                    <Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
						<Box>
                       		WO DETAILS:
							<Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    bgcolor:'#d6d3ce',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0,
									ml:1
                                }}
                            >
                                Print
                            </Button>
						</Box>
                        <Box
                            sx={{
                                display:'flex',
                                alignItems:'center',
                                ml:'auto',
                                gap:0.2
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    backgroundColor:'#ffc3c6',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0,
                                    ml:1
                                }}
                            >
                                Defer
                            </Button>
                            <Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    bgcolor:'#d6d3ce',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0
                                }}
                            >
                                SUPP. WO
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            bgcolor:'white',
                            height:'100%',
                            width:'100%',
                            border:'1px solid black'
                        }}
                    >

                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
                        Found:
                    </Box>
                </Box>
            </Box>
        )
    }

	const Closed = () => {
        return(
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows:'120px 120px auto',
                    height:'100%',
                    pt:0.1,
                    px:0.5,
                    width:'100%',
                    position:'relative',
                    gap:0.5,
                }}
            >
				<Box
					sx={{
						width:'100%',
						height:'100%',
						display:'grid',
						gridTemplateRows:'auto 20px',
					}}
				>
					<Box
						sx={{
							width:'100%',
							height:'100%',
							border:'1px solid black',
							bgcolor:'#848284',
						}}
					>

					</Box>
					<Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
                        Found:
                    </Box>
				</Box>
                <Box
                    sx={{
                        border:'1px solid black',
                        height:'100%',
                        display:'grid',
                        position:'relative',
                        gridTemplateRows:'33% 33% 34%',
                        gridTemplateColumns:'auto 16% 10% 19% 19% 70px',
                        pt:1,
                        maxWidth:'100%',
                    }}
                >
                    <Box
                        sx={{
                            position:'absolute',
                            top:-8,
                            left:5,
                            fontSize:'10px',
                            bgcolor:'#d6d3ce',
                            color:'black'
                        }}
                    >
                        Work Package Editor (New):
                    </Box>
                   
                    <FormControl 
                        required
                        sx={{
                            ...formStyle,
                            gridColumnStart:1,
                            gridColumnEnd:3,
                        }}
                    >
                        <FormLabel sx={labelStyle}>WP Number:</FormLabel>
                        <Input  
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl> 
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>Rev.:</FormLabel>
                        <Input  
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>WP Date:</FormLabel>
                        <Input  
                            type="date"
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}  
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl> 
                    <FormControl required sx={{...formStyle}}>
                        <FormLabel sx={labelStyle}>Issued By:</FormLabel>
                        <Autocomplete
							disabled
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle,
								'--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}
                            
                        />
                    </FormControl>
                    <Box sx={{...formStyle,display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Checkbox 
                            label='BASE:'
                            variant="outlined"
                            color="neutral"
                            sx={{
                                fontSize:'12px',
                                '--Checkbox-size':'15px',
                                gap:0.4,
                                '& span': {
                                    borderRadius: '0px',
                                    borderWidth:'1px',
                                    borderColor:'black',
                                    color:'black'
                                }, 
                            }}
                            //checked={controls?.q}
                            //onChange={(event) => setControls({...controls, q:event.target.checked})}
                        />
                    </Box>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>Plan Date:</FormLabel>
                        <Input  
                            type='date'
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl 
                        required
                        sx={{
                            ...formStyle,
                            gridColumnStart:2,
                            gridColumnEnd:4,
                        }}
                    >
                        <FormLabel sx={labelStyle}>Finish Date:</FormLabel>
                        <Input  
                            type='date'
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}  
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>MRO Code:</FormLabel>
                        <Autocomplete
							disabled
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle}}>
                        <FormLabel sx={labelStyle}>STA:</FormLabel>
                        <Autocomplete
                            disabled
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                        />
                    </FormControl>
                    <Box sx={{...formStyle,}}/>
                    <FormControl 
                        sx={{
                            ...formStyle,
                            gridColumnStart:1,
                            gridColumnEnd:6,
                        }}
                    >
                        <FormLabel sx={labelStyle}>WP Description:</FormLabel>
                        <Textarea 
							disabled
                            maxRows={1}
                            sx={{
                                ...inputStyle,
                                color:'#000078',
                                height:'100%',
                                lineHeight:1,
                                py:0,
                                px:0.3,
								'--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}
                            //value={editedData?.remarks ?? ''}
                            //onChange={(event) => handleChange('remarks', event.target.value)}
                        />
                    </FormControl>
                    <Box sx={{...formStyle,}}/>
                </Box>
                <Box
                    sx={{
                        width:'100%',
                        height:'100%',
                        display:'grid',
                        gridTemplateRows:'20px 20px auto 20px',
                    }}
                >
					<Input  
						sx={{
							...inputStyle, 
							height: 18,
							width:'60px'
						}} 
						//value={selectedAc?.acfamily ?? ''}
					/>
                    <Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
						<Box>
                       		WO DETAILS:
							<Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    bgcolor:'#d6d3ce',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0,
									ml:1
                                }}
                            >
                                Print
                            </Button>
						</Box>
                    </Box>
                    <Box
                        sx={{
                            bgcolor:'white',
                            height:'100%',
                            width:'100%',
                            border:'1px solid black'
                        }}
                    >

                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
                        Found:
                    </Box>
                </Box>
            </Box>
        )
    }

	const Canceled = () => {
        return(
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows:'120px 120px auto',
                    height:'100%',
                    pt:0.1,
                    px:0.5,
                    width:'100%',
                    position:'relative',
                    gap:0.5,
                }}
            >
				<Box
					sx={{
						width:'100%',
						height:'100%',
						display:'grid',
						gridTemplateRows:'auto 20px',
					}}
				>
					<Box
						sx={{
							width:'100%',
							height:'100%',
							border:'1px solid black',
							bgcolor:'#848284',
						}}
					>

					</Box>
					<Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
                        Found:
                    </Box>
				</Box>
                <Box
                    sx={{
                        border:'1px solid black',
                        height:'100%',
                        display:'grid',
                        position:'relative',
                        gridTemplateRows:'33% 33% 34%',
                        gridTemplateColumns:'auto 16% 10% 19% 19% 70px',
                        pt:1,
                        maxWidth:'100%',
                    }}
                >
                    <Box
                        sx={{
                            position:'absolute',
                            top:-8,
                            left:5,
                            fontSize:'10px',
                            bgcolor:'#d6d3ce',
                            color:'black'
                        }}
                    >
                        Work Package Editor (New):
                    </Box>
                   
                    <FormControl 
                        required
                        sx={{
                            ...formStyle,
                            gridColumnStart:1,
                            gridColumnEnd:3,
                        }}
                    >
                        <FormLabel sx={labelStyle}>WP Number:</FormLabel>
                        <Input  
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl> 
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>Rev.:</FormLabel>
                        <Input  
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>WP Date:</FormLabel>
                        <Input  
                            type="date"
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}  
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl> 
                    <FormControl required sx={{...formStyle}}>
                        <FormLabel sx={labelStyle}>Issued By:</FormLabel>
                        <Autocomplete
							disabled
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle,
								'--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}
                            
                        />
                    </FormControl>
                    <Box sx={{...formStyle,display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Checkbox 
                            label='BASE:'
                            variant="outlined"
                            color="neutral"
                            sx={{
                                fontSize:'12px',
                                '--Checkbox-size':'15px',
                                gap:0.4,
                                '& span': {
                                    borderRadius: '0px',
                                    borderWidth:'1px',
                                    borderColor:'black',
                                    color:'black'
                                }, 
                            }}
                            //checked={controls?.q}
                            //onChange={(event) => setControls({...controls, q:event.target.checked})}
                        />
                    </Box>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>Plan Date:</FormLabel>
                        <Input  
                            type='date'
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl 
                        required
                        sx={{
                            ...formStyle,
                            gridColumnStart:2,
                            gridColumnEnd:4,
                        }}
                    >
                        <FormLabel sx={labelStyle}>Finish Date:</FormLabel>
                        <Input  
                            type='date'
                            disabled
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}  
                            //value={selectedAc?.acfamily ?? ''}
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle,}}>
                        <FormLabel sx={labelStyle}>MRO Code:</FormLabel>
                        <Autocomplete
							disabled
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                            
                        />
                    </FormControl>
                    <FormControl required sx={{...formStyle}}>
                        <FormLabel sx={labelStyle}>STA:</FormLabel>
                        <Autocomplete
                            disabled
                            disableClearable
                            options={[]}
                            //value={selectedAc?.acreg ?? ''}
                            //onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
                            sx={{
                                ...inputStyle, 
                                '--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }} 
                        />
                    </FormControl>
                    <Box sx={{...formStyle,}}/>
                    <FormControl 
                        sx={{
                            ...formStyle,
                            gridColumnStart:1,
                            gridColumnEnd:6,
                        }}
                    >
                        <FormLabel sx={labelStyle}>WP Description:</FormLabel>
                        <Textarea 
							disabled
                            maxRows={1}
                            sx={{
                                ...inputStyle,
                                color:'#000078',
                                height:'100%',
                                lineHeight:1,
                                py:0,
                                px:0.3,
								'--variant-outlinedDisabledColor':'black',
                                '--variant-outlinedDisabledBorder':'black',
                                bgcolor:'#d6d3ce',
                            }}
                            //value={editedData?.remarks ?? ''}
                            //onChange={(event) => handleChange('remarks', event.target.value)}
                        />
                    </FormControl>
                    <Box sx={{...formStyle,}}/>
                </Box>
                <Box
                    sx={{
                        width:'100%',
                        height:'100%',
                        display:'grid',
                        gridTemplateRows:'20px auto 20px',
                    }}
                >
                    <Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
						<Box>
                       		WO DETAILS:
							<Button
                                variant="outlined"
                                color="neutral"
                                sx={{
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    bgcolor:'#d6d3ce',
                                    border:'2px outset white',
                                    height:18,
                                    width:60,
                                    lineHeight:1,
                                    minHeight:0,
                                    p:0,
									ml:1
                                }}
                            >
                                Print
                            </Button>
						</Box>
                    </Box>
                    <Box
                        sx={{
                            bgcolor:'white',
                            height:'100%',
                            width:'100%',
                            border:'1px solid black'
                        }}
                    >

                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            fontSize:'10px',
                            alignItems:'center'
                        }}
                    >
                        Found:
                    </Box>
                </Box>
            </Box>
        )
    }

    return(
        <Box
            sx={{
                height:'100%',
                width:'100%',
                border:'1px solid black',
                position:'relative',
                pt:1,
                
            }}
        >
            <Box
                sx={{
                    position:'absolute',
                    top:-10,
                    left:5,
                    fontSize:'12px',
                    bgcolor:'#d6d3ce',
                    color:'#000084'
                }}
            >
                Work Package Editor:
            </Box>
            <Tabs
                defaultValue={'New'}
                sx={{
                    flexDirection: 'column',
                    bgcolor:'#d6d3ce',
                    whiteSpace: 'nowrap',
                    position:'relative',
                    height:'100%',
                    overflow:'auto',
                    width:'100%',
                }}
            >
                <TabList >
                    <Tab value="New" sx={tabStyle}>
                        New
                    </Tab>
                    <Tab value="Created" sx={tabStyle}>
                        Created
                    </Tab>
                    <Tab value="Planned" sx={tabStyle}>
                        Planned
                    </Tab>
                    <Tab value="Opened" sx={tabStyle}>
                        Opened
                    </Tab>
                    <Tab value="Execution" sx={tabStyle}>
                        Execution
                    </Tab>
                    <Tab value="Closed" sx={tabStyle}>
                        Closed
                    </Tab>
                    <Tab value="Cenceled" sx={tabStyle}>
                        Cenceled
                    </Tab>
                </TabList>
                <TabPanel value="New" sx={{p:'0px'}}>
                   <New/>
                </TabPanel>
                <TabPanel value="Created" sx={{p:'0px'}}>
                   <Created/>
                </TabPanel>
                <TabPanel value="Planned" sx={{p:'0px'}}>
                    <Planned/>
                </TabPanel>
                <TabPanel value="Opened" sx={{p:'0px'}}>
					<Opened/>
                </TabPanel>
                <TabPanel value="Execution" sx={{p:'0px'}}>
					<Execution/>
                </TabPanel>
                <TabPanel value='Closed' sx={{p:'0px'}}>
                    <Closed/>
                </TabPanel>
                <TabPanel value='Cenceled' sx={{p:'0px'}}>
                    <Canceled/>
                </TabPanel>
            </Tabs>
        </Box>
    )
}