import * as React from 'react';
import { useNavigate } from 'react-router-dom';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Radio from '@mui/joy/Radio';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import RadioGroup from '@mui/joy/RadioGroup';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import CssBaseline from '@mui/joy/CssBaseline';
import Autocomplete from '@mui/joy/Autocomplete';
import { CssVarsProvider } from '@mui/joy/styles';

import { ToastContainer, toast, Zoom, ToastOptions} from "react-toastify";

//Компоненты 
import WP from './WP.tsx';
import Forecast from './Forecast.tsx';
import ForecastComp from './ForecastComp.tsx';
import ForecastSpare from './ForecastSpare.tsx';
import Header from '../../Components/Header.tsx';
import Spiner from '../../Components/Spiner.tsx'

import URL from '../../../../URL.js'

//Иконки
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function Planning() {
	document.body.style.backgroundColor = '#d6d3ce';
	document.title = 'Planning'
	const navigate = useNavigate();
	
	//Стили
	const inputStyle = { borderRadius: 0, px:0.5, mx: 0.5, height: 22, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'12px',"--Input-decoratorChildHeight": "20px", lineHeight:1,  '& input': {textAlign: 'center',}};
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'12px', alignSelf:'center', lineHeight:1 };
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'12px', lineHeight:1};
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'12px',color:'black', lineHeight:1};
	const filtersStyle = {position:'absolute',width:'100%',top:0,minWidth:0,minHeight:0,height:'100%',px: 0.5,m: 0,borderRadius: '0px',"--Input-decoratorChildHeight": "24px",};

	const [isApuSelection, setIsApuSelection] = React.useState(false);
	const [showCalc, setShowCalc] = React.useState(false);
	const [showTabel, setShowTable] = React.useState({task:true, components:true})
	const [showAircraftsList, setShowAircraftsList] = React.useState(false)
	const [showForecastWnd, setShowForecastWnd] = React.useState(false)
	const [showForecastCompWnd, setShowForecastCompWnd] = React.useState(false)
	const [showForecastSpareWnd, setShowForecastSpareWnd] = React.useState(false)
	const [showWp, setShowWp] = React.useState(false)
	const [showSpinner, setShowSpinner] = React.useState(0)

	const [filters, setFilters] = React.useState ({status:'All', type:'Oop', instructionsFlagFilters:[], check:'',idNumber:'', wpWo:''})
	const [acData, setAcData] = React.useState <{Id:number, AcReg:string, AcSn:string, AcType:string, AcFamily:string, AcMfrDate:string, StaAircraftLocation:string, OperatorCodeIcao:string, OperatorName:string}[]>([])
	const baseAcId = sessionStorage.getItem('selectedAc')
	const [selectedAc, setSelectedAc] = React.useState <{Id:number, AcReg:string, AcSn:string, AcType:string, AcFamily:string, AcMfrDate:string, StaAircraftLocation:string, OperatorCodeIcao:string, OperatorName:string}>()
	const [actualTimeData, setActualTimeData] = React.useState <{totalDate:string, totalHours:string, totalCycls:string}>();
	const [apuData, setApuData] = React.useState <{MaxApuTotalHrs:number, MaxApuTotalCycl:number, Pn:string, Sn:string, MfrDate:string, MaxTotalDate:string}>()
	const [calculationData, setCalculationData] = React.useState({periodValue:1, periodType:'Month', calcAvgFh:7, calcAvgFc:5, fixAvgFh:7, fixAvgFc:5, apply:'calc',acAvgFh:8, acAvgFc:6, apuAvgFh:null, apuAvgFc:null})

	const [showFilters, setShowFilters] = React.useState(false);
	const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
	const [data, setData] = React.useState<{id?:number, overdue?:string, calculatedDueDate?:string, plusMinusDays?:number, remainings?:string, wp?:string, type?:string, idNumber?:string, checkId?:any, dase?:string, fhCompl?:number, fhInterval?:number, fhNextDue?:number, fhStart?:number, fhFinish?:number, fcCompl?:number, fcInterval?:number, fcNextDue?:number, fcStart?:number }[]>([]);
	const [filteredData, setFilteredData] = React.useState <{id?:number, ataCode?:string, title?:string, acFamily?:string, etopsSignificant?:boolean}[]>([]);
	const [tableFilters, setTableFilters] = React.useState<{id?:number, overdue?:string, calculatedDueDate?:string, plusMinusDays?:number, remainings?:string, wp?:string, type?:string, idNumber?:string, checkId?:string, base?:string, fhCompl?:number, fhInterval?:number, fhNextDue?:number, fhStart?:number, fhFinish?:number, fcCompl?:number, fcInterval?:number, fcNextDue?:number, fcStart?:number, fcFinish?:number, dateCompl?:string, calendarInterval?:string, calendarValueInterval?:number, dateNextDue?:string, calendarStart?:string, calendarValueStart?:number, calendarFinish?:string, calendarValueFinish?:number, taskTitle?:string, wo?:string, woDate?:string, woIssuedBy?:string, woScheduleDate?:string, idWp?:number, new?:string, status?:string, prolongatedNumber?:number, whicheverLast?:string, remarks?:string}>()

	const headers =[
		{id:'overdue',name:'Overdue'}, 
		{id:'calculatedDueDate',name:'Calc Due Date'},
		{id:'plusMinusDays',name:'+/- d'},
		{id:'remainings',name:'Remainings'},
		{id:'wp',name:'WP'},
		{id:'type',name:'Type'},
		{id:'idNumber',name:'ID-Number'},
		{id:'checkId',name:'Check ID'},
		{id:'base',name:'Base'},
		{id:'fhCompl',name:'FH_Compl'},
		{id:'fhInterval',name:'FH_Interval'},
		{id:'fhNextDue',name:'FH_Next_Due'},
		{id:'fhStart',name:'FH_Start'},
		{id:'fhFininsh',name:'FH_Finish'},
		{id:'fcCompl',name:'FC_Compl'},
		{id:'fcInterval',name:'FC_Interval'},
		{id:'fcNextDue',name:'FC_Next_Due'},
		{id:'fcStart',name:'FC_Start'},
		{id:'fcFininsh',name:'FC_Finish'},
		{id:'dateCompl',name:'Date_Compl'},
		{id:'calendarInterval',name:'Calendar_Interval'},
		{id:'calendarValueInterval',name:'Calendar_Value_Interval'},
		{id:'dateNextDue',name:'Date_Next_Due'},
		{id:'calendarStart',name:'Calendar_Start'},
		{id:'calendarValueStart',name:'Calendar_Value_Start'},
		{id:'calendarFinish',name:'Calendar_Finish'},
		{id:'calendarValueFinish',name:'Calendar_Value_Finish'},
		{id:'taskTitle',name:'Task_Title'},
		{id:'wo',name:'WO'},
		{id:'woDate',name:'WO_Date'},
		{id:'woIssuedBy',name:'WO_Issued_By'},
		{id:'woScheduleDate',name:'WO_Schedule_Date'},
		{id:'idWp',name:'ID_WP'},
		{id:'new',name:'New'},
		{id:'status',name:'Status'},
		{id:'prolongatedNumber',name:'Prolongated_Number'},
		{id:'whicheverLast',name:'Whichever_Last'},
		{id:'remarks',name:'Remarks'},
	]

	const toastOptions : ToastOptions = {
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: false,
		theme: "light",
		transition: Zoom
	};

	const changeShowTable = (property, value) => {
		if(property === 'task' && value === false){
			setShowTable({task:false, components:true})
		}
		else if(property === 'components' && value === false){
			setShowTable({task:true, components:false})
		} else {
			setShowTable({ ...showTabel, [property]: value });
		}
	}

	//Изменение фильтров над таблицей
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Изменение фильтров в таблице
	const handleChangeFilters = (property, value) => {
		setTableFilters({ ...tableFilters, [property]: value });
	};

	//Проверка наличия фильтров
	const checkFilters = () => {
		if (tableFilters === undefined) {
			return true;
		}
	
		// Проверяем, все ли свойства объекта пустые
		return Object.values(tableFilters).every(value => 
			value === '' || value === null || value === undefined || 
			(typeof value === 'number' && value === 0)
		);
	}

	//Запрос данных для header AC
	React.useEffect(() => {
		const fetchAircrafts = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_PARTM}/Planning/ComboboxAircrafts`,
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setAcData(result);
				setSelectedAc(result?.find(data => data.Id === Number(baseAcId)))
			} else {
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			}
		};
		fetchAircrafts();
	}, [])

	//Запрос данных для header AC
	React.useEffect(() => {
		const fetchAircrafts = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_PARTM}/Planning/ActualTime(acReg='${selectedAc?.AcReg}')`,
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				if(response.status !== 204)	{
					const result = await response.json();
					setActualTimeData(result)
				} else {
					setActualTimeData(undefined)
				}
			} else {
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			}
		};
		if(selectedAc) fetchAircrafts();
	}, [selectedAc])

	//Запрос данных для header APU
	React.useEffect(() => {
		const fetchApuData = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_PARTM}/Planning/Apu(acReg='${selectedAc?.AcReg}')`,
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				if(response.status !== 204)	{
					const result = await response.json();
					setApuData(result)
				} else {
					setApuData(undefined)
				}
			} else {
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			}
		};
		if(selectedAc) fetchApuData();
	}, [selectedAc])

	const fetchCalcData = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_PARTM}/Planning/AvgUtilization(acReg='${selectedAc?.AcReg}',period=${calculationData?.periodValue},periodType='${calculationData?.periodType}')`,
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			if(response.status !== 204)
			{
				const result = await response.json();
				setCalculationData({...calculationData, calcAvgFh:result.FlightHours, calcAvgFc:result.FlightCycles})
			}
		} else {
			try{
				const answer = await response.json()
				if(answer.message){
					toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
				} else {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			} catch {
				toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
			}
		}
	};

	React.useEffect(() => {
		const fetchAverageData = async () => {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_PARTM}/Planning/Avg(acReg='${selectedAc?.AcReg}')`,
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				if(response.status !== 204)
				{
					const result = await response.json();
					setCalculationData({...calculationData, acAvgFh:result.acAvgFh, acAvgFc:result.acAvgFc, apuAvgFh:result.apuAvgFh, apuAvgFc:result.apuAvgFc})
				}
			} else {
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			}
		};
		if(selectedAc) fetchAverageData()
	}, [selectedAc])

	//Применение значений из калькулятора
	const handleApply = async () =>  {
		fetch(
			`${URL}${process.env.REACT_APP_API_PARTM}/Planning/ApplyAvg(acReg='${selectedAc?.AcReg}',fh=${calculationData?.apply === 'calc' ? calculationData?.calcAvgFh : calculationData?.fixAvgFh},fc=${calculationData?.apply === 'calc' ? calculationData?.calcAvgFc : calculationData?.fixAvgFc})`, 
			{
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
				}
			}
		)
		.then(async (response) => {
			if (!response.ok){
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			} else {
				//props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
			}
			fetchData();
		})
		.catch(error => console.error('Ошибка:', error));
	}

	//Кнопка Delete в calculator
	const handleDelete = async () =>  {
		fetch(
			`${URL}${process.env.REACT_APP_API_PARTM}/Planning/DeleteAvg(acReg='${selectedAc?.AcReg}')`, 
			{
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
				}
			}
		)
		.then(async (response) => {
			if (!response.ok){
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			} else {
				toast.success("Deleted successfully", toastOptions);
			}
			fetchData();
		})
		.catch(error => console.error('Ошибка:', error));
	}

	//Кнопка Save в calculator
	const handleSave = async () =>  {
		fetch(
			`${URL}${process.env.REACT_APP_API_PARTM}/Planning/SaveAvg(acReg='${selectedAc?.AcReg}',fh=${calculationData?.acAvgFh},fc=${calculationData?.acAvgFc})`, 
			{
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
				}
			}
		)
		.then(async (response) => {
			if (!response.ok){
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			} else {
				toast.success("Saved successfully", toastOptions);
			}
			fetchData();
		})
		.catch(error => console.error('Ошибка создания группы:', error));
	}

	const AircraftSelectingModal = () => {
		const [selectedRow, setSelectedRow] = React.useState<{Id:number}>()
		const handleOk = () => {
			setSelectedAc(acData?.find(data => data.Id === Number(selectedRow?.Id)));
			setShowAircraftsList(false)
		}
		return(
			<Modal
				id='Modal'
				aria-labelledby="modal-title"
				aria-describedby="modal-desc"
				open={showAircraftsList}
				sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backdropFilter:'blur(0px)', '& div':{backdropFilter:'blur(0px)'}}}
			>
				<Sheet
					variant="outlined"
					sx={{ 
						position:'absolute',
						top:'154px', 
						left:'8px',
						borderRadius:0,
						border:'1px solid black',
						width:'300px',
						bgcolor:'#dedff7',
						display:'grid',
						gridTemplateRows:'18px auto 40px',
						px:0.5
					}}
				>
					<Typography
						sx={{
							fontSize: 12,
							color:'#000084',
							fontWeight:'bold'
						}}
					>
						A/C Reg. Filter:
					</Typography>
					<Box
						sx={{
							border:'1px solid black',
							height:'300px',
							borderRadius:'0px',
							bgcolor:'white',
							overflow:'auto',
						}}
					>
						<Table
							aria-labelledby="tableTitle"
							borderAxis="none"
							stickyHeader
							hoverRow
							sx={{
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '1px',
								"--TableCell-height": "10px",
								'--TableCell-paddingX': '5px',
								tableLayout: 'auto', 
								width:'auto',
								borderRadius:'0px',
							}}
						>
							
							{acData?.length === 0 ? (
								<Box>No Any Reference Were Found !</Box>
							):(
								<tbody>
									{acData.map((row) => (
										<tr
											key={row.Id}
											onClick={() => setSelectedRow(row)}
											style={{ 
												backgroundColor: selectedRow === row ? '#c0c0ff' : 'transparent',
												color:'black',
												cursor:'default'
											}}
										>
											<td style={rowStyle}>{row.AcReg}</td>
											<td style={rowStyle}>{row.AcSn}</td>
											<td style={rowStyle}>{row.AcType}</td>
											<td style={{width:'100%'}}></td>
										</tr>
									))}
								</tbody>
							)}
						</Table>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection:'row',
							justifyContent:'flex-end',
							alignItems:'center'
						}}
					>
						<Button
							disabled={selectedRow?.Id?false:true}
							onClick={() => handleOk()}
							variant="outlined"
							color="neutral"
							sx={{
								borderRadius: '0px',
								fontSize: '12px',
								fontWeight:100,
								bgcolor:'#d6d3ce',
								border:'2px outset white',
								height:26,
								width:56,
								lineHeight:1,
								minHeight:0,
								p:0
							}}
						>
							Ок
						</Button>
						<Button
							onClick={() => setShowAircraftsList(false)}
							variant="outlined"
							color="neutral"
							sx={{
								borderRadius: '0px',
								fontSize: '12px',
								fontWeight:100,
								bgcolor:'#d6d3ce',
								border:'2px outset white',
								height:26,
								width:56,
								lineHeight:1,
								minHeight:0,
								p:0,
								ml:1
							}}
						>
							Cancel
						</Button>
					</Box>
				</Sheet>
			</Modal>
	  	)
	}

	//Запрос данных для таблицы
	const fetchData = async () => {
		setShowSpinner(value => value + 1);
		const response = await fetch(`${URL}${process.env.REACT_APP_API_PARTM}/Planning/PlanSchedule(acReg='${selectedAc?.AcReg}',statusFilter='${filters.status}',objectTypeFilter='${filters.type}',instructionsFlagFilter='${filters.instructionsFlagFilters.length !==0? filters.instructionsFlagFilters:'None'}',checkFilter='',idNumberFilter='${filters.idNumber}',woWpFilter='${filters.wpWo}')`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			if(response.status !== 204){
				const result = await response.json();
				setData(result);	
			} else {
				setData([]);	
			}
		} else {
			try{
				const answer = await response.json()
				if(answer.message){
					toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
				} else {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			} catch {
				toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
			}
		}
		setShowSpinner(value => value - 1);
	};

	React.useEffect(() => {
		if(selectedAc?.AcReg)fetchData();
	}, [selectedAc, filters]);

	const [selectedRow, setSelectedRow] = React.useState({id:0})

	//Выбор строки
	const handleRowSelection = (row) => {
		if (selectedRow.id === row.id) {
			setSelectedRow({id:0})
		} else {
			setSelectedRow(row)
		}
	};

	//Фильтрация данных
	React.useEffect(() => {
		let filtered = data;
		//for (const key in filters) {
			//if(filters[key] != ''){
				//filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
			//}
		//}
		setFilteredData(filtered);
	}, [data, filters]);

	//Сортировка данных
	const handleSort = (property, value) => {
		let filtered = filteredData
		if (property === 'id'){
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
		} else {
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
		}
		setFilteredData(filtered)
		setSort({lable:property, order:value})
	};

	const checkRowColor = (row,index) => {
		if(selectedRow.id === row.id) return '#c0c0ff'
		if(row.overdue === 'N' && row.plusMinusDays > 21) return '#c6ffc6'
		if(row.overdue === 'N' && row.plusMinusDays <= 21 && !row.remainings.includes('-')) return '#ffffc6'
		if(row.overdue === 'Y' && !row.wp) return '#ffc3c6'
		if(row.overdue === 'Y' && row.wp) return '#ffe3c6'
		if(row.overdue === 'F') return '#e7e3e7'
		if(index % 2 === 0){
			return '#EDF2F7'
		} else {
			return 'white'
		}
	}
	
	const changeInstructionsFlagFilters = (property) => {
		const arr = filters.instructionsFlagFilters
		const index = arr.indexOf(property);
		if (index > -1) {
			arr.splice(index, 1);
		} else {
			arr.push(property);
		}
		handleChange('instructionsFlagFilters', [...arr])
	}

	//Создание Excel отчёта
	const createExcel = () => {
		return fetch(`${URL}${process.env.REACT_APP_API_PARTM}/Planning/PlanScheduleXlsx(acReg='${selectedAc?.AcReg}',statusFilter='${filters.status}',objectTypeFilter='${filters.type}',instructionsFlagFilter='${filters.instructionsFlagFilters.length !==0? filters.instructionsFlagFilters:'None'}',checkFilter='',idNumberFilter='${filters.idNumber}',woWpFilter='${filters.wpWo}')`, 
		{
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				//setResponseStatus({...responseStatus, errorStatus:response.status});
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'Tasks.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	};

	const handleTaskOverdueCheckClick = (row,value) => {
		setShowSpinner(value => value + 1);
		fetch(`${URL}${process.env.REACT_APP_API_PARTM}/Planning/MarkTask(acReg='${selectedAc?.AcReg}',taskId=${row.id},check=${value})`, 
		{
			method:'POST',
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
			
		})
		.then(async (response) => {
			if (response.ok) {
				let item = data.find(i => i.id === row.id)
				if(item){
					item.new = value?'I':null
				}
			} else {
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			}
			
		})
		.catch((error) => {
			console.error('Ошибка:', error);
		}).finally(() => {
			setShowSpinner(value => value - 1);
		});

	}

	//#region Lazy loading 
	const tableRef = React.useRef(null); // Ссылка на элемент таблицы
	const [sliceCount, setSliceCount] = React.useState(100)

	const handleScroll = () => {
		if (tableRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
			if (scrollTop + clientHeight >= scrollHeight - 100) {
				setSliceCount(value=>value+100)
			}
		}
	};

	React.useEffect(() => {
		const tableElement = tableRef.current;
		if (tableElement) {
			tableElement.addEventListener('scroll', handleScroll);
		}
		return () => {
			if (tableElement) {
				tableElement.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);
	//#endregion

	return (
		<CssVarsProvider disableTransitionOnChange>
			<CssBaseline />
			<ToastContainer/>
			{showSpinner !== 0 && (<Spiner/>)}
			<Box sx={{ display: 'flex', minHeight: '100dvh', minWidth:'1100px' }}>
				<Header title="Planning"/>
				<Box
					component="main"
					className="MainContent"
					sx={{
						px: 1,
						pt:'calc(var(--Header-height))',
						pb: 1,
						flex: 1,
						minWidth: 0,
						height: '100dvh',
					}}
				>
					<AircraftSelectingModal/>
					<Forecast
						setShowForecastWnd={setShowForecastWnd}
						showForecastWnd={showForecastWnd}
						URL={URL}
					/>
					<ForecastComp
						setShowForecastCompWnd={setShowForecastCompWnd}
						showForecastCompWnd={showForecastCompWnd}
						URL={URL}
					/>
					<ForecastSpare
						setShowForecastSpareWnd={setShowForecastSpareWnd}
						showForecastSpareWnd={showForecastSpareWnd}
						URL={URL}
					/>
					<Box
						sx={{
							position:'relative',
							height:'100%',
							display:'grid',
							gridTemplateRows:showTabel.task && showTabel.components?`40px 70px calc(70% - 110px) 30%`:`40px 70px calc(100% - 110px)`,
							gridTemplateColumns:'auto 500px',
							alignItems:'center',
						}}
					>
						<Box
							sx={{
								id:'Forecast_Buttons',
								display:'flex',
								gridRow:1,
								gridColumnStart:1,
								gridColumnEnd:3
							}}
						>
							<Button
								onClick={()=>navigate(`/Part-M`)}
								variant="outlined"
								color="neutral"
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'transparent',
									border:'2px outset white',
									height:40,
									width:100,
									display:'flex',
									flexDirection:'column',
									mr:1,
									lineHeight:1
								}}
							>
								<img 
									width='20px' 
									height='20px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Close
							</Button>
							<Button
								onClick={()=>setShowForecastWnd(true)}
								variant="outlined"
								color="neutral"
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'transparent',
									border:'2px outset white',
									height:40,
									width:100,
									display:'flex',
									flexDirection:'column',
									mr:1,
									lineHeight:1
								}}
							>
								<img 
									width='20px' 
									height='20px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Forecast
							</Button>
							<Button
								onClick={()=>setShowForecastCompWnd(true)}
								variant="outlined"
								color="neutral"
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'transparent',
									border:'2px outset white',
									height:40,
									width:100,
									display:'flex',
									flexDirection:'column',
									mr:1,
									lineHeight:1
								}}
							>
								<img 
									width='20px' 
									height='20px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								ForecastComp
							</Button>
							<Button
								onClick={()=>setShowForecastSpareWnd(true)}
								variant="outlined"
								color="neutral"
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'transparent',
									border:'2px outset white',
									height:40,
									width:100,
									display:'flex',
									flexDirection:'column',
									mr:1,
									lineHeight:1
								}}
							>
								<img 
									width='20px' 
									height='20px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								ForecastSpare
							</Button>
							<Button
								variant="outlined"
								color="neutral"
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'transparent',
									border:'2px outset white',
									height:40,
									width:100,
									display:'flex',
									flexDirection:'column',
									mr:1,
									lineHeight:1,
									whiteSpace:'nowrap'
								}}
							>
								<img 
									width='20px' 
									height='20px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Data Validation
							</Button>
						</Box>
						<Box
							sx={{
								display:'grid',
								gridTemplateColumns:!showCalc?'1260px auto':'920px auto',
								border:'1px solid black',
								gridRow:2,
								gridColumnStart:1,
								gridColumnEnd:3
							}}
						>
							<Box
								sx={{
									position:'relative', 
									display:!isApuSelection && !showCalc?'grid':'none',
									gridTemplateColumns:'100px 100px 100px 100px 100px 120px 60px 90px 130px 110px 110px 110px',
									alignItems:'end',
									py:1,
									px:1
								}}
							>
								<Box
									sx={{
										position:'absolute',
										top:-10,
										left:5,
										fontSize:'12px',
										bgcolor:'#d6d3ce',
										color:'#000084'
									}}
								>
									Selection:
								</Box>
								<Button
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '12px',
										fontWeight:100,
										backgroundColor:'#ffc3c6',
										border:'2px outset white',
										height:18,
										width:60,
										lineHeight:1,
										position:'absolute',
										top:-10,
										left:70,
										minHeight:0,
										p:0
									}}
								>
									Del Mark
								</Button>
								<Button
									onClick={()=>{setIsApuSelection(true)}}
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '12px',
										fontWeight:100,
										backgroundColor:'#c6c3ff',
										border:'2px outset white',
										height:18,
										width:40,
										lineHeight:1,
										position:'absolute',
										top:-10,
										right:5,
										minHeight:0,
										p:0
									}}
								>
									APU
								</Button>
								<Box
									sx={{
										display:'flex',
										flexDirection:'column',
										gap:0.5,
									}}
								>
									<Checkbox 
										label='Task:'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'12px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& span': {
												borderRadius: '0px',
												borderWidth:'1px',
												borderColor:'black',
												color:'black'
											}, 
										}}
										checked={showTabel?.task}
										onChange={(event) => changeShowTable('task', event.target.checked)}
									/>
									<Checkbox 
										label='Components:'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'12px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& span': {
												borderRadius: '0px',
												borderWidth:'1px',
												borderColor:'black',
												color:'black'
											}, 
										}}
										checked={showTabel?.components}
										onChange={(event) => changeShowTable('components', event.target.checked)}
									/>
								</Box>
								<FormControl sx={{cursor:'pointer'}} onClick={()=>setShowAircraftsList(true)}>
									<FormLabel sx={labelStyle}>AC Reg.:</FormLabel>
									<Autocomplete
										disabled
										disableClearable
										options={[]}
										value={selectedAc?.AcReg ?? ''}
										//onChange={(event, value) => setEditedDocRefData({...editedDocRefData, docType:value ?? ''})}
										sx={{
											...inputStyle,
											'--variant-outlinedDisabledColor':'#000084',
											'--variant-outlinedDisabledBorder':'black',
											
										}}
										
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>AC Family:</FormLabel>
									<Input  
										disabled
										sx={{
											...inputStyle, 
											'--variant-outlinedDisabledColor':'black',
											'--variant-outlinedDisabledBorder':'black',
											bgcolor:'#d6d3ce',
										}} 
										value={selectedAc?.AcFamily ?? ''}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>AC Type:</FormLabel>
									<Input  
										disabled
										sx={{
											...inputStyle, 
											'--variant-outlinedDisabledColor':'black',
											'--variant-outlinedDisabledBorder':'black',
											bgcolor:'#d6d3ce',
										}} 
										value={selectedAc?.AcType ?? ''}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>S/N:</FormLabel>
									<Input  
										disabled
										sx={{
											...inputStyle, 
											'--variant-outlinedDisabledColor':'black',
											'--variant-outlinedDisabledBorder':'black',
											bgcolor:'#d6d3ce',
										}} 
										value={selectedAc?.AcSn ?? ''}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>AC MFR. Date:</FormLabel>
									<Input  
										disabled
										sx={{
											...inputStyle, 
											'--variant-outlinedDisabledColor':'black',
											'--variant-outlinedDisabledBorder':'black',
											bgcolor:'#d6d3ce',
										}} 
										value={selectedAc?.AcMfrDate?.slice(0,10) ?? ''}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>STA:</FormLabel>
									<Input  
										disabled
										sx={{
											...inputStyle, 
											'--variant-outlinedDisabledColor':'black',
											'--variant-outlinedDisabledBorder':'black',
											bgcolor:'#d6d3ce',
										}} 
										value={selectedAc?.StaAircraftLocation ?? ''}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>Code ICAO:</FormLabel>
									<Input  
										disabled
										sx={{
											...inputStyle, 
											'--variant-outlinedDisabledColor':'black',
											'--variant-outlinedDisabledBorder':'black',
											bgcolor:'#d6d3ce',
										}} 
										value={selectedAc?.OperatorCodeIcao ?? ''}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>Operator Name:</FormLabel>
									<Input  
										disabled
										sx={{
											...inputStyle, 
											'--variant-outlinedDisabledColor':'black',
											'--variant-outlinedDisabledBorder':'black',
											bgcolor:'#d6d3ce',
										}} 
										value={selectedAc?.OperatorName ?? ''}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>AC Total Date:</FormLabel>
									<Input  
										disabled
										sx={{
											...inputStyle, 
											'--variant-outlinedDisabledColor':'black',
											'--variant-outlinedDisabledBorder':'black',
											bgcolor:'#d6d3ce',
										}} 
										value={actualTimeData?.totalDate?.slice(0,10) ?? ''}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>AC Total FH:</FormLabel>
									<Input  
										disabled
										sx={{
											...inputStyle, 
											'--variant-outlinedDisabledColor':'black',
											'--variant-outlinedDisabledBorder':'black',
											bgcolor:'#d6d3ce',
										}} 
										value={actualTimeData?.totalHours ?? ''}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>AC Total FC:</FormLabel>
									<Input  
										disabled
										sx={{
											...inputStyle, 
											'--variant-outlinedDisabledColor':'black',
											'--variant-outlinedDisabledBorder':'black',
											bgcolor:'#d6d3ce',
										}} 
										value={actualTimeData?.totalCycls ?? ''}
									/>
								</FormControl>
							</Box>
							<Box
								sx={{
									position:'relative', 
									display:isApuSelection && !showCalc?'grid':'none',
									gridTemplateColumns:'270px 270px 120px 100px 120px 120px 120px',
									alignItems:'end',
									py:1,
								}}
							>
								<Box
									sx={{
										position:'absolute',
										top:-10,
										left:5,
										fontSize:'12px',
										bgcolor:'#d6d3ce',
										color:'#000084'
									}}
								>
									Installed APU Data:
								</Box>
								<Button
									onClick={()=>{setIsApuSelection(false)}}
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '12px',
										fontWeight:100,
										backgroundColor:'#c6c3ff',
										border:'2px outset white',
										height:18,
										width:40,
										lineHeight:1,
										position:'absolute',
										top:-10,
										right:5,
										minHeight:0,
										p:0
									}}
								>
									AC
								</Button>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>APU PN:</FormLabel>
									<Input  
										disabled
										sx={{
											...inputStyle, 
											'--variant-outlinedDisabledColor':'black',
											'--variant-outlinedDisabledBorder':'black',
											bgcolor:'#d6d3ce',
										}} 
										value={apuData?.Pn ?? 'Not Found'}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>APU S/N:</FormLabel>
									<Input  
										disabled
										sx={{
											...inputStyle, 
											'--variant-outlinedDisabledColor':'black',
											'--variant-outlinedDisabledBorder':'black',
											bgcolor:'#d6d3ce',
										}} 
										value={apuData?.Sn ?? 'Not Found'}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>APU MFR. Date:</FormLabel>
									<Input  
										disabled
										sx={{
											...inputStyle, 
											'--variant-outlinedDisabledColor':'black',
											'--variant-outlinedDisabledBorder':'black',
											bgcolor:'#d6d3ce',
										}} 
										value={apuData?.MfrDate ?? 'Not Found'}
									/>
								</FormControl>
								<Box/>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>APU Total Date:</FormLabel>
									<Input  
										disabled
										sx={{
											...inputStyle, 
											'--variant-outlinedDisabledColor':'black',
											'--variant-outlinedDisabledBorder':'black',
											bgcolor:'#d6d3ce',
										}} 
										value={apuData?.MaxTotalDate?.slice(0,10) ?? 'Not Found'}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>APU Total FH:</FormLabel>
									<Input  
										disabled
										sx={{
											...inputStyle, 
											'--variant-outlinedDisabledColor':'black',
											'--variant-outlinedDisabledBorder':'black',
											bgcolor:'#d6d3ce',
										}} 
										value={apuData?.MaxApuTotalHrs ?? 'Not Found'}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>APU Total FC:</FormLabel>
									<Input  
										disabled
										sx={{
											...inputStyle, 
											'--variant-outlinedDisabledColor':'black',
											'--variant-outlinedDisabledBorder':'black',
											bgcolor:'#d6d3ce',
										}} 
										value={apuData?.MaxApuTotalCycl ?? 'Not Found'}
									/>
								</FormControl>
							</Box>
							<Box
								sx={{
									position:'relative', 
									display:showCalc?'grid':'none',
									gridTemplateColumns:'270px 130px 70px 40px 120px 150px 65px 60px',
									alignItems:'center',
									pt:1,
									bgcolor:'#dedff7'
								}}
							>
								<Box
									sx={{
										position:'absolute',
										top:-10,
										left:5,
										fontSize:'12px',
										bgcolor:'#dedff7',
										color:'#000084'
									}}
								>
									Calculation of Average Aircraft's Utilization Criteria:
								</Box>
								<RadioGroup 
									orientation='horizontal' 
									sx={{
										'--RadioGroup-gap':'8px',
										borderRadius:'0px',
										p:0.5,
										zIndex:1
									}}
								>
									<Radio
										label="1 W" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12
										}}
										checked={calculationData?.periodValue === 1 && calculationData?.periodType === 'Week'}
										onChange={()=>setCalculationData({...calculationData, periodValue:1, periodType:'Week'})}
									/>
									<Radio
										label="2 W" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12,
											
										}}
										checked={calculationData?.periodValue === 2 && calculationData?.periodType === 'Week'}
										onChange={()=>setCalculationData({...calculationData, periodValue:2, periodType:'Week'})}
									/>
									<Radio
										label="1 M" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12,
											
										}}
										checked={calculationData?.periodValue === 1 && calculationData?.periodType === 'Month'}
										onChange={()=>setCalculationData({...calculationData, periodValue:1, periodType:'Month'})}
									/>
									<Radio
										label="3 M" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12,
											
										}}
										checked={calculationData?.periodValue === 3 && calculationData?.periodType === 'Month'}
										onChange={()=>setCalculationData({...calculationData, periodValue:3, periodType:'Month'})}
									/>
									<Radio
										label="6 M" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12,
											
										}}
										checked={calculationData?.periodValue === 6 && calculationData?.periodType === 'Month'}
										onChange={()=>setCalculationData({...calculationData, periodValue:6, periodType:'Month'})}
									/>
									<Radio
										label="12 M" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12,
											
										}}
										checked={calculationData?.periodValue === 12 && calculationData?.periodType === 'Month'}
										onChange={()=>setCalculationData({...calculationData, periodValue:12, periodType:'Month'})}
									/>
								</RadioGroup>
								<Box
									sx={{
										display:'flex',
										alignItems:'center',
										flexDirection:'column',
										
									}}
								>
									<Box
										sx={{
											display:'flex',
											alignItems:'center',
											mb:0.2
										}}
									>
										<Typography
											sx={{
												fontSize: '10px',
											}}
										>
											Calc AVG FH:
										</Typography>
										<Input  
											
											sx={{
												...inputStyle, 
												height:'16px',
												p:0,
												width:'50px'
											}} 
											value={calculationData?.calcAvgFh ?? ''}
										/>
									</Box>
									<Box
										sx={{
											display:'flex',
											alignItems:'center',
										}}
									>
										<Typography
											sx={{
												fontSize: '10px',
											}}
										>
											Calc AVG FC:
										</Typography>
										<Input  
											
											sx={{
												...inputStyle, 
												height:'16px',
												p:0,
												width:'50px'
											}} 
											value={calculationData?.calcAvgFc ?? ''}
										/>
									</Box>
								</Box>
								<Button
									variant="outlined"
									color="neutral"
									onClick={fetchCalcData}
									sx={{
										borderRadius: '0px',
										fontSize: '14px',
										fontWeight:100,
										backgroundColor:'#c6c3ff',
										border:'2px outset white',
										height:40,
										width:70,
										display:'flex',
										flexDirection:'column',
										mr:1,
										lineHeight:1
									}}
								>
									<img 
										width='20px' 
										height='20px' 
										src="/images/OUTLOOK7.ico" 
										alt="" 
									/>
									Calculate
								</Button>
								<Box/>
								<Box
									sx={{
										display:'flex',
										alignItems:'center',
										flexDirection:'column',
										
									}}
								>
									<Box
										sx={{
											display:'flex',
											alignItems:'center',
											mb:0.2
										}}
									>
										<Typography
											sx={{
												fontSize: '10px',
											}}
										>
											Fix AVG FH:
										</Typography>
										<Input  
											type='number'
											sx={{
												...inputStyle, 
												height:'16px',
												p:0,
												width:'50px'
											}} 
											value={calculationData?.fixAvgFh ?? ''}
											onChange={(event)=>setCalculationData({...calculationData, fixAvgFh:event.target.valueAsNumber})}
										/>
									</Box>
									<Box
										sx={{
											display:'flex',
											alignItems:'center',
										}}
									>
										<Typography
											sx={{
												fontSize: '10px',
											}}
										>
											Fix AVG FC:
										</Typography>
										<Input  
											type='number'
											sx={{
												...inputStyle, 
												height:'16px',
												p:0,
												width:'50px'
											}} 
											value={calculationData?.fixAvgFc ?? ''}
											onChange={(event)=>setCalculationData({...calculationData, fixAvgFc:event.target.valueAsNumber})}
										/>
									</Box>
								</Box>
								<Box
									sx={{
										display:'flex',
										flexDirection:'column',
										gap:0.5,
									}}
								>
									<Checkbox 
										label='Apply Calculated AVG:'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'12px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& span': {
												borderRadius: '0px',
												borderWidth:'1px',
												borderColor:'black',
												color:'black'
											}, 
										}}
										checked={calculationData?.apply === 'calc'}
										onChange={()=>setCalculationData({...calculationData, apply:'calc'})}
									/>
									<Checkbox 
										label='Apply Fixed AVG:'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'12px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& span': {
												borderRadius: '0px',
												borderWidth:'1px',
												borderColor:'black',
												color:'black'
											}, 
										}}
										checked={calculationData?.apply === 'fix'}
										onChange={()=>setCalculationData({...calculationData, apply:'fix'})}
									/>
								</Box>
								<Button
									onClick={handleApply}
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '14px',
										fontWeight:100,
										backgroundColor:'#c6c3ff',
										border:'2px outset white',
										height:40,
										width:60,
										display:'flex',
										flexDirection:'column',
										mr:1,
										lineHeight:1
									}}
								>
									<img 
										width='20px' 
										height='20px' 
										src="/images/OUTLOOK7.ico" 
										alt="" 
									/>
									Apply
								</Button>
								<Button
									onClick={()=>setShowCalc(false)}
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '14px',
										fontWeight:100,
										backgroundColor:'#c6c3ff',
										border:'2px outset white',
										height:40,
										width:60,
										display:'flex',
										flexDirection:'column',
										mr:1,
										lineHeight:1
									}}
								>
									<img 
										width='20px' 
										height='20px' 
										src="/images/OUTLOOK7.ico" 
										alt="" 
									/>
									Close
								</Button>
							</Box>
							<Box
								sx={{
									width:'100%',
									position:'relative',
									borderLeft:'1px solid black',
									bgcolor:'#dedff7',
									display:'grid',
									gridTemplateColumns:'70px 90px 30px 65px 60px',
									gridTemplateRows:'7px 22px 22px',
									pl:1
								}}
							>
								<Box
									sx={{
										position:'absolute',
										top:-10,
										left:5,
										fontSize:'12px',
										bgcolor:'#dedff7',
										color:'#000084'
									}}
								>
									Average: Saved
								</Box>
								<Button
									onClick={() => setShowCalc(!showCalc)}
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '12px',
										fontWeight:100,
										bgcolor:'#d6d3ce',
										border:'2px outset white',
										height:18,
										width:36,
										lineHeight:1,
										position:'absolute',
										top:-10,
										left:100,
										minHeight:0,
										p:0
									}}
								>
									Calc
								</Button>
								<Box
									sx={{
										
										display:'flex',
										alignItems:'center',
										gridRow:2
									}}
								>
									<Typography
										sx={{
											fontSize: '10px',
										}}
									>
										FH:
									</Typography>
									<Input  
										
										sx={{
											...inputStyle, 
											height:'16px',
											p:0
										}} 
										value={calculationData?.acAvgFh}
										onChange={(event)=>{setCalculationData({...calculationData, acAvgFh:event?.target.valueAsNumber })}}
									/>
								</Box>
								<Box
									sx={{
										
										display:'flex',
										alignItems:'center',
										gridRow:3
									}}
								>
									<Typography
										sx={{
											fontSize: '10px',
										}}
									>
										FC:
									</Typography>
									<Input  
										
										sx={{
											...inputStyle, 
											height:'16px',
											p:0
										}} 
										value={calculationData?.acAvgFc}
										onChange={(event)=>{setCalculationData({...calculationData, acAvgFc:event?.target.valueAsNUmber })}}
									/>
								</Box>
								<Box
									sx={{
										
										display:'flex',
										alignItems:'center',
										gridRow:2
									}}
								>
									<Typography
										sx={{
											fontSize: '10px',
											whiteSpace:'nowrap'
										}}
									>
										APU FH:
									</Typography>
									<Input  
										
										sx={{
											...inputStyle, 
											height:'16px',
											p:0
										}} 
										value={calculationData?.apuAvgFh ?? 'NA' }
										onChange={(event)=>{setCalculationData({...calculationData, apuAvgFh:event?.target.valueAsNumber })}}
									/>
								</Box>
								<Box
									sx={{
										
										display:'flex',
										alignItems:'center',
										gridRow:3
									}}
								>
									<Typography
										sx={{
											fontSize: '10px',
											whiteSpace:'nowrap'
										}}
									>
										APU FC:
									</Typography>
									<Input  
										
										sx={{
											...inputStyle, 
											height:'16px',
											p:0
										}} 
										value={calculationData?.apuAvgFc ?? 'NA' }
										onChange={(event)=>{setCalculationData({...calculationData, apuAvgFc:event?.target.value })}}
									/>
								</Box>
								<Button
									onClick={handleDelete}
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '14px',
										fontWeight:100,
										backgroundColor:'#c6c3ff',
										border:'2px outset white',
										height:40,
										width:60,
										display:showCalc?'flex':'none',
										flexDirection:'column',
										mr:1,
										lineHeight:1,
										gridColumn:4,
										gridRow:2,
									}}
								>
									<img 
										width='20px' 
										height='20px' 
										src="/images/OUTLOOK7.ico" 
										alt="" 
									/>
									Delete
								</Button>
								<Button
									onClick={handleSave}
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '14px',
										fontWeight:100,
										backgroundColor:'#c6c3ff',
										border:'2px outset white',
										height:40,
										width:60,
										display:showCalc?'flex':'none',
										flexDirection:'column',
										mr:1,
										lineHeight:1,
										gridColumn:5,
										gridRow:2,
									}}
								>
									<img 
										width='20px' 
										height='20px' 
										src="/images/OUTLOOK7.ico" 
										alt="" 
									/>
									Save
								</Button>
							</Box>
						</Box>
						<Box
							sx={{
								height:'98%',
								border:'1px solid black',
								position:'relative',
								display:showTabel.task?'grid':'none',
								gridTemplateRows:'20px auto',
								pt:2,
								px:0.5,
								gridRow:3,
								gridColumnStart:1,
								gridColumnEnd:showWp?2:3
							}}
						>
							<Box
								sx={{
									position:'absolute',
									top:-10,
									left:5,
									fontSize:'12px',
									bgcolor:'#d6d3ce',
									color:'#000084'
								}}
							>
								AC Sched: found {filteredData?.length}
							</Box>
							<Box
								sx={{
									position:'absolute',
									top:-5,
									left:130,
									fontSize:'12px',
									bgcolor:'#d6d3ce',
									color:'#000084',
								}}
							>
								<Checkbox 
									label='HIDE:'
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'12px',
										'--Checkbox-size':'12px',
										gap:0.4,
										'& span': {
											borderRadius: '0px',
											borderWidth:'1px',
											borderColor:'black',
											color:'black'
										}, 
									}}
									checked={filters.instructionsFlagFilters.indexOf('Hide') > -1? true:false}
									onChange={() => changeInstructionsFlagFilters('Hide')}
								/>
								<Checkbox 
									label='MAND-LIM:'
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'12px',
										'--Checkbox-size':'12px',
										gap:0.4,
										'& span': {
											borderRadius: '0px',
											borderWidth:'1px',
											borderColor:'black',
											color:'black'
										}, 
										ml:0.5
									}}
									checked={filters.instructionsFlagFilters.indexOf('Mandlim') > -1? true:false}
									onChange={() => changeInstructionsFlagFilters('Mandlim')}
								/>
								<Checkbox 
									label='MAJOR:'
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'12px',
										'--Checkbox-size':'12px',
										gap:0.4,
										'& span': {
											borderRadius: '0px',
											borderWidth:'1px',
											borderColor:'black',
											color:'black'
										}, 
										ml:0.5
									}}
									checked={filters.instructionsFlagFilters.indexOf('Major') > -1? true:false}
									onChange={() => changeInstructionsFlagFilters('Major')}
								/>
								<Checkbox 
									label=':FLS-56'
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'12px',
										'--Checkbox-size':'12px',
										gap:0.4,
										'& span': {
											borderRadius: '0px',
											borderWidth:'1px',
											borderColor:'black',
											color:'black'
										}, 
										ml:0.5
									}}
									checked={filters.instructionsFlagFilters.indexOf('Fls56') > -1? true:false}
									onChange={() => changeInstructionsFlagFilters('Fls56')}
								/>
								<Checkbox 
									label=':FLS-75'
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'12px',
										'--Checkbox-size':'12px',
										gap:0.4,
										'& span': {
											borderRadius: '0px',
											borderWidth:'1px',
											borderColor:'black',
											color:'black'
										}, 
										ml:0.5
									}}
									checked={filters.instructionsFlagFilters.indexOf('Fls75') > -1? true:false}
									onChange={() => changeInstructionsFlagFilters('Fls75')}
								/>
								<Button
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '12px',
										fontWeight:100,
										backgroundColor:'#ffc3c6',
										border:'2px outset white',
										height:18,
										width:60,
										lineHeight:1,
										minHeight:0,
										p:0,
										ml:1
									}}
								>
									Columns
								</Button>
								<Button
									onClick={()=>{setIsApuSelection(true)}}
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '12px',
										fontWeight:100,
										backgroundColor:'#dedff7',
										border:'2px outset white',
										height:18,
										width:40,
										lineHeight:1,
										minHeight:0,
										p:0,
										ml:0.5
									}}
								>
									Reset
								</Button>
							</Box>
							<Box
								sx={{
									display:'flex',
									alignItems:'flex-end',
								}}
							>
								<RadioGroup 
									orientation='horizontal' 
									sx={{
										'--RadioGroup-gap':'8px',
										border:'1px solid black',
										borderRadius:'0px',
										p:0.5,
										zIndex:1
									}}
								>
									<Radio
										label="All:" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12
										}}
										checked={filters.status === 'All'}
										onChange={() => handleChange('status', 'All')}
									/>
									<Radio
										label="Over:" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12,
											
										}}
										checked={filters.status === 'Over'}
										onChange={() => handleChange('status', 'Over')}
									/>
									<Radio
										label="Sch:" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12,
											
										}}
										checked={filters.status === 'Sch'}
										onChange={() => handleChange('status', 'Sch')}
									/>
									<Radio
										label="Fin:" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12,
											
										}}
										checked={filters.status === 'Fin'}
										onChange={() => handleChange('status', 'Fin')}
									/>
									<Radio
										label="NA:" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12,
											
										}}
										checked={filters.status === 'Na'}
										onChange={() => handleChange('status', 'Na')}
									/>
								</RadioGroup>
								<RadioGroup 
									orientation='horizontal' 
									sx={{
										'--RadioGroup-gap':'8px',
										border:'1px solid black',
										borderRadius:'0px',
										p:0.5,
										zIndex:1
									}}
								>
									<Radio
										label="OOP:" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12
										}}
										checked={filters.type === 'Oop'}
										onChange={() => handleChange('type', 'Oop')}
									/>
									<Radio
										label="ALL:" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12,
											
										}}
										checked={filters.type === 'All'}
										onChange={() => handleChange('type', 'All')}
									/>
									<Radio
										label="Tasks:" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12,
											
										}}
										checked={filters.type === 'Tasks'}
										onChange={() => handleChange('type', 'Tasks')}
									/>
									<Radio
										label="Checks:" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12,
											
										}}
										checked={filters.type === 'Checks'}
										onChange={() => handleChange('type', 'Checks')}
									/>
									<Radio
										label="EC:" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12,
											
										}}
										checked={filters.type === 'EC'}
										onChange={() => handleChange('type', 'EC')}
									/>
									<Radio
										label="NRC:" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12,
											
										}}
										checked={filters.type === 'NRC'}
										onChange={() => handleChange('type', 'NRC')}
									/>
								</RadioGroup>
								<FormControl sx={{ml:8}}>
									<FormLabel sx={labelStyle}>Filter ID-Number:</FormLabel>
									<Input  
										sx={{
											...inputStyle, 
											width:'120px',
										}} 
										value={filters?.idNumber ?? ''}
										onChange={(event)=>handleChange('idNumber', event.target.value)}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>Filter WP/WO:</FormLabel>
									<Input  
										sx={{
											...inputStyle, 
											width:'120px'
										}} 
										value={filters?.wpWo ?? ''}
										onChange={(event)=>handleChange('wpWo', event.target.value)}
									/>
								</FormControl>
								<Button
									onClick={()=>{setShowWp(!showWp)}}
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '12px',
										fontWeight:100,
										backgroundColor:'#c6c3ff',
										border:'2px outset white',
										height:32,
										width:40,
										lineHeight:1,
										minHeight:0,
										p:0,
										ml:0.5
									}}
								>
									WP
								</Button>
								<Button
									onClick={createExcel}
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '12px',
										fontWeight:100,
										backgroundColor:'#c6c3ff',
										border:'2px outset white',
										height:32,
										width:40,
										lineHeight:1,
										minHeight:0,
										p:0,
										ml:'auto'
									}}
								>
									Excel
								</Button>
							</Box>
							<Sheet
								id='tablesSheet'
								variant="outlined"
								sx={{
									width: '100%',
									borderRadius: '0px',
									overflow: 'auto',
									border: '1px solid black',
									bgcolor:'#848284',
									my:0.3,
									position:'relative',
								}}
								ref={tableRef}
							>
								<Table
									id='table'
									borderAxis="both"
									stickyHeader
									hoverRow
									sx={{
										'--TableCell-headBackground': '#d6d3ce',
										'--Table-headerUnderlineThickness': '1px',
										'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
										'--TableCell-paddingY': '1px',
										'--TableCell-height': '20px',
										'--TableCell-paddingX': '5px',
										'--TableCell-borderColor': '#939293',
										tableLayout: 'auto', 
										width:'auto',
										borderRadius:'0px',
									}}
								>
									<thead>
										<tr>
											<th style={headerStyle}>
												<Typography
													onClick={() => handleSort('id', sort.order == 'asc' ? 'desc':'asc' )}
													endDecorator={<ArrowDropDownIcon />}
													sx={{
														'& svg': {
															color:sort.lable === 'id' ? '#636b74' : 'transparent',
															transition: '0.2s',
															transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
														},
														fontWeight:filters?.id ? 'bold' : 'normal'
													}}
												>
													ID
												</Typography>
											</th>
											{headers.map((row) => (
												<th style={headerStyle}>
													<Typography
														onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
														endDecorator={<ArrowDropDownIcon />}
														sx={{
															'& svg': {
																color:sort.lable === row.id ? '#636b74' : 'transparent',
																transition: '0.2s',
																transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
															},
															fontWeight:tableFilters?.[row.id] ? 'bold' : 'normal'
														}}
													>
														{row.name}
													</Typography>
												</th>
											))}
										</tr>
										{showFilters && (
											<tr>
												<th style={{padding:0}}>
													<Input
														value={filters?.id || ''}
														onChange={(event) => handleChange('id', event.target.value)}
														type="number"
														slotProps={{
															input: {
																step: 1,
																min:0
															}
														}}
														sx={filtersStyle}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.ata || ''}
														onChange={(event) => handleChange('ata', event.target.value)}
														sx={filtersStyle}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.task || ''}
														onChange={(event) => handleChange('task', event.target.value)}
														sx={filtersStyle}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.basicTask || ''}
														onChange={(event) => handleChange('basicTask', event.target.value)}
														sx={filtersStyle}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.jic || ''}
														onChange={(event) => handleChange('jic', event.target.value)}
														sx={filtersStyle}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.taskTitle || ''}
														onChange={(event) => handleChange('taskTitle', event.target.value)}
														sx={filtersStyle}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.taskDescription || ''}
														onChange={(event) => handleChange('taskDescription', event.target.value)}
														sx={filtersStyle}
													/>
												</th>
												<th style={{padding:0}}>
													<Autocomplete
														options={[...new Set(data.map(data => data.taskType))].filter(row => row).map(taskType=> ({ label: taskType}))}
														onChange={(event, value) => handleChange('taskType', value ? value.label : '')}
														value={{label: filters?.taskType || ''}}
														sx={filtersStyle}
													/>
												</th>
												<th style={{padding:0}}>
													<Autocomplete
														options={[...new Set(data.map(data => data.taskEffectivity))].filter(row => row).map(taskEffectivity=> ({ label: taskEffectivity}))}
														onChange={(event, value) => handleChange('taskEffectivity', value ? value.label : '')}
														value={{label: filters?.taskEffectivity || ''}}
														sx={filtersStyle}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.fhStart || ''}
														onChange={(event) => handleChange('fhStart', event.target.value)}
														sx={filtersStyle}
														type="number"
														slotProps={{
															input: {
																step: 1,
																min:0
															}
														}}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.fcStart || ''}
														onChange={(event) => handleChange('fcStart', event.target.value)}
														sx={filtersStyle}
														type="number"
														slotProps={{
															input: {
																step: 1,
																min:0
															}
														}}
													/>
												</th>
												<th style={{padding:0}}>
													<Autocomplete
														options={[...new Set(data.map(data => data.calendarStart))].filter(row => row).map(calendarStart=> ({ label: calendarStart}))}
														onChange={(event, value) => handleChange('calendarStart', value ? value.label : '')}
														value={{label: filters?.calendarStart || ''}}
														sx={filtersStyle}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.calendarValueStart || ''}
														onChange={(event) => handleChange('calendarValueStart', event.target.value)}
														sx={filtersStyle}
														type="number"
														slotProps={{
															input: {
																step: 1,
																min:0
															}
														}}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.docReferenceStart || ''}
														onChange={(event) => handleChange('docReferenceStart', event.target.value)}
														sx={filtersStyle}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.fhInterval || ''}
														onChange={(event) => handleChange('fhInterval', event.target.value)}
														sx={filtersStyle}
														type="number"
														slotProps={{
															input: {
																step: 1,
																min:0
															}
														}}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.fcInterval || ''}
														onChange={(event) => handleChange('fcInterval', event.target.value)}
														sx={filtersStyle}
														type="number"
														slotProps={{
															input: {
																step: 1,
																min:0
															}
														}}
													/>
												</th>
												<th style={{padding:0}}>
													<Autocomplete
														options={[...new Set(data.map(data => data.calendarInterval))].filter(row => row).map(calendarInterval=> ({ label: calendarInterval}))}
														onChange={(event, value) => handleChange('calendarInterval', value ? value.label : '')}
														value={{label: filters?.calendarInterval || ''}}
														sx={filtersStyle}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.calendarValueInterval || ''}
														onChange={(event) => handleChange('calendarValueInterval', event.target.value)}
														sx={filtersStyle}
														type="number"
														slotProps={{
															input: {
																step: 1,
																min:0
															}
														}}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.docReferenceInterval || ''}
														onChange={(event) => handleChange('docReferenceInterval', event.target.value)}
														sx={filtersStyle}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.completedByComponentReplacement || ''}
														onChange={(event) => handleChange('completedByComponentReplacement', event.target.value)}
														sx={filtersStyle}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.ipcPositions || ''}
														onChange={(event) => handleChange('ipcPositions', event.target.value)}
														sx={filtersStyle}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.fhFinish || ''}
														onChange={(event) => handleChange('fhFinish', event.target.value)}
														sx={filtersStyle}
														type="number"
														slotProps={{
															input: {
																step: 1,
																min:0
															}
														}}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.fcFinish || ''}
														onChange={(event) => handleChange('fcFinish', event.target.value)}
														sx={filtersStyle}
														type="number"
														slotProps={{
															input: {
																step: 1,
																min:0
															}
														}}
													/>
												</th>
												<th style={{padding:0}}>
													<Autocomplete
														options={[...new Set(data.map(data => data.calendarFinish))].filter(row => row).map(calendarFinish=> ({ label: calendarFinish}))}
														onChange={(event, value) => handleChange('calendarFinish', value ? value.label : '')}
														value={{label: filters?.calendarFinish || ''}}
														sx={filtersStyle}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.calendarValueFinish || ''}
														onChange={(event) => handleChange('calendarValueFinish', event.target.value)}
														sx={filtersStyle}
														type="number"
														slotProps={{
															input: {
																step: 1,
																min:0
															}
														}}
													/>
												</th>
												<th style={{padding:0}}>
													<Input
														value={filters?.docReferenceFinish || ''}
														onChange={(event) => handleChange('docReferenceFinish', event.target.value)}
														sx={filtersStyle}
													/>
												</th>
												<th/>
												<th/>
												<th/>
												<th/>
												<th/>
												<th/>
												<th/>
												<th/>
												<th/>
												<th/>
												<th/>
												<th/>
												<th/>
												<th/>
											</tr>
										)}
									</thead>
									<tbody>
										{filteredData.length == 0 && (
											<th colSpan={999}>
												<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
											</th>
										)}
										{filteredData.slice(0,sliceCount).map((row, index) => (
											<tr
												key={row.id}
												onClick={() => handleRowSelection(row)}
												style={{ 
													backgroundColor: checkRowColor(row, index),
													color:'black',
												}}
											>
												<td style={{...rowStyle, backgroundColor: '#c6c3c6'}}>{row.id}</td>
												
												{headers.map((obj) => {
													return obj.id === 'overdue' ? (
														<td style={rowStyle}>
															{row.overdue}
															<Checkbox 
																variant="outlined"
																sx={{
																	fontSize: '12px',
																	'--Checkbox-size': '12px',
																	gap: 0.4,
																	'& span': {
																		borderRadius: '0px',
																		borderWidth: '1px',
																		borderColor: 'black',
																		color: 'black',
																		backgroundColor: row.wp ? '#009ece':'white',
																	}, 
																	'& span > span': {
																		border:'none',
																		color: 'black',backgroundColor:'transparent',
																	}, 
																}}
																checked={row.wp || row.new === 'I'}
																onChange={(event) => handleTaskOverdueCheckClick(row,event.target.checked)}
															/>
														</td>
													) : (
														<td style={rowStyle}>{row[obj.id]}</td>
													);
												})}					
											</tr>
										))}
									</tbody>
								</Table>
							</Sheet>
						</Box>
						<Box
							sx={{
								height:'100%',
								border:'1px solid black',
								position:'relative',
								mt:1,
								display:showTabel.components?'grid':'none',
								gridTemplateRows:'20px auto',
								pt:2,
								px:0.5,
								gridRow:showTabel.task?4:3,
								gridColumnStart:1,
								gridColumnEnd:showWp?2:3
							}}
						>
							<Box
								sx={{
									position:'absolute',
									top:-10,
									left:5,
									fontSize:'12px',
									bgcolor:'#d6d3ce',
									color:'#000084'
								}}
							>
								Component Schedule: 100
							</Box>
							<Box
								sx={{
									position:'absolute',
									top:-8,
									left:200,
									fontSize:'12px',
									bgcolor:'#d6d3ce',
									color:'#000084',
								}}
							>
								<Button
									onClick={()=>{setIsApuSelection(true)}}
									variant="outlined"
									color="neutral"
									sx={{
										borderRadius: '0px',
										fontSize: '12px',
										fontWeight:100,
										backgroundColor:'#dedff7',
										border:'2px outset white',
										height:18,
										width:40,
										lineHeight:1,
										minHeight:0,
										p:0,
										
									}}
								>
									Reset
								</Button>
							</Box>
							<Box
								sx={{
									display:'flex',
									alignItems:'flex-end',
								}}
							>
								<RadioGroup 
									orientation='horizontal' 
									sx={{
										'--RadioGroup-gap':'8px',
										border:'1px solid black',
										borderRadius:'0px',
										p:0.5,
										zIndex:1,
										pr:5.2
									}}
								>
									<Radio
										label="All:" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12
										}}
										//checked={filters.isTask}
										//onChange={()=>setFilters({...filters, isTask:true})}
									/>
									<Radio
										label="Overdue:" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12,
											
										}}
										//checked={filters.isTask}
										//onChange={()=>setFilters({...filters, isTask:true})}
									/>
									<Radio
										label="Scheduled:" 
										variant="outlined" 
										color='neutral'
										sx={{
											'--Radio-size':'12px',
											gap:0.4,
											fontSize:12,
											
										}}
										//checked={filters.isTask}
										//onChange={()=>setFilters({...filters, isTask:true})}
									/>
								</RadioGroup>
								<FormControl sx={{ml:1}}>
									<FormLabel sx={labelStyle}>Filter IPC Pos.:</FormLabel>
									<Input  
										sx={{
											...inputStyle, 
											width:'120px',
										}} 
										value={'B737-NG'}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>PN:</FormLabel>
									<Input  
										sx={{
											...inputStyle, 
											width:'120px'
										}} 
										value={'B737-NG'}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>SN:</FormLabel>
									<Input  
										sx={{
											...inputStyle, 
											width:'120px'
										}} 
										value={'B737-NG'}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>Description:</FormLabel>
									<Input  
										sx={{
											...inputStyle, 
											width:'120px'
										}} 
										value={'B737-NG'}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>TC reference:</FormLabel>
									<Input  
										sx={{
											...inputStyle, 
											width:'120px'
										}} 
										value={'B737-NG'}
									/>
								</FormControl>
								<FormControl sx={{}}>
									<FormLabel sx={labelStyle}>TRT:</FormLabel>
									<Input  
										sx={{
											...inputStyle, 
											width:'120px'
										}} 
										value={'B737-NG'}
									/>
								</FormControl>
								<Box
									sx={{
										display:'flex',
										flexDirection:'column',
										gap:0.5,
										whiteSpace:'nowrap'
									}}
								>
									<Checkbox 
										label='Show All:'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'12px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& span': {
												borderRadius: '0px',
												borderWidth:'1px',
												borderColor:'black',
												color:'black'
											}, 
										}}
										//checked={controls?.q}
										//onChange={(event) => setControls({...controls, q:event.target.checked})}
									/>
									<Checkbox 
										label='Show Counts:'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'12px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& span': {
												borderRadius: '0px',
												borderWidth:'1px',
												borderColor:'black',
												color:'black'
											}, 
										}}
										//checked={controls?.q}
										//onChange={(event) => setControls({...controls, q:event.target.checked})}
									/>
								</Box>
							</Box>
						</Box>
						<Box
							sx={{
								height:'100%',
								position:'relative',
								pt:0.6,
								pl:1,
								display:showWp?'flex':'none',
								gridColumn:2,
								gridRowStart:3,
								gridRowEnd:showTabel.task && showTabel.components?5:4,
								width:'100%',
							}}
						>
							<WP/>
						</Box>
					</Box>		
				</Box>
			</Box>
		</CssVarsProvider>
	);
}
