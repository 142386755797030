import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Divider from '@mui/joy/Divider';
import Checkbox from '@mui/joy/Checkbox';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import { ToastContainer, toast, Zoom} from "react-toastify";

//Иконки
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

//Компоненты 
import Requirements from './Requirements.tsx'
import RelationTree from './TreeViews/RelationTree.tsx'
import AmpPositionsTree from './TreeViews/AmpPositionsTree.tsx';
import Spiner from '../../../Components/Spiner.tsx'
import ConfirmAction from '../../../Components/ConfirmAction.tsx';

export default function Pos_AMP_MR(props) {
	//Стили
	const inputStyle = {borderRadius:'0px', minHeight:'0px', height:'26px', border:'1px solid black',color:'#000078', fontWeight:'bold',fontSize:'14px', mb:1, minWidth:0,width:'100%'}
	const lableStyle = {m:0, fontFamily:'Arial, sans-serif', fontSize:'14px',alignSelf:'center',lineHeight:1, color:'black'};

	const [filters, setFilters] = React.useState<{subAssy:boolean, ipcPos:string, partEff:string}>();
	const [positionCount, setPositionCount] = React.useState(0)
	const [relationCount, setRelationCount] = React.useState(0)
	const [positionId, setPositionId] = React.useState();
	const [taskId, setTaskId] = React.useState();
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [confirmActionType, setConfirmActionType] = React.useState('');
	const windowName = 'POS-AMP MR'

	const [showSpinner, setShowSpinner] = React.useState(0)

	const toastOptions = {
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: false,
		theme: "light",
		transition: Zoom
	};

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	const handleAction = async (type) => {
		setConfirmActionType(type);
		if (type === 'Add'){
			setShowConfirm(true);
		} else if (type === 'Update') {
			setShowConfirm(true);
		} else if (type === 'Delete'){
			setShowConfirm(true);
		}
	};

	//Обработчик события добавления новой записи
	const handleAdd = () =>  {
		selectedRows.map((taskid)=>{
			fetch(
				`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrIpcPositions/MoveTaskIntoPosition`, 
				{
					method: 'POST',
					headers: {
						'Authorization': `Bearer ${localStorage.getItem('asid')}`,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						request:{
							ampid:props.selectedAmp.id,
							taskid:taskid,
							positionid:positionId
						}
					})
				}
			)
			.then((response) => {
				if (!response.ok){
					if(response.massage){
						toast.error(<div>Error<br/><hr/>{response.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} else {
					toast.success("MRB_Code added successfully", toastOptions);
				}
				props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка создания группы:', error));
		})
	}

	//Обработчик события удаления выбранной записи
	const handleDelete = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrIpcPositions/RemoveMrFromPosition(ampId=${props.selectedAmp?.id},positionId=${positionId},taskId=${taskId})`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));
	}
	
	//#region Изменение высоты таблицы
	const [isDragging, setIsDragging] = React.useState(false);
	const [defHeight, setDefHeight] = React.useState(300)
	const [tableAreaHeight, setTableAreaHeight] = React.useState(defHeight);
	const [startPosition, setStartPosition] = React.useState({ y: 0});

	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({ y: e.clientY});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		const deltaY = e.clientY - startPosition.y;
		setTableAreaHeight(defHeight+deltaY)
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
		setDefHeight(tableAreaHeight)
	};
	//#endregion

	//#region Lazy loading для дерева
	const [loading, setLoading] = React.useState(false); // Состояние загрузки
	const tableRef = React.useRef(null); // Ссылка на элемент таблицы
	const [sliceCount, setSliceCount] = React.useState(100)
	
	const handleScroll = () => {
		if (tableRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
			if (scrollTop + clientHeight >= scrollHeight - 5 && !loading) {
				setSliceCount(value=>value+50)
			}
		}
	};

	React.useEffect(() => {
		const tableElement = tableRef.current;
		if (tableElement) {
			tableElement.addEventListener('scroll', handleScroll);
		}
		return () => {
			if (tableElement) {
				tableElement.removeEventListener('scroll', handleScroll);
			}
		};
	}, [loading]);
	//#endregion

	return (
		<React.Fragment>
			<ToastContainer/>
			{showSpinner !== 0 && (<Spiner/>)}
			{showConfirm && (
				<ConfirmAction 
					actionType={confirmActionType} 
					windowName={windowName} 
					onConfirmAdd={handleAdd} 
					onConfirmUpdate={handleDelete}
					onConfirmDelete={handleDelete} 
					setShowConfirm={setShowConfirm}
					showConfirm={showConfirm}
				/>
			)}
			<Box
				id='main'
				sx={{
					position:'relative',
					height:'100%',
					display:'grid',
					gridTemplateColumns:'50% 50%',
				}}
			>
				<Box
					id='tablesArea'
					onMouseMove={handleMouseMove}
					onMouseUp={handleMouseUp}
					sx={{
						display: 'grid',
						gridTemplateRows:`${tableAreaHeight}px 10px auto`,
						position:'relative'
					}}
				>
					<Box
						sx={{
							position:'absolute',
							right:0,
							top:'30%',
							display:'flex',
							flexDirection:'column'
						}}
					>
						<Button	
							disabled={selectedRows?.length >0 && positionId? false : true}
							onClick={()=>handleAction('Add')}
							color='neutral'
							sx={{
								borderRadius:0,
								height: 30, 
								minHeight: 0,
								width:50,
								whiteSpace:'wrap',
								fontSize:12,
								mx:1,
								mb:1,
								bgcolor:'#c7c3fe',
								color:'black',
								border:'2px outset grey',
								lineHeight:1
							}}
						>
							<KeyboardArrowLeftIcon/>
						</Button>
						<Button	
							disabled={taskId ? false : true}
							onClick={()=>handleAction('Delete')}
							color='neutral'
							sx={{
								borderRadius:0,
								height: 30, 
								minHeight: 0,
								width:50,
								whiteSpace:'wrap',
								fontSize:12,
								mx:1,
								mb:1,
								bgcolor:'#c7c3fe',
								color:'black',
								border:'2px outset grey',
								lineHeight:1
							}}
						>
							<KeyboardArrowRightIcon/>
						</Button>
					</Box>
					<Box
						id='topArea'
						sx={{
							position:'relative',
							mr:6
						}}
					>
						<Typography 
							id='topAreaTitle'
							sx={{ 
								position:'absolute',
								fontSize:'16px',
								fontWeight:'bold',
								color:'#000078',
								fontFamily:'Arial, sans-serif',
								left:16
							}}
						>
							AMP IPC Positions Structure:
						</Typography>	
						<Box
							id='buttons'
							sx={{
								display:'flex',
								alignItems:'center',
								justifyContent:'center',
								position:'absolute',
								right:16,
								height:40,
								width:`100%`,
							}}
						>
							<Box
								className="SearchAndFilters-tabletUp"
								sx={{
									width:'100%',
									display:'grid',
									gridTemplateColumns:'auto 100px repeat(2, 20%)',
									gap:1,
									mt:1.5
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems:'flex-end',
										justifyContent:'center',
										gridColumn:2,
										mb:1
									}}
								>
									<Typography sx={{...lableStyle,alignSelf:'flex-end'}}>
										Sub-Assy:
									</Typography>
									<Tooltip title='Apply Filters to Sub-Assy also'>
										<Checkbox
											checked={filters?.subAssy}
											onChange={(event) => handleChange('subAssy', event.target.checked)}
											variant='outlined'
											color='neutral'
											sx={{
												ml:1,
												'& span': {
													borderRadius: '0px',
													borderWidth:'1px',
													borderColor:'black',
													color:'black'
												}, 
											}}
										/>
									</Tooltip>
								</Box>
								<Tooltip title='Filter IPC Position'>
									<FormControl>
										<Input
											value={filters?.ipcPos || ''}
											onChange={(event) => handleChange('ipcPos', event.target.value)}
											sx={inputStyle}
										/>
									</FormControl>
								</Tooltip>
								<Tooltip title='Filter Part Effectivity'>
									<FormControl>
										<Input
											value={filters?.partEff || ''}
											onChange={(event) => handleChange('partEff', event.target.value)}
											sx={inputStyle}
										/>
									</FormControl>
								</Tooltip>
							</Box>
						</Box>		
						<Sheet
							id='tablesSheet'
							variant="outlined"
							ref={tableRef}
							sx={{
								position:'absolute',
								width: `calc(100% - 32px)`,
								height:`calc(100% - 65px)`,
								borderRadius: '0px',
								flexShrink: 1,
								overflow: 'auto',
								border: '1px solid black',
								bgcolor:'white',
								mx:'16px',
								mt:'38px',
								mb:'10px'
							}}
						>
							<AmpPositionsTree
								setPositionId={setPositionId}
								setShowSpinner={setShowSpinner}
								setPositionCount={setPositionCount}
								acFamily={props.selectedACFamily}
								ampId={props.selectedAmp?.id} 
								selectedOperator={props.selectedOperator}
								isSubassy={filters?.subAssy || false}
								partEff={filters?.partEff || ''}
								ipcPos={filters?.ipcPos || ''}
								sliceCount={sliceCount}
							/>
						</Sheet>
						<Box
							sx={{
								display: 'flex',
								position:'absolute',
								width: `calc(100% - 32px)`,
								height:`20px`,
								bottom:5,
								mx:'16px',
								alignItems:'center',
								justifyContent:'space-between',
							}}
						>
							{positionCount !== 0 ? (
								<Typography
									sx={{
										fontSize:'12px',
									}}
								>
									Found {positionCount} Positions
								</Typography>
							):(
								<Typography
									sx={{
										fontSize:'12px',
									}}
								>
									No Positions were Found!
								</Typography>
							)}
						</Box>
					</Box>
					<Box
						onMouseDown={handleMouseDown}
						onMouseMove={handleMouseMove}
						onMouseUp={handleMouseUp}
						sx={{
							cursor:'ns-resize',
							position:'relative',
						}}
					>
						<Divider 
							onMouseDown={handleMouseDown}
							onMouseMove={handleMouseMove}
							onMouseUp={handleMouseUp}
							orientation="horizontal"
							sx={{
								cursor:'ns-resize',
								position:'absolute',
								width:`calc(100% - 65px)`
							}}
						/>
					</Box>		
					<Box
						id='bottomArea'
						sx={{
							position:'relative',
							mr:6
						}}
					>
						<Typography 
							id='topAreaTitle'
							sx={{ 
								position:'absolute',
								fontSize:'16px',
								fontWeight:'bold',
								color:'#000078',
								fontFamily:'Arial, sans-serif',
								left:16
							}}
						>
							Relation between IPC Positions - MR:
						</Typography>	
						<Sheet
							id='tablesSheet'
							variant="outlined"
							sx={{
								position:'absolute',
								width: `calc(100% - 32px)`,
								height:`calc(100% - 54px)`,
								borderRadius: '0px',
								flexShrink: 1,
								overflow: 'auto',
								border: '1px solid black',
								bgcolor:'white',
								mx:'16px',
								mt:'24px',
							}}
						>
							<RelationTree
								setPositionId={setPositionId}
								setTaskId={setTaskId}
								setShowSpinner={setShowSpinner}
								setRelationCount={setRelationCount}
								acFamily={props.selectedACFamily}
								ampId={props.selectedAmp?.id} 
								selectedOperator={props.selectedOperator}
								isSubassy={filters?.subAssy || false}
								partEff={filters?.partEff || ''}
								ipcPos={filters?.ipcPos || ''}
							/>
						</Sheet>
						<Box
							sx={{
								display: 'flex',
								position:'absolute',
								width: `calc(100% - 32px)`,
								height:`20px`,
								bottom:5,
								mx:'16px',
								alignItems:'center',
								justifyContent:'space-between',
							}}
						>
							{relationCount !== 0 ? (
								<Typography
									sx={{
										fontSize:'12px',
									}}
								>
									Found {relationCount} Positions
								</Typography>
							):(
								<Typography
									sx={{
										fontSize:'12px',
									}}
								>
									No Positions were Found!
								</Typography>
							)}
						</Box>
					</Box>
				</Box>
				<Box
					id='requirements'
					sx={{
						display:'flex',
						position:'relative',
					}}
				>
					<Requirements
						URL={props.URL}
						selectedAmp={props.selectedAmp}
						setShowSpinner={setShowSpinner}
						setSelectedRows={setSelectedRows}
						selectedRows={selectedRows}
					/>
				</Box>
			</Box>
		</React.Fragment>
	);
}
