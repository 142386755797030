import * as React from 'react';

import Modal from '@mui/joy/Modal';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Tab from '@mui/joy/Tab';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import Autocomplete from '@mui/joy/Autocomplete';
import Grid from '@mui/joy/Grid';
import Tooltip from '@mui/joy/Tooltip';
import Textarea from '@mui/joy/Textarea';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Checkbox from '@mui/joy/Checkbox';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import CloseIcon from '@mui/icons-material/Close';

export default function TaskInitializing (props) {
	const tabStyle = {fontSize:'14px', lineHeight:1,'--ListItem-minHeight':1};
    const inputStyle = {borderRadius:'0px', minHeight:'0px', height:'26px', border:'1px solid black',color:'#000078', fontWeight:'bold',fontSize:'14px', mb:1, px:0.2}
    const labelStyle = {m:0, fontFamily:'Arial, sans-serif', fontSize:'12px',alignSelf:'center',lineHeight:1};
    const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'14px',color:'black',};
    const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'14px', maxWidth:'500px', textOverflow: 'ellipsis', overflow: 'hidden',lineHeight:1};
    const filtersStyle = {position:'absolute',width:'100%',top:0,minWidth:0,minHeight:0,height:'100%',px: 0.5,m: 0,borderRadius: '0px',"--Input-decoratorChildHeight": "24px",};
    const formControlStyle = { my: 0.2 };

	const TaskInitializing = () => {
		const headers =[
			{id:'acSn',name:'AC_SN'}, 
			{id:'task',name:'Task'},
			{id:'remarks',name:'Remarks'},
			{id:'taskTitle',name:'Task_Title'},
			{id:'taskType',name:'Task_Type'},
			{id:'mnhr',name:'MNHR'},
			{id:'effective',name:'Effective'},
			{id:'status',name:'Status'},
			{id:'fhCompl',name:'FH_Compl'},
			{id:'fhNextDue',name:'FH_Next_Due'},
			{id:'fcCompl',name:'FC_Compl'},
			{id:'fcNextDue',name:'FC_Next_Due'},
			{id:'dateCompl',name:'Date_Compl'},
			{id:'dateNextDue',name:'Date_Next_Due'},
			{id:'iniId',name:'Ini_ID'},
		]

		const [data, setData] = React.useState([])
		const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
		const [selectedRow, setSelectedRow] = React.useState({id:0});

		//Запрос данных для таблицы
		const fetchData = async () => {
			//props.setShowSpinner(value => value + 1);
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/TaskInitializedAlready(taskId=${props.selectedForInitializingTask.id})`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setData(result);
			} else {
				//props.setResponseStatus({...responseStatus, errorStatus:response.status});
			}
			//props.setShowSpinner(value => value - 1);
		};

		React.useEffect(() => {
			fetchData();
		}, []);

		//Сортировка данных
		const handleSort = (property, value) => {
			let filtered = data
			if (property === 'id'){
				filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
			} else {
				filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
			}
			setData(filtered)
			setSort({lable:property, order:value})
		};

		const checkRowColor = (row,index) => {
			if(selectedRow.acReg === row.acReg) return '#c0c0ff'
			if(index % 2 === 0){
				return '#EDF2F7'
			} else {
				return 'white'
			}
		}

		//Выбор строки
		const handleRowSelection = (row) => {
			if(row.acReg === selectedRow.acReg){
				setSelectedRow({acReg:''});
			} else {
				setSelectedRow(row);
			}  
		};

		return(
			<Box
				sx={{
					height:'100%',
					width:'100%',
					display:'grid',
					gridTemplateColumns:'50% 50%',
				}}
			>
				<Box
					id='tableArea'
					sx={{
						position:'relative',
					}}
				>
					<Typography 
						id='tableAreaTitle'
						sx={{ 
							position:'absolute',
							fontSize:'14px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif',
							left:16,
							top:0
						}}
					>
						Task Initializing:
					</Typography>
					<Box
						id='filters'
						sx={{
							display:'flex',
							flexWrap: 'nowrap',
							gap: 0.5,
							alignItems:'flex-end',
							mt:'20px',
							mx:'16px',
						}}
					>
						<FormControl>
							<FormLabel sx={labelStyle}>Task Num.:</FormLabel>
							<Input
								disabled
								sx={{
									...inputStyle, 
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
                                    '& input': {
                                        textAlign: 'center',
                                    }
								}} 
                                value ={props.selectedForInitializingTask.task || ''}
							/>
						</FormControl>
					</Box>
					<Typography
						sx={{
							fontSize:14,
							mx:'16px',
						}}
					>
						Tasks Initialized already:
					</Typography>
					<Sheet
						id='tablesSheet'
						variant="outlined"
						sx={{
							position:'absolute',
							width: `calc(100% - 32px)`,
							height:`calc(100% - 90px)`,
							borderRadius: '0px',
							flexShrink: 1,
							overflow: 'auto',
							border: '1px solid black',
							bgcolor:'#848284',
							mx:'16px',
							mt:'0px',
							mb:'10px'
						}}
					>
						<Table
							id='table'
							borderAxis="both"
							stickyHeader
							hoverRow
							sx={{
								'--TableCell-headBackground': '#d6d3ce',
								'--Table-headerUnderlineThickness': '1px',
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '1px',
								'--TableCell-height': '22px',
								'--TableCell-paddingX': '5px',
								'--TableCell-borderColor': '#939293',
								tableLayout: 'auto', 
								width:'auto',
								borderRadius:'0px'
							}}
						>
							<thead>
								<tr>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('acReg ', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'acReg ' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
											}}
										>
											AC_Reg
										</Typography>
									</th>
									{headers.map((row) => (
										<th style={headerStyle}>
											<Typography
												onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
												endDecorator={<ArrowDropDownIcon />}
												sx={{
													'& svg': {
														color:sort.lable === row.id ? '#636b74' : 'transparent',
														transition: '0.2s',
														transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
													},
												}}
											>
												{row.name}
											</Typography>
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{data.length == 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
									</th>
								)}
								{data.map((row, index) => (
									<tr
										key={row.acReg}
										onClick={() => handleRowSelection(row)}
										style={{ 
											backgroundColor: checkRowColor(row, index),
											color:'black',
										}}
									>
										<td style={{...rowStyle, backgroundColor: selectedRow.acReg === row.acReg ? '#c0c0ff' : '#c6c3c6'}}>{row.acReg}</td>
										<td style={rowStyle}>{row.acSn}</td>
										<td style={rowStyle}>{row.task}</td>
										<td style={rowStyle}>{row.remarks}</td>
										<td style={rowStyle}>{row.taskTitle}</td>
										<td style={rowStyle}>{row.taskType}</td>
										<td style={rowStyle}>{row.mnhr}</td>
										<td style={rowStyle}>{row.effective}</td>
										<td style={rowStyle}>{row.status}</td>
										<td style={rowStyle}>{row.fhCompl}</td>
										<td style={rowStyle}>{row.fhNextDue}</td>
										<td style={rowStyle}>{row.fcCompl}</td>
										<td style={rowStyle}>{row.fcNextDue}</td>
										<td style={rowStyle}>{row.dateCompl}</td>
										<td style={rowStyle}>{row.dateNextDue}</td>
										<td style={rowStyle}>{row.iniId}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
				</Box>
                <Box
                    sx={{position:'relative'}}
                >
                    <TaskInitializingEditore/>
                </Box>
			</Box>
		);
	}

    const TaskInitializingEditore = () => {
        const headers =[
            {id:'sel',name:'SEL'}, 
			{id:'acSn',name:'AC_SN'}, 
			{id:'acFamily',name:'AC_Family'},
			{id:'effective',name:'Effective'},
			{id:'operatorCodeIcao',name:'Operator_Code_ICAO'},
			{id:'taskEffectivity',name:'Task_Effectivity'},
			{id:'note',name:'Note'},
			{id:'isApu',name:'isAPU'},
		]

		const [data, setData] = React.useState([])
		const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
		const [selectedRows, setSelectedRows] = React.useState([]);
        const [editedData, setEditedData] = React.useState({});

        //Запрос данных для таблицы
		const fetchData = async () => {
			//props.setShowSpinner(value => value + 1);
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/TaskAircraftsCanBeInitialized(taskId=${props.selectedForInitializingTask.id})`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setData(result);
			} else {
				//props.setResponseStatus({...responseStatus, errorStatus:response.status});
			}
			//props.setShowSpinner(value => value - 1);
		};

		React.useEffect(() => {
			fetchData();
		}, []);

        //Запрос актуальных часов налета и циклов для editor
		const fetchTotalFhFc= async () => {
			//props.setShowSpinner(value => value + 1);
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/ActualTimes?$apply=groupby((reg),aggregate(totalCycl with max as TotalCycls, totalHrs with max as TotalHrs))&$filter=reg eq '${selectedRows[selectedRows.length-1]}'`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setEditedData({...editedData, totalFh:result[0]?.TotalHrs ?? '', totalFc:result[0]?.TotalCycls ?? ''});
			} else {
				//props.setResponseStatus({...responseStatus, errorStatus:response.status});
			}
			//props.setShowSpinner(value => value - 1);
		};

        const fetchTotalDate= async () => {
			//props.setShowSpinner(value => value + 1);
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/ActualTimes?$filter=reg eq '${selectedRows[selectedRows.length-1]}'&$select=date&$orderby=date desc&$top=1`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setEditedData({...editedData, totalDate:result.value.date});
			} else {
				//props.setResponseStatus({...responseStatus, errorStatus:response.status});
			}
			//props.setShowSpinner(value => value - 1);
		};

        React.useEffect(() => {
			if(selectedRows.length > 0) fetchTotalFhFc();
            if(selectedRows.length > 0) fetchTotalDate();
		}, [selectedRows]);

		//Сортировка данных
		const handleSort = (property, value) => {
			let filtered = data
			if (property === 'id'){
				filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
			} else {
				filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
			}
			setData(filtered)
			setSort({lable:property, order:value})
		};

		const checkRowColor = (row,index) => {
            if(selectedRows.indexOf(row?.acReg) > -1) return '#c0c0ff'
            if(index % 2 === 0){
                return '#EDF2F7'
            } else {
                return 'white'
            }
        }

		//Выбор строки
        const handleRowSelection = (row) => {
            const arr = selectedRows;
            const index = arr.indexOf(row.acReg);

            if (index > -1) {
                arr.splice(index, 1);
            } else {
                arr.push(row.acReg);
            }
            setSelectedRows([...arr]);
        };

        const handleSelectAll = (event) => {
            if (event.target.checked) {
                setSelectedRows(data.map(item => item.acReg))
            } else {
                setSelectedRows([]);
            }
        }
        
        return (
            <Box
                sx={{height:'100%'}}
            >
                <Typography 
                    sx={{
                        fontSize:'14px',
                        fontWeight:'bold',
                        color:'#000078',
                        fontFamily:'Arial, sans-serif',
                        ml:1
                    }}
                >
                    Select Aircrafts to be Initializing:
                </Typography>
                <Box
                    sx={{
                        position:'absolute',
                        height:`100%`,
                        width:'100%',
                        top:'0px',
                        overflow:'auto',
                    }}
                >
                    <Box
                        id='filters'
                        sx={{
                            display:'flex',
                            flexWrap: 'nowrap',
                            gap: 0.5,
                            alignItems:'flex-end',
                            mt:'20px',
                            mx:'16px',
                        }}
                    >
                       <Checkbox 
                            label='Select All'
                            variant="outlined"
                            color="neutral"
                            sx={{
                                fontSize:'12px',
                                '--Checkbox-size':'15px',
                                gap:0.4,
                                mb:1,
                                '& span': {
                                    borderRadius: '0px',
                                    borderWidth:'1px',
                                    borderColor:'black',
                                    color:'black'
                                }, 
                            }}
                            checked={selectedRows?.length > 0 && selectedRows?.length == data.length}
							indeterminate={selectedRows?.length > 0 && selectedRows?.length < data.length}
                            onChange={(event) => handleSelectAll(event)}
                        />
                    </Box>
                    <Sheet
                        id='tablesSheet'
                        variant="outlined"
                        sx={{
                            position:'absolute',
                            width: `calc(100% - 32px)`,
                            height:`48%`,
                            borderRadius: '0px',
                            flexShrink: 1,
                            overflow: 'auto',
                            border: '1px solid black',
                            bgcolor:'#848284',
                            mx:'16px',
                            mt:'0px',
                            mb:'10px'
                        }}
                    >
                        <Table
                            id='table'
                            borderAxis="both"
                            stickyHeader
                            hoverRow
                            sx={{
                                '--TableCell-headBackground': '#d6d3ce',
                                '--Table-headerUnderlineThickness': '1px',
                                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                                '--TableCell-paddingY': '1px',
                                '--TableCell-height': '22px',
                                '--TableCell-paddingX': '5px',
                                '--TableCell-borderColor': '#939293',
                                tableLayout: 'auto', 
                                width:'auto',
                                borderRadius:'0px'
                            }}
                        >
                            <thead>
                                <tr>
                                    <th style={headerStyle}>
                                        <Typography
                                            onClick={() => handleSort('acReg ', sort.order == 'asc' ? 'desc':'asc' )}
                                            endDecorator={<ArrowDropDownIcon />}
                                            sx={{
                                                '& svg': {
                                                    color:sort.lable === 'acReg ' ? '#636b74' : 'transparent',
                                                    transition: '0.2s',
                                                    transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
                                                },
                                            }}
                                        >
                                            AC_Reg
                                        </Typography>
                                    </th>
                                    {headers.map((row) => (
                                        <th style={headerStyle}>
                                            <Typography
                                                onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
                                                endDecorator={<ArrowDropDownIcon />}
                                                sx={{
                                                    '& svg': {
                                                        color:sort.lable === row.id ? '#636b74' : 'transparent',
                                                        transition: '0.2s',
                                                        transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
                                                    },
                                                }}
                                            >
                                                {row.name}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.length == 0 && (
                                    <th colSpan={999}>
                                        <td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
                                    </th>
                                )}
                                {data.map((row, index) => (
                                    <tr
                                        key={row.acReg}
                                        onClick={() => handleRowSelection(row)}
                                        style={{ 
                                            backgroundColor: checkRowColor(row, index),
                                            color:'black',
                                        }}
                                    >
                                        <td style={{...rowStyle, backgroundColor: '#c6c3c6'}}>{row.acReg}</td>
                                        <td style={{...rowStyle, textAlign:'center' }}>
                                            <Checkbox 
                                                variant="outlined"
                                                color="neutral"
                                                sx={{
                                                    fontSize:'12px',
                                                    '--Checkbox-size':'15px',
                                                    gap:0.4,
                                                    '& span': {
														borderRadius: '0px',
														borderWidth:'1px',
														borderColor:'black',
														color:'black'
													}, 
                                                }}
                                                checked={selectedRows.indexOf(row?.acReg) > -1}
                                            />
                                        </td>
                                        <td style={rowStyle}>{row.acSn}</td>
                                        <td style={rowStyle}>{row.acFamily}</td>
                                        <td style={rowStyle}>{row.effective}</td>
                                        <td style={rowStyle}>{row.operatorCodeIcao}</td>
                                        <td style={rowStyle}>{row.taskEffectivity}</td>
                                        <td style={rowStyle}>{row.note}</td>
                                        <td style={rowStyle}>{row.isApu.toString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Sheet>
                    <Box 
                        sx={{
                            position:'absolute',
                            width: `calc(100% - 32px)`,
                            height:`42%`,
                            border:'1px solid black',
                            top:`calc(40% + 107px)`,
                            mx:'16px'
                        }}
                    >
                        <Box 
                            sx={{
                                display:'flex', 
                                position:'absolute',
                                top:-12,
                                left:5
                            }}
                        >
                            <Typography
                                sx={{
                                    bgcolor:'#d6d3ce',
                                    fontWeight:'600',
                                    color:'black',
                                    fontSize:14
                                }}
                            >
                                Initializaizing Date:
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                height:'100%',
                                display:'grid',
                                gridTemplateRows:`45px 42px 25% auto`,
                                gridTemplateColumns:'20% 20% 20% 20% 20%',
                                p:0.5,

                            }}
                        >
                            <Box
                                sx={{
                                    pt:1.2,
                                }}
                            >
                                <RadioGroup orientation="vertical" >
                                    <Radio 
                                        value="compl" 
                                        label="Compl.:" 
                                        variant="outlined" 
                                        color='neutral'
                                        sx={{
                                            '--Radio-size':'14px',
                                            gap:0.5,
                                            fontSize:14
                                        }}
                                    />
                                    <Radio 
                                        value="due" 
                                        label="Due" 
                                        variant="outlined" 
                                        color='neutral'
                                        sx={{
                                            '--Radio-size':'14px',
                                            gap:0.5,
                                            fontSize:14,
                                            mt:1
                                        }}
                                    />
                                </RadioGroup>
                            </Box>
                            <Box
                                sx={{
                                    px:0.5,
                                }}
                            >
                                <FormControl sx={formControlStyle}>
                                    <FormLabel sx={labelStyle}>Compl. Date:</FormLabel>
                                    <Input  
                                        sx={inputStyle} 
                                        value={editedData?.basicTask ?? ''}
                                        //onChange={(event) => handleChange('basicTask', event.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    px:0.5
                                }}
                            >
                                <FormControl sx={formControlStyle}>
                                    <FormLabel sx={labelStyle}>AC Compl. FH:</FormLabel>
                                    <Input  
                                        sx={inputStyle} 
                                        value={editedData?.basicTask ?? ''}
                                        //onChange={(event) => handleChange('basicTask', event.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    px:0.5
                                }}
                            >
                                <FormControl sx={formControlStyle}>
                                    <FormLabel sx={labelStyle}>AC Compl. FC:</FormLabel>
                                    <Input  
                                        sx={inputStyle} 
                                        value={editedData?.basicTask ?? ''}
                                        //onChange={(event) => handleChange('basicTask', event.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    px:0.5
                                }}
                            >
                                <FormControl sx={formControlStyle}>
                                    <FormLabel sx={labelStyle}>Latest Found Date:</FormLabel>
                                    <Input  
                                        sx={{
                                            ...inputStyle,
                                            '--variant-outlinedDisabledColor':'black',
                                            '--variant-outlinedDisabledBorder':'black',
                                            bgcolor:'#d6d3ce',
                                            '& input': {
                                                textAlign: 'center',
                                            }
                                        }} 
                                        value={editedData?.basicTask ?? ''}
                                        //onChange={(event) => handleChange('basicTask', event.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box/>
                            <Box
                                sx={{
                                    px:0.5,
                                }}
                            >
                                <FormControl sx={formControlStyle}>
                                    <FormLabel sx={labelStyle}>AC Total Date:</FormLabel>
                                    <Input  
                                        sx={{
                                            ...inputStyle,
                                            '--variant-outlinedDisabledColor':'black',
                                            '--variant-outlinedDisabledBorder':'black',
                                            bgcolor:'#d6d3ce',
                                            '& input': {
                                                textAlign: 'center',
                                            }
                                        }} 
                                        value={editedData?.totalDate?.slice(0,10) ?? ''}
                                    />
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    px:0.5
                                }}
                            >
                                <FormControl sx={formControlStyle}>
                                    <FormLabel sx={labelStyle}>AC Total FH:</FormLabel>
                                    <Input  
                                        sx={{
                                            ...inputStyle,
                                            '--variant-outlinedDisabledColor':'black',
                                            '--variant-outlinedDisabledBorder':'black',
                                            bgcolor:'#d6d3ce',
                                            '& input': {
                                                textAlign: 'center',
                                            }
                                        }} 
                                        value={editedData?.totalFh ?? ''}
                                    />
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    px:0.5
                                }}
                            >
                                <FormControl sx={formControlStyle}>
                                    <FormLabel sx={labelStyle}>AC Total FC:</FormLabel>
                                    <Input  
                                        sx={{
                                            ...inputStyle,
                                            '--variant-outlinedDisabledColor':'black',
                                            '--variant-outlinedDisabledBorder':'black',
                                            bgcolor:'#d6d3ce',
                                            '& input': {
                                                textAlign: 'center',
                                            }
                                        }} 
                                        value={editedData?.totalFc ?? ''}
                                    />
                                </FormControl>
                            </Box>
                            <Box/>
                            <Box>
                                <Tooltip title='Whichever Comes Last (Intervals Value)'>
									<Checkbox 
										label='Not Effective:'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'12px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										checked={editedData.whicheverLast === 'Y'?true:false}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
                            </Box>
                            <Box
                                sx={{
                                    gridColumnStart:2,
                                    gridColumnEnd:6,
                                }}
                            >
                                <Textarea 
                                    maxRows={1}
                                    sx={{...inputStyle,color:'#000078', width:'100%', height:'100%'}}
                                    value={editedData?.apuData || ''}
                                    onChange={(event) => setEditedData({...editedData, apuData:event.target.value})}
                                />
                            </Box>
                            <Box
                                sx={{
                                    gridColumnStart:1,
                                    gridColumnEnd:5,
                                    pt:0.5
                                }}
                            >
                                <Textarea 
                                    maxRows={1}
                                    sx={{...inputStyle,color:'#000078', width:'100%', height:'100%'}}
                                    value={editedData?.remarks || ''}
                                    onChange={(event) => setEditedData({...editedData, remarks:event.target.value})}
                                />
                            </Box>
							<Button
								variant="outlined"
								color="neutral"
								disabled
								//onClick={() => props.setShowTaskInitializing(false)}
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'#dedff7',
									border:'2px outset white',
									height:'50px',
									width:'80px',
									display:'flex',
									flexDirection:'column',
									mr:1,
									gridColumnStart:5,
                                    gridColumnEnd:6,
									mt:'auto',
									ml:'auto'
								}}
							>
								<img 
									width='27px' 
									height='27px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Confirm
							</Button>
                        </Box>     
                    </Box>
                </Box>
            </Box>
        )
    }

    const TaskActualInformation = () => {
		const headers =[
			{id:'acSn',name:'AC_SN'}, 
			{id:'task',name:'Task'},
			{id:'remarks',name:'Remarks'},
			{id:'taskTitle',name:'Task_Title'},
			{id:'taskType',name:'Task_Type'},
			{id:'mnhr',name:'MNHR'},
			{id:'effective',name:'Effective'},
			{id:'status',name:'Status'},
			{id:'fhCompl',name:'FH_Compl'},
			{id:'fhNextDue',name:'FH_Next_Due'},
			{id:'fcCompl',name:'FC_Compl'},
			{id:'fcNextDue',name:'FC_Next_Due'},
			{id:'dateCompl',name:'Date_Compl'},
			{id:'dateNextDue',name:'Date_Next_Due'},
			{id:'iniId',name:'Ini_ID'},
		]

		const [showFilters, setShowFilters] = React.useState(false)
		const [data, setData] = React.useState([])
		const [filteredData, setFilteredData] = React.useState([]);
		const [filters, setFilters] = React.useState<{id:number, ata:string, task:string, basicTask:string, jic:string, taskTitle:string, taskDescription:string, taskType:string, taskEffectivity:string, fhStart:number, fcStart:number, calendarStart:string, calendarValueStart:number, docReferenceStart:string, fhInterval:number, fcInterval:number, calendarInterval:string, calendarValueInterval:number, docReferenceInterval:string,completedByComponentReplacement:string, ipcPositions:string, fhFinish:number, fcFinish:number, calendarFinish:string, calendarValueFinish:number, docReferenceFinish:string}>();
		const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
		const [selectedRow, setSelectedRow] = React.useState({id:0});

		//Запрос данных для таблицы
		const fetchData = async () => {
			//props.setShowSpinner(value => value + 1);
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/TaskInitializedAlready(taskId=${props.selectedForInitializingTask.id})`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setData(result);
			} else {
				//props.setResponseStatus({...responseStatus, errorStatus:response.status});
			}
			//props.setShowSpinner(value => value - 1);
		};

		React.useEffect(() => {
			fetchData();
		}, []);

		//Фильтрация данных
		React.useEffect(() => {
			let filtered = data;
			for (const key in filters) {
				if(filters[key] != ''){
					filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
				}
			}
			setFilteredData(filtered);
		}, [data, filters]);

		//Изменение фильтров
		const handleChange = (property, value) => {
			setFilters({ ...filters, [property]: value });
		};

		//Сортировка данных
		const handleSort = (property, value) => {
			let filtered = filteredData
			if (property === 'id'){
				filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
			} else {
				filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
			}
			setFilteredData(filtered)
			setSort({lable:property, order:value})
		};

		//Проверка наличия фильтров
		const checkFilters = () => {
			if (filters === undefined) {
				return true;
			}
		
			// Проверяем, все ли свойства объекта пустые
			return Object.values(filters).every(value => 
				value === '' || value === null || value === undefined || 
				(typeof value === 'number' && value === 0)
			);
		}

		const checkRowColor = (row,index) => {
			if(selectedRow.acReg === row.acReg) return '#c0c0ff'
			if(index % 2 === 0){
				return '#EDF2F7'
			} else {
				return 'white'
			}
		}

		//Выбор строки
		const handleRowSelection = (row) => {
			if(row.acReg === selectedRow.acReg){
				setSelectedRow({acReg:''});
			} else {
				setSelectedRow(row);
			}  
		};

		return(
			<Box
				sx={{
					height:'100%',
					width:'100%',
					display:'grid',
					gridTemplateColumns:'100%',
				}}
			>
				<Box
					id='tableArea'
					sx={{
						position:'relative',
					}}
				>
					<Typography 
						id='tableAreaTitle'
						sx={{ 
							position:'absolute',
							fontSize:'14px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif',
							left:16,
							top:0
						}}
					>
						Task Initializing:
					</Typography>
					<Box
						id='filters'
						sx={{
							display:'flex',
							flexWrap: 'nowrap',
							gap: 0.5,
							alignItems:'flex-end',
							mt:'20px',
							mx:'16px',
						}}
					>
						<FormControl>
							<FormLabel sx={labelStyle}>Task Num.:</FormLabel>
							<Input
								disabled
								sx={{
									...inputStyle, 
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
                                    '& input': {
                                        textAlign: 'center',
                                    }
								}} 
                                value ={props.selectedForInitializingTask.task || ''}
							/>
						</FormControl>
					</Box>
					<Box
						//onClick={() => setShowFilters(!showFilters)}
						sx={{
							border:'1px solid black',
							borderBottom:'none',
							height:'24px',
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							//bgcolor:checkFilters()?'transparent':'#DDE7EE',
							mx:'16px',
						}}
					>
						<ArrowDropDownIcon 
							sx={{
								fontSize: '24px',
								transition: '0.2s',
								//transform: !showFilters ? 'rotate(0deg)' : 'rotate(180deg)',
							}}
						/>
					</Box>
					<Sheet
						id='tablesSheet'
						variant="outlined"
						sx={{
							position:'absolute',
							width: `calc(100% - 32px)`,
							height:`calc(100% - 90px)`,
							borderRadius: '0px',
							flexShrink: 1,
							overflow: 'auto',
							border: '1px solid black',
							bgcolor:'#848284',
							mx:'16px',
							mt:'0px',
							mb:'10px'
						}}
					>
						<Table
							id='table'
							borderAxis="both"
							stickyHeader
							hoverRow
							sx={{
								'--TableCell-headBackground': '#d6d3ce',
								'--Table-headerUnderlineThickness': '1px',
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '1px',
								'--TableCell-height': '22px',
								'--TableCell-paddingX': '5px',
								'--TableCell-borderColor': '#939293',
								tableLayout: 'auto', 
								width:'auto',
								borderRadius:'0px'
							}}
						>
							<thead>
								<tr>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('acReg ', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'acReg ' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
												fontWeight:filters?.acReg != 0 ? 'bold' : 'normal'
											}}
										>
											AC_Reg
										</Typography>
									</th>
									{headers.map((row) => (
										<th style={headerStyle}>
											<Typography
												onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
												endDecorator={<ArrowDropDownIcon />}
												sx={{
													'& svg': {
														color:sort.lable === row.id ? '#636b74' : 'transparent',
														transition: '0.2s',
														transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
													},
													fontWeight:filters?.[row.id] ? 'bold' : 'normal'
												}}
											>
												{row.name}
											</Typography>
										</th>
									))}
								</tr>
								{showFilters && (
									<tr>
										<th style={{padding:0}}>
											<Input
												//value={filters?.id || ''}
												//onChange={(event) => handleChange('id', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}
												}}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												//value={filters?.ataCode || ''}
												//onChange={(event) => handleChange('ataCode', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												//value={filters?.title || ''}
												//onChange={(event) => handleChange('title', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											{/*<Autocomplete
												options={[...new Set(data.map(data => data.acFamily))].filter(row => row).map(acFamily=> ({ label: acFamily}))}
												onChange={(event, value) => handleChange('acFamily', value ? value.label : '')}
												value={{ label: filters?.acFamily || ''}}
												sx={filtersStyle}
											/>*/}
										</th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
									</tr>
								)}
							</thead>
							<tbody>
								{filteredData.length == 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
									</th>
								)}
								{filteredData.map((row, index) => (
									<tr
										key={row.acReg}
										onClick={() => handleRowSelection(row)}
										style={{ 
											backgroundColor: checkRowColor(row, index),
											color:'black',
										}}
									>
										<td style={{...rowStyle, backgroundColor: selectedRow.acReg === row.acReg ? '#c0c0ff' : '#c6c3c6'}}>{row.acReg}</td>
										<td style={rowStyle}>{row.acSn}</td>
										<td style={rowStyle}>{row.task}</td>
										<td style={rowStyle}>{row.remarks}</td>
										<td style={rowStyle}>{row.taskTitle}</td>
										<td style={rowStyle}>{row.taskType}</td>
										<td style={rowStyle}>{row.mnhr}</td>
										<td style={rowStyle}>{row.effective}</td>
										<td style={rowStyle}>{row.status}</td>
										<td style={rowStyle}>{row.fhCompl}</td>
										<td style={rowStyle}>{row.fhNextDue}</td>
										<td style={rowStyle}>{row.fcCompl}</td>
										<td style={rowStyle}>{row.fcNextDue}</td>
										<td style={rowStyle}>{row.dateCompl}</td>
										<td style={rowStyle}>{row.dateNextDue}</td>
										<td style={rowStyle}>{row.iniId}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
				</Box>
			</Box>
		);
	}

    return (
		<Modal 
			open={props.showTaskInitializing} 
			disableRestoreFocus={true}
			disableEnforceFocus={true}
            sx={{backdropFilter:'blur(0px)'}}
		>
			<ModalDialog 
				variant="outlined" 
				role="alertdialog"
				sx={{
					p:0,
					borderRadius:0,
                    width:'60%',
                    height:'70%',
					gap:0,
                    minWidth:'1000px',
                    minHeight:'690px'
				}}
			>
				<DialogTitle
					sx={{
						py:0.5,
						pl:1,
						bgcolor:'#08246b',
						color:'white',
                        fontSize:'12px'
					}}
				>
                    Task Initializing-Actual Information
					<IconButton 
						onClick={() => props.setShowTaskInitializing(false)}
						sx={{
							mr:1,
							ml:'auto',
							height:'12px',
							minHeight:'0px',
							width:'12px',
							minWidth:'0px',
							color:'black'
						}}
					>
						<CloseIcon/>
					</IconButton>
				</DialogTitle>
				<DialogContent 
					sx={{
						m:0,
						p:0
					}}
				>
					<Tabs
						defaultValue="TI"
						sx={{
							flexDirection: 'column',
							bgcolor:'#d6d3ce',
							whiteSpace: 'nowrap',
							'--Tabs-spacing': '5px',
							height:'100%',
							width:'100%',
							m:0
						}}
					>
						<TabList >
							<Tab value="TI" sx={tabStyle}>
								Task-Initializing
							</Tab>
							<Tab value="TAI" sx={tabStyle}>
								Task-Actual Information
							</Tab>
						</TabList>
						<TabPanel value="TI" sx={{p:'0px'}}>
							<TaskInitializing/>
						</TabPanel>
						<TabPanel value="TAI" sx={{p:'0px'}}>
                            <TaskActualInformation/>
						</TabPanel>
					</Tabs>
				</DialogContent>
				<DialogActions
					sx={{
						bgcolor:'#d6d3ce',
						pt:0,
                        display:'grid',
                        gridTemplateColumns:'auto 80px',
                        px:2,
					}}
				>
					<Button
                        variant="outlined"
                        color="neutral"
                        onClick={() => props.setShowTaskInitializing(false)}
                        sx={{
                            borderRadius: '0px',
                            fontSize: '14px',
                            fontWeight:100,
                            backgroundColor:'#dedff7',
                            border:'2px outset white',
                            height:'50px',
                            width:'80px',
                            display:'flex',
                            flexDirection:'column',
                            mr:1,
                            gridColumn:2
                        }}
                    >
                        <img 
                            width='27px' 
                            height='27px' 
                            src="/images/OUTLOOK7.ico" 
                            alt="" 
                        />
                        Close
                    </Button>
				</DialogActions>
			</ModalDialog>
     	</Modal>
	);
}