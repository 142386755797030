import * as React from 'react';

import Box from '@mui/joy/Box';
import Tab from '@mui/joy/Tab';
import Tabs from '@mui/joy/Tabs';
import Grid from '@mui/joy/Grid';
import TableJoy from '@mui/joy/Table';
import Input from '@mui/joy/Input';
import Radio from '@mui/joy/Radio';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Textarea from '@mui/joy/Textarea';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import RadioGroup from '@mui/joy/RadioGroup';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import {toast, Zoom, ToastOptions} from "react-toastify";
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';

export default function AddEditeForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, px:1, mx: 0.5, height: 26, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px',"--Input-decoratorChildHeight": "24px", lineHeight:1 };
	const labelStyle = { mx: 1, my: 0.5, p: 0, fontSize:'14px', alignSelf:'center', lineHeight:1 };
	const formControlStyle = { my: 0.2 };
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'14px', lineHeigth:0};
	const tabStyle = {fontSize:'14px', lineHeight:1,'--ListItem-minHeight':1};

	const [editedData, setEditedData] = React.useState<{id?:number, positionId?:number, treatment?:string, treatmentDescription?:string, tcReference?:string, fhStart?:number, fcStart?:number, calendarStart?:string, calendarValueStart?:number, docReferenceStart?:string, replacementTaskStart?:string, ammReferenceStart?:string, fhInterval?:number, fcInterval?:number, calendarInterval?:string, calendarValueInterval?:number, docReferenceInterval?:string, replacementTaskInterval?:string, ammReferenceInterval?:string, fhFinish?:number, fcFinish?:number, calendarFinish?:string, calendarValueFinish?:number, docReferenceFinish?:string, replacementTaskFinish?:string, ammReferenceFInish?:string, positionEffectivity?:string, partEffectivity?:string, logModelId?:number, copyId?:number, mainAssyReplacement?:string, fhCompl?:string, fhAcInstall?:string, fhNextDue?:number, acFhNextDue?:number, fcCompl?:string, fcAcInstall?:string, fcNextDue?:number, acFcNextDue?:number, dateCompl?:string, dateAcInstall?:string, dateNextDue?:string, acDetaNextDue?:string, initialized?:string, overdue?:string, overdueValue?:string, acOverdue?:string, acOverdueValue?:string, ampId?:number, apuUtilization?:boolean, transferId?:number, tireChangeCount?:number, maxTireChangeCount?:number, inSituComponentMaintenance?:string }>();
	const [treatmentsList, setTreatmentsList] = React.useState([])
	const [tcReferenceList, setTcReferenceList] = React.useState([])
	const [replmMaterialsList, setReplmMaterialsList] = React.useState<{id:number, pn:string, description:string, qty:string, storeUnit:string, materialType:string}[]>([])
	const [replmToolsList, setReplmToolsList] = React.useState<{id:number, oemPn:string, supplierPn:string, optionalPn:string, description:string, remarks:string}[]>([])
	const [instractions,setInstractions] = React.useState([])
	const [partEffectivityList, setPartEffectivityList] = React.useState([])
	const [partEffectivityBaseList, setPartEffectivityBaseList] = React.useState<{id:number, pn:string, description:string, hasTreatmen:boolean, mainPn:string, isEngine:boolean, isApu:boolean, isMlg:boolean, isNlg:boolean, isPropeller:boolean, isMgbx:boolean, isTgbx:boolean, isStruct:boolean, isAvionic:boolean }[]>([])
	const [isActivatedTask, setIsActivatedTask] = React.useState(true)
	const [meta, setMeta] = React.useState([{name:'',max:0}]);

	const [showReplmMaterialsEditore, setShowReplmMaterialsEditore] = React.useState(false)
	const [showReplmToolsEditore, setShowReplmToolsEditore] = React.useState(false)

	const toastOptions : ToastOptions = {
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: false,
		theme: "light",
		transition: Zoom
	};

	//Запрос ограничений для полей
	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelMps/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			}
		};
		fetchMeta();
	}, []);

	//Запрос списка Treatments
	React.useEffect(() => {
		const fetchTreatmentList = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/CommonTreatments/Names`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setTreatmentsList(result);
			} else {
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			}
		};
		fetchTreatmentList ();
	}, []);

	//Запрос Treatment Descriptopn
	React.useEffect(() => {
		const fetchTreatmentDescription = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/CommonTreatments/Description/${editedData?.treatment}`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				handleChange('treatmentDescription', result)
			} else {
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			}
		};
		if(editedData?.treatment) fetchTreatmentDescription ();
	}, [editedData?.treatment]);

	//Запрос Treatment Info
	React.useEffect(() => {
		const fetchMPInfo = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelMps/${props.selectedMP}`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setEditedData(result)
			} else {
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			}
		};
		fetchMPInfo ();
	}, [props.selectedMP]);

	//Запрос списка Tc Reference
	React.useEffect(() => {
		const fetchTcReferenceList = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpMrs/Tasks(ampId=${props.selectedAmp.id})`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setTcReferenceList(result.value)
			} else {
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			}
		};
		fetchTcReferenceList ();
	}, []);

	//Запрос списка Replm Materials
	React.useEffect(() => {
		const fetchReplmMaterialsList = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelMps/ReplmMaterials(ampId=${props.selectedAmp.id},positionId=${props.selectedPosition})`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setReplmMaterialsList(result)
			} else {
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			}
		};
		fetchReplmMaterialsList ();
	}, [props.selectedAmp.id, props.selectedPosition]);

	//Запрос списка Replm Tools
	React.useEffect(() => {
		const fetchReplmToolsList = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelMps/ReplmTools(ampId=${props.selectedAmp.id},positionId=${props.selectedPosition})`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setReplmToolsList(result)
			} else {
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			}
		};
		fetchReplmToolsList ();
	}, [props.selectedAmp.id, props.selectedPosition]);

	//Запрос списка Part Effectivity
	React.useEffect(() => {
		const fetchPartEffectivityList = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelPns/PartEffectivity(ampId=${props.selectedAmp.id},positionId=${props.selectedPosition})`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setPartEffectivityBaseList(result)
			} else {
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			}
		};
		if(props.selectedPosition) fetchPartEffectivityList ();
	}, [props.selectedAmp.id, props.selectedPosition]);

	//Запрос списка Part Effectivity для конкретного mpId
	React.useEffect(() => {
		const fetchPartEffectivityList = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelMps/MpPartEffectivity(ampId=${props.selectedAmp.id},mpId=${props.selectedMP})`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setPartEffectivityList(result)
			} else {
				try{
					const answer = await response.json()
					if(answer.message){
						toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
					} else {
						toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
					}
				} catch {
					toast.error(<div>Error<br/><hr/>Status code: {response.status}</div>, toastOptions);
				}
			}
		};
		if(props.selectedMP) fetchPartEffectivityList ();
	}, [props.selectedAmp.id, props.selectedMP]);


	//Обработчик события добавления новой записи
	const handleAdd = async () =>  {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/Atas`, 
			{
				method: 'POST',
				headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
				},
				body: JSON.stringify(editedData)
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка создания группы:', error));
	}

	//Обработчик события обновления выбранной записи
	const handleUpdate = async () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/Atas/${props.selectedRow.id}`, 
			{
				method: 'PATCH',
				headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
				},
				body: JSON.stringify(editedData)
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка обновления группы:', error));	
	}

	//Обработчик события удаления выбранной записи
	const handleDelete = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/Atas/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));
	}
	
	//Внесение изменений в data
	const handleChange = (property, value) => {

		//Проверка максимальной длинны строки
		const maxLengthDefault = 255;
		let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		if (value.length > maxLength) return;	
		
		if(typeof value === 'number'){
			setEditedData({ ...editedData, [property]: isNaN(value) ? null : value  });
		} else {
			setEditedData({ ...editedData, [property]: value  });
		}
	};

	const ReplmMaterialsEditor = () => {
		const [filters, setFilters] = React.useState({pn:'', description:''})
		const [editedReplmMaterialData, setEditedReplmMaterialData] = React.useState({pn:'', description:'', qty:1, materialType:'', unit:'', alt:false})
		const [tableData, setTableData] = React.useState([])
		const [unitsList, setUnitsList] = React.useState([])

		//Запрос списка Replm Materials
		React.useEffect(() => {
			const fetchTableData = async () => {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/PartsCatalogAcTypes/GetWithAcType(partNumber='${filters.pn ?? '%'}',description='${filters.description ?? '%'}')`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setTableData(result)
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			fetchTableData ();
		}, [filters]);

		//Запрос списка Units
		React.useEffect(() => {
			const fetchUnitList = async () => {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/PartsCatalogs/StoreUnits`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setUnitsList(result)
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			fetchUnitList ();
		}, []);

		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1,
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Component Replacement Materials Editor:
					</Typography>
				</Box>
				<Box
					sx={{
						height:'270px',
						display:'grid',
						gridTemplateColumns:'60% 40%',
					}}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'12% 88%',
							gridTemplateColumns:'30% 50% 20%',
							pt:1.5
						}}
					>
						<Tooltip 
							title='Part Number Search Criteria' 
						>
							<Input  
								sx={{...inputStyle, mx:1}} 
								value={filters.pn?? ''}
								onChange={(event) => setFilters({ ...filters, pn:event.target.value})}
							/>
						</Tooltip>
						<Tooltip 
							title='Description Search Criteria' 
						>
							<Input  
								sx={inputStyle} 
								value={filters.description?? ''}
								onChange={(event) => setFilters({ ...filters, description:event.target.value})}
							/>
						</Tooltip>
						<Box
							sx={{
								border:'1px solid black',
								height:'220px',
								mx:1,
								mb:0.3,
								borderRadius:'0px',
								bgcolor:'white',
								overflow:'auto',
								gridRowStart:2, 
								gridColumnStart:1, 
								gridColumnEnd:4
							}}
						>
							{tableData.length === 0 ? (
								<Box>
									Type Search Criteria to Find Part Number !
								</Box>
							) : (
								<AutoSizer>
									{({ height, width }) => (
										<Table
											gridStyle={{ outline: 'none' }}
											width={width}
											height={height}
											headerHeight={0}
											rowHeight={20}
											rowCount={tableData.length}
											rowGetter={({ index }) => tableData[index]}
											onRowDoubleClick={({ index })=>setEditedReplmMaterialData(tableData[index])}
											//onRowDoubleClick={({ index })=>setSelectedPositionPn(filteredPositionPnList[index].pn)}
										>
											<Column width={width} dataKey="pn" />
											<Column width={width} dataKey="description" />
											<Column width={width} dataKey="ata" />
											<Column width={width} dataKey="acType" />
										</Table>
									)}
								</AutoSizer>
							)}
						</Box>
					</Box>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'18% 24% 20% 12% 26%',
							gridTemplateColumns:'25% 25% 25% 25%',
							
						}}
					>
						<FormControl sx={{gridColumnStart:1, gridColumnEnd:5}}>
							<FormLabel sx={labelStyle}>Part Number:</FormLabel>
							<Input  
								disabled
								sx={{
									...inputStyle,
									color:'#000078', 
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
								}} 
								value={editedReplmMaterialData?.pn ?? ''}
							/>
						</FormControl>
						<FormControl sx={{gridColumnStart:1, gridColumnEnd:5}}>
							<FormLabel sx={labelStyle}>Description:</FormLabel>
							<Textarea 
								disabled
								maxRows={1}
								sx={{
									...inputStyle,
									color:'#000078', 
									height:50,
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
								}}
								value={editedReplmMaterialData?.description ?? ''}
							/>
						</FormControl>
						<FormControl sx={{gridColumnStart:1, gridColumnEnd:3}}>
							<FormLabel sx={labelStyle}>Qty:</FormLabel>
							<Input  
								type="number"
								slotProps={{
									input: {
										min:0
									}}
								}
								sx={inputStyle} 
								value={editedReplmMaterialData?.qty ?? ''}
								onChange={(event) => setEditedReplmMaterialData({...editedReplmMaterialData, qty:event.target.valueAsNumber})}
							/>
						</FormControl>
						<FormControl sx={{gridColumnStart:3, gridColumnEnd:5}}>
							<FormLabel sx={labelStyle}>Material Type:</FormLabel>
							<Input  
								disabled
								sx={{
									...inputStyle,
									color:'#000078', 
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
								}} 
								value={editedReplmMaterialData?.materialType ?? ''}
							/>
						</FormControl>
						<Box sx={{gridColumnStart:1, gridColumnEnd:3, display:'flex', justifyContent:'flex-end'}}>
							Unit:
						</Box>
						<Autocomplete
							disableClearable
							options={unitsList}
							value={{ label: editedReplmMaterialData?.unit ?? ''}}
							onChange={(event, value) => setEditedReplmMaterialData({...editedReplmMaterialData, unit:value?.label ?? ''})}
							sx={{...inputStyle, gridColumnStart:3, gridColumnEnd:5}}
						/>
						<Tooltip 
							title='Alternative Y or N' 
							sx={{gridColumnStart:1, gridColumnEnd:2}}
						>
							<Checkbox 
								label=':ALT'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									fontWeight:'bold',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								checked={editedReplmMaterialData?.alt}
								onChange={(event) => setEditedReplmMaterialData({...editedReplmMaterialData, alt:event.target.checked})}
							/>
						</Tooltip>
						<Box 
							sx={{
								gridColumnStart:2, 
								gridColumnEnd:5,
								display:'flex',
								flexDirection:'row'
							}}
						>
							<Button
								variant="outlined"
								color="neutral"
								//onClick = {draftAMP}
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'#dedff7',
									border:'2px outset white',
									height:56,
									width:90,
									display:'flex',
									flexDirection:'column',
									mr:1
								}}
							>
								<img 
									width='27px' 
									height='27px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Add
							</Button>
							<Button
								variant="outlined"
								color="neutral"
								onClick = {()=>setShowReplmMaterialsEditore(false)}
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'#dedff7',
									border:'2px outset white',
									height:56,
									width:90,
									display:'flex',
									flexDirection:'column',
									mr:1
								}}
							>
								<img 
									width='27px' 
									height='27px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Close
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		)
	}

	const ReplmToolsEditor = () => {
		const [filters, setFilters] = React.useState({pn:'', description:''})
		const [editedReplmToolsData, setEditedReplmToolsData] = React.useState({id:0, oemPn:'', supplierPn:'', optionalPn:'', description:'', remarks:''})
		const [tableData, setTableData] = React.useState([])

		//Запрос списка Replm Tools
		React.useEffect(() => {
			const fetchTableData = async () => {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/CommonTools/View(oemPn='${filters.pn ?? '%'}',description='${filters.description ?? '%'}')`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					const result = await response.json();
					setTableData(result.value)
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			fetchTableData ();
		}, [filters]);

		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1,
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Component Replacement Tools and Equipment Editor:
					</Typography>
				</Box>
				<Box
					sx={{
						height:'350px',
						display:'grid',
						gridTemplateColumns:'60% 40%',
					}}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'9% 91%',
							gridTemplateColumns:'30% 50% 20%',
							pt:1.5
						}}
					>
						<Tooltip 
							title='OEM Part Number Search Criteria' 
						>
							<Input  
								sx={{...inputStyle, mx:1}} 
								value={filters.pn?? ''}
								onChange={(event) => setFilters({ ...filters, pn:event.target.value})}
							/>
						</Tooltip>
						<Tooltip 
							title='Description Search Criteria' 
						>
							<Input  
								sx={inputStyle} 
								value={filters.description?? ''}
								onChange={(event) => setFilters({ ...filters, description:event.target.value})}
							/>
						</Tooltip>
						<Box
							sx={{
								border:'1px solid black',
								height:'300px',
								mx:1,
								mb:0.3,
								borderRadius:'0px',
								bgcolor:'white',
								overflow:'auto',
								gridRowStart:2, 
								gridColumnStart:1, 
								gridColumnEnd:4
							}}
						>
							{tableData.length === 0 ? (
								<Box>
									Type Search Criteria to Find Part Number !
								</Box>
							) : (
								<AutoSizer>
									{({ height, width }) => (
										<Table
											gridStyle={{ outline: 'none' }}
											width={width}
											height={height}
											headerHeight={0}
											rowHeight={20}
											rowCount={tableData.length}
											rowGetter={({ index }) => tableData[index]}
											onRowDoubleClick={({ index })=>setEditedReplmToolsData(tableData[index])}
											//onRowDoubleClick={({ index })=>setSelectedPositionPn(filteredPositionPnList[index].pn)}
										>
											<Column width={width} dataKey="id" />
											<Column width={width} dataKey="oemPn" />
											<Column width={width} dataKey="supplierPn" />
											<Column width={width} dataKey="optionalPn" />
											<Column width={width} dataKey="description" />
											<Column width={width} dataKey="remarks" />
										</Table>
									)}
								</AutoSizer>
							)}
						</Box>
					</Box>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'14% 14% 14% 20% 20% 18%',							
						}}
					>
						<FormControl>
							<FormLabel sx={labelStyle}>OEM Part Number:</FormLabel>
							<Input  
								sx={{
									...inputStyle,
								}} 
								value={editedReplmToolsData?.oemPn ?? ''}
								onChange={(event) => setEditedReplmToolsData({...editedReplmToolsData, oemPn:event.target.value})}
							/>
						</FormControl>
						<FormControl>
							<FormLabel sx={labelStyle}>Supplier Part Number::</FormLabel>
							<Input  
								sx={{
									...inputStyle,
								}} 
								value={editedReplmToolsData?.supplierPn ?? ''}
								onChange={(event) => setEditedReplmToolsData({...editedReplmToolsData, supplierPn:event.target.value})}
							/>
						</FormControl>
						<FormControl>
							<FormLabel sx={labelStyle}>Optional Part Number:</FormLabel>
							<Input  
								sx={{
									...inputStyle,
								}} 
								value={editedReplmToolsData?.optionalPn ?? ''}
								onChange={(event) => setEditedReplmToolsData({...editedReplmToolsData, optionalPn:event.target.value})}
							/>
						</FormControl>
						<FormControl>
							<FormLabel sx={labelStyle}>Description:</FormLabel>
							<Textarea 
								maxRows={1}
								sx={{
									...inputStyle,
									height:50,
								}}
								value={editedReplmToolsData?.description ?? ''}
								onChange={(event) => setEditedReplmToolsData({...editedReplmToolsData, description:event.target.value})}
							/>
						</FormControl>
						<FormControl>
							<FormLabel sx={labelStyle}>Remarks:</FormLabel>
							<Textarea 
								maxRows={1}
								sx={{
									...inputStyle,
									height:50,
								}}
								value={editedReplmToolsData?.remarks ?? ''}
								onChange={(event) => setEditedReplmToolsData({...editedReplmToolsData, remarks:event.target.value})}
							/>
						</FormControl>
						<Box 
							sx={{
								display:'flex',
								flexDirection:'row',
								justifyContent:'flex-end',
							}}
						>
							<Button
								variant="outlined"
								color="neutral"
								//onClick = {draftAMP}
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'#dedff7',
									border:'2px outset white',
									height:56,
									width:90,
									display:'flex',
									flexDirection:'column',
									mr:1
								}}
							>
								<img 
									width='27px' 
									height='27px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Add
							</Button>
							<Button
								variant="outlined"
								color="neutral"
								onClick = {()=>setShowReplmToolsEditore(false)}
								sx={{
									borderRadius: '0px',
									fontSize: '14px',
									fontWeight:100,
									backgroundColor:'#dedff7',
									border:'2px outset white',
									height:56,
									width:90,
									display:'flex',
									flexDirection:'column',
									mr:1
								}}
							>
								<img 
									width='27px' 
									height='27px' 
									src="/images/OUTLOOK7.ico" 
									alt="" 
								/>
								Close
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		)
	}

	const InstructionsEditor = () => {
		//Запрос списка Instractions
		React.useEffect(() => {
			const fetchInstractionsList = async () => {
				const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelMps/ReplmInstructions(ampId=${props.selectedAmp.id},positionId=${props.selectedPosition},treatment='${editedData?.treatment}')`, 
				{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				if (response.ok) {	
					//const result = await response.json();
					//setInstractions(result)
				} else {
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				}
			};
			//fetchInstractionsList ();
		}, [props.selectedAmp.id, props.selectedPosition, editedData?.treatment]);
		
		return (
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						INSTRUCTIONS:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:100, pt:1 }}>
					<Grid xs={1}></Grid>
					<Grid xs={2}>
						<Tooltip title='ETOPS Y or N'>
							<Checkbox 
								label=':ETOPS'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='Double Inspection Y or N'>
							<Checkbox 
								label=':DI (RII)'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='Airworthiness Limitation Instruction Y or N'>
							<Checkbox 
								label=':ALI'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={5}>
						<Tooltip title='CPCP'>
							<Checkbox 
								label=':CPCP'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					
					<Grid xs={1}></Grid>
					<Grid xs={2}>
						<Tooltip title='ETOPS II Y or N'>
							<Checkbox 
								label=':ETOPS II'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='Critical Task Y or N'>
							<Checkbox 
								label=':CR'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='EWIS Y or N'>
							<Checkbox 
								label=':EWIS'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={5}>
						<Tooltip title='AD Instruction Y or N'>
							<Checkbox 
								label=':AD'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>

					<Grid xs={1}></Grid>
					<Grid xs={2}>
						<Tooltip title='Category III approach capability affected Y or N'>
							<Checkbox 
								label=':CAT 3'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='Critical Design Configuration Control Limitation Y or N'>
							<Checkbox 
								label=':CDCCL'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='Certificated Maintenance Requirements Y or N'>
							<Checkbox 
								label=':CMR'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={5}/>
				</Grid>
			</Box>
		)
	}

	const PartEffectivity = () => {
		return(
			<Box sx={{display:'flex', flexDirection:'column'}}>
				<Box 
					sx={{
						position:'relative',
						border:'1px solid black',
						mt:1
					}}
				>
					<Box 
						sx={{
							display:'flex', 
							position:'absolute',
							top:-8,
							left:5
						}}
					>
						<Typography
							sx={{
								bgcolor:'#d6d3ce',
								fontWeight:'600',
								color:'black',
								fontSize:14,
								lineHeight:1
							}}
						>
							Part Effectivity:
						</Typography>
					</Box>
					<Grid container spacing={0} sx={{ flexGrow: 1, height:125, pt:0.6, px:1 }}>
						<Grid xs={12}>
							<Box
								sx={{
									border:'1px solid black',
									height:'110px',
									mx:0.5,
									mt:0.5,
									borderRadius:'0px',
									overflow:'auto',
									bgcolor:'#c6ffff',
								}}
							>
								<TableJoy
									aria-labelledby="tableTitle"
									borderAxis="none"
									stickyHeader
									hoverRow
									sx={{
										'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
										'--TableCell-paddingY': '1px',
										"--TableCell-height": "18px",
										'--TableCell-paddingX': '5px',
										tableLayout: 'auto', 
										width:'auto',
										borderRadius:'0px',
									}}
								>
									<tbody>
										<tr
											key={'all'}
											//onClick={() => setSelectedGroupForModule(row)}
											style={{ 
												//backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
												//color: selectedGroupForModule === row ? 'white' : 'black',
												lineHeight:0
											}}
										>
											<td style={{ whiteSpace: 'nowrap', width: 'auto',fontSize:'18px',textAlign: 'center', lineHeight:0 }}>
												<Checkbox 
													variant="outlined"
													color="neutral"
													//checked={row.hasAccess}
													//onChange={handleCheckboxChange}
													sx={{
														mt:1,
														color:'#840000',
														fontWeight:'bold',
														'--Checkbox-size':'15px',
														gap:0.4,
														'& span': {
															borderRadius: '0px',
															borderWidth:'1px',
															borderColor:'black',
															color:'black'
														}, 
													}}
												/>
											</td>
											<td style={rowStyle}>All</td>
											<td style={{width:'100%'}}></td>
											<td style={{width:'100%'}}></td>
											<td style={{width:'100%'}}></td>
											<td style={{width:'100%'}}></td>
										</tr>
										{partEffectivityBaseList.map((row) => (
											<tr
												key={row.id}
												//onClick={() => setSelectedGroupForModule(row)}
												style={{ 
													//backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
													//color: selectedGroupForModule === row ? 'white' : 'black',
													lineHeight:0
												}}
											>
												<td style={{ whiteSpace: 'nowrap', width: 'auto',fontSize:'18px',textAlign: 'center', lineHeight:0 }}>
													<Checkbox 
														variant="outlined"
														color="neutral"
														//checked={row.hasAccess}
														//onChange={handleCheckboxChange}
														sx={{
															mt:1,
															color:'#840000',
															fontWeight:'bold',
															'--Checkbox-size':'15px',
															gap:0.4,
															'& span': {
																borderRadius: '0px',
																borderWidth:'1px',
																borderColor:'black',
																color:'black'
															}, 
														}}
													/>
												</td>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.pn}</td>
												<td style={rowStyle}>{row.description}</td>
												<td style={rowStyle}>{row.mainPn}</td>
												<td style={{width:'100%'}}></td>
											</tr>
										))}
									</tbody>
								</TableJoy>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<Box 
					sx={{
						position:'relative',
						border:'1px solid black',
						mt:1
					}}
				>
					<Box 
						sx={{
							display:'flex', 
							position:'absolute',
							top:-8,
							left:5
						}}
					>
						<Typography
							sx={{
								bgcolor:'#d6d3ce',
								fontWeight:'600',
								color:'black',
								fontSize:14,
								lineHeight:1
							}}
						>
							Associated Treatments:
						</Typography>
					</Box>
					<Grid container spacing={0} sx={{ flexGrow: 1, height:125, pt:0.6, px:1 }}>
						<Grid xs={12}>
							<Box
								sx={{
									border:'1px solid #d2dbe4',
									height:'110px',
									mx:0.5,
									mt:0.5,
									borderRadius:'0px',
									bgcolor:'#c6ffff',
									overflow:'auto'
								}}
							>
								<TableJoy
									aria-labelledby="tableTitle"
									borderAxis="none"
									stickyHeader
									hoverRow
									sx={{
										'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
										'--TableCell-paddingY': '1px',
										"--TableCell-height": "24px",
										'--TableCell-paddingX': '5px',
										tableLayout: 'auto', 
										width:'auto',
										borderRadius:'0px'
									}}
								>
									<tbody>
										{/** 
										{moduleList.map((row) => (
											<tr
												key={row.id}
												onClick={() => setSelectedGroupForModule(row)}
												style={{ 
													backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
													color: selectedGroupForModule === row ? 'white' : 'black',
												}}
											>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.groupName}</td>
												<td style={rowStyle}>{row.groupSta}</td>
												<td style={rowStyle}>{row.permission}</td>
												<td style={rowStyle}>{row.activate}</td>
												<td style={rowStyle}>{row.remarks}</td>
											</tr>
										))}*/}
									</tbody>
								</TableJoy>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<Box 
					sx={{
						position:'relative',
						border:'1px solid black',
						mt:1
					}}
				>
					<Box 
						sx={{
							display:'flex', 
							position:'absolute',
							top:-8,
							left:5
						}}
					>
						<Typography
							sx={{
								bgcolor:'#d6d3ce',
								fontWeight:'600',
								color:'black',
								fontSize:14,
								lineHeight:1
							}}
						>
							Activated Task Cards or EC:
						</Typography>
					</Box>
					<Grid container spacing={0} sx={{ flexGrow: 1, height:125, pt:0.6, px:1 }}>
						<Grid xs={4} sx={{display:'flex', alignItems:'center'}}>
							<RadioGroup orientation="horizontal">
								<Radio 
									checked={isActivatedTask}
									onChange={()=>setIsActivatedTask(true)}
									value="Task" 
									label="Task" 
									variant="outlined" 
									color='neutral'
									sx={{
										'--Radio-size':'14px',
										gap:0.5,
										fontSize:14
									}}
								/>
								<Radio 
									checked={!isActivatedTask}
									onChange={()=>setIsActivatedTask(false)}
									value="EC" 
									label="EC" 
									variant="outlined" 
									color='neutral'
									sx={{
										'--Radio-size':'14px',
										gap:0.5,
										fontSize:14
									}}
								/>
							</RadioGroup>
						</Grid>
						<Grid xs={2} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
							<Typography>
								Filter:
							</Typography>
						</Grid>
						<Grid xs={3.5} sx={{display:'flex', alignItems:'center'}}>
							<Input  
								sx={inputStyle} 
								value={editedData?.title ?? ''}
								onChange={(event) => handleChange('title', event.target.value)}
							/>
						</Grid>
						<Grid xs={2.4} sx={{display:'flex', alignItems:'center'}}>
							<Button
								//onClick={handleConfirm}
								color='neutral'
								sx={{
									borderRadius:0,
									height: 25, 
									minHeight: 0,
									bgcolor:'#d6d3ce',
									color:'black',
									border:'2px outset grey',
									ml:'auto'
								}}
							>
								Add
							</Button>
						</Grid>

						<Grid xs={12}>
							<Box
								sx={{
									border:'1px solid #d2dbe4',
									height:'86px',
									mx:0.5,
									mt:0.1,
									borderRadius:'0px',
									bgcolor:'#c6ffff',
									overflow:'auto'
								}}
							>
								<TableJoy
									aria-labelledby="tableTitle"
									borderAxis="none"
									stickyHeader
									hoverRow
									sx={{
										'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
										'--TableCell-paddingY': '1px',
										"--TableCell-height": "24px",
										'--TableCell-paddingX': '5px',
										tableLayout: 'auto', 
										width:'auto',
										borderRadius:'0px'
									}}
								>
									<tbody>
										{/** 
										{moduleList.map((row) => (
											<tr
												key={row.id}
												onClick={() => setSelectedGroupForModule(row)}
												style={{ 
													backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
													color: selectedGroupForModule === row ? 'white' : 'black',
												}}
											>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.groupName}</td>
												<td style={rowStyle}>{row.groupSta}</td>
												<td style={rowStyle}>{row.permission}</td>
												<td style={rowStyle}>{row.activate}</td>
												<td style={rowStyle}>{row.remarks}</td>
											</tr>
										))}*/}
									</tbody>
								</TableJoy>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
		)	
	}

	return (
		<Box>
			<Typography 
				sx={{
					fontSize:'16px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif',
					ml:1
				}}
			>
				Aircraft Maintenance Requirements Editor:
			</Typography>
			<Box sx={{display:'flex', alignItems:'center'}}>
				<Tooltip title='Add'>
					<IconButton
						variant='plain'
						//disabled={editedData.ataCode && editedData.title && editedData.acFamily ? false : true}
						disabled
						onClick={handleAdd}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px' 
							src="/images/svg/add.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title='Update'>
					<IconButton 
						variant='plain'
						disabled={true}
						onClick={handleUpdate}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px' 
							src="/images/svg/save.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title='Delete'>
					<IconButton 
						variant='plain'
						disabled={true}
						onClick={handleDelete}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px'  
							src="/images/svg/trashcan.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
			</Box>
			<Box
				sx={{
					position:'absolute',
					height:`calc(100% - 70px)`,
					width:'100%',
					overflow:'auto',

				}}
			>
				<Grid container spacing={0} sx={{ flexGrow: 1 }}>
					<Grid xs={4}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Treatment:</FormLabel>
							<Autocomplete
								sx={inputStyle}
								disableClearable
								options={treatmentsList?.map(data=>({label: data}))}
								value={{ label: editedData?.treatment ?? ''}}
								onChange={(event, value) => handleChange('treatment', value ? value.label : '')}
							/>
						</FormControl>
					</Grid>
					<Grid xs={8}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Treatment Description:</FormLabel>
							<Textarea 
								disabled
								maxRows={1}
								sx={{
									...inputStyle,
									color:'#000078', 
									height:32,
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
								}}
								value={editedData?.treatmentDescription ?? ''}
							/>
						</FormControl>
					</Grid>

					<Grid xs={12} sx={{ml:0.5}}>
						<FormControl sx={{mt:2}}>
							<Tooltip title='IN SITU Component Maintenance - Replacements Is Not Required'>
								<Checkbox 
									label=':IN SITU Component Maintenance'
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										fontWeight:'bold',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									checked={editedData?.inSituComponentMaintenance ==='Y'}
									onChange={(event) => handleChange('inSituComponentMaintenance', event.target.checked?'Y':'N')}
								/>
							</Tooltip>
						</FormControl>
					</Grid>

					<Grid xs={12} sx={{mt:1}}>
						<Tabs
							defaultValue="Int"
							sx={{
								flexDirection: 'column',
								bgcolor:'#d6d3ce',
								whiteSpace: 'nowrap',
								ml:0.6,
								'--Tabs-spacing': '5px'
							}}
						>
							<TabList >
								<Tooltip title='Interval'>
									<Tab value="Int" sx={tabStyle}>
										Interval
									</Tab>
								</Tooltip>
								<Tooltip title='Start Threshold'>
									<Tab value="ST" sx={tabStyle}>
										Start Threshold
									</Tab>
								</Tooltip>
								<Tooltip title='Finish Threshold'>
									<Tab value="FT" sx={tabStyle}>
										Finish Threshold
									</Tab>
								</Tooltip>
								<Tooltip title='Materials for Component Replacement'>
									<Tab value="RM" sx={tabStyle}>
										Replm Materials
									</Tab>
								</Tooltip>
								<Tooltip title='Tools and Equipments for Component Replacement'>
									<Tab value="RT" sx={tabStyle}>
										Replm Tools
									</Tab>
								</Tooltip>
								<Tooltip title='JIC procedure for Replacement'>
									<Tab value="RJIC" sx={tabStyle}>
										Replm JIC
									</Tab>
								</Tooltip>
								<Tooltip title='Instraction'>
									<Tab value="Inst" sx={tabStyle}>
										Instraction
									</Tab>
								</Tooltip>
							</TabList>
							<TabPanel value="Int" sx={{p:'0px'}}>
								<Box sx={{display:'flex', flexDirection:'column'}}>
									<Box 
										sx={{
											position:'relative',
											border:'1px solid black',
											mt:1,
											pb:1
										}}
									>
										<Box 
											sx={{
												display:'flex', 
												position:'absolute',
												top:-12,
												left:5
											}}
										>
											<Typography
												sx={{
													bgcolor:'#d6d3ce',
													fontWeight:'600',
													color:'black',
													fontSize:14
												}}
											>
												Interval:
											</Typography>
											<Typography
												sx={{
													ml:3,
													bgcolor:'#d6d3ce',
													color:'red'
												}}
											>
												*
											</Typography>
										</Box>
										
										<Grid container spacing={0}>
											<Grid xs={4}></Grid>
											<Grid xs={1}>
												<FormControl sx={{...formControlStyle,alignItems:'center'}}>
													<FormLabel sx={labelStyle}>DY:</FormLabel>
													<Tooltip title='Days Selector'>
														<Checkbox 
															variant="outlined"
															color="neutral"
															sx={{
																fontSize:'14px',
																'--Checkbox-size':'15px',
																gap:0.4,
																'& span': {
																	borderRadius: '0px',
																	borderWidth:'1px',
																	borderColor:'black',
																	color:'black'
																}, 
															}}
															checked={editedData?.calendarInterval ==='DY'?true:false}
															onChange={(event) => handleChange('calendarInterval', event.target.checked?'DY':'')}
														/>
													</Tooltip>
												</FormControl>
											</Grid>
											<Grid xs={1}>
												<FormControl sx={{...formControlStyle,alignItems:'center'}}>
													<FormLabel sx={labelStyle}>MO:</FormLabel>
													<Tooltip title='Months Selector'>
														<Checkbox 
															variant="outlined"
															color="neutral"
															sx={{
																fontSize:'14px',
																'--Checkbox-size':'15px',
																gap:0.4,
																'& span': {
																	borderRadius: '0px',
																	borderWidth:'1px',
																	borderColor:'black',
																	color:'black'
																}, 
															}}
															checked={editedData?.calendarInterval ==='MO'?true:false}
															onChange={(event) => handleChange('calendarInterval', event.target.checked?'MO':'')}
														/>
													</Tooltip>
												</FormControl>
											</Grid>
											<Grid xs={1}>
												<FormControl sx={{...formControlStyle,alignItems:'center'}}>
													<FormLabel sx={labelStyle}>YR:</FormLabel>
													<Tooltip title='Years Selector'>
														<Checkbox 
															variant="outlined"
															color="neutral"
															sx={{
																fontSize:'14px',
																'--Checkbox-size':'15px',
																gap:0.4,
																'& span': {
																	borderRadius: '0px',
																	borderWidth:'1px',
																	borderColor:'black',
																	color:'black'
																}, 
															}}
															checked={editedData?.calendarInterval ==='YR'?true:false}
															onChange={(event) => handleChange('calendarInterval', event.target.checked?'YR':'')}
														/>
													</Tooltip>
												</FormControl>
											</Grid>
											<Grid xs={5}>
												<FormControl sx={{mt:2}}>
													<Tooltip title='Tasks for Replacements or Other Tasks are required to print in WP Y/N. Specify Task Card Number below and copy task in pdf to TC Location'>
														<Checkbox 
															label=':Task Card Required'
															variant="outlined"
															color="neutral"
															sx={{
																fontSize:'14px',
																'--Checkbox-size':'15px',
																fontWeight:'bold',
																gap:0.4,
																'& span': {
																	borderRadius: '0px',
																	borderWidth:'1px',
																	borderColor:'black',
																	color:'black'
																}, 
															}}
															checked={editedData?.replacementTaskInterval === 'Y'?true:false}
															onChange={(event) => handleChange('replacementTaskInterval', event.target.checked?'Y':'N')}
														/>
													</Tooltip>
												</FormControl>
											</Grid>				
											
											<Grid xs={2}>
												<FormControl>
													<FormLabel sx={labelStyle}>FH:</FormLabel>
													<Input  
														type="number"
														slotProps={{
															input: {
																min:0
															}}
														}
														sx={inputStyle} 
														value={editedData?.fhInterval ?? ''}
														onChange={(event) => handleChange('fhInterval', event.target.valueAsNumber)}
													/>
												</FormControl>
											</Grid>
											<Grid xs={2}>
												<FormControl>
													<FormLabel sx={labelStyle}>FC:</FormLabel>
													<Input  
														type="number"
														slotProps={{
															input: {
																min:0
															}}
														}
														sx={inputStyle} 
														value={editedData?.fcInterval ?? ''}
														onChange={(event) => handleChange('fcInterval', event.target.valueAsNumber)}
													/>
												</FormControl>
											</Grid>
											<Grid xs={1}></Grid>
											<Grid xs={2}>
												<FormLabel sx={{...labelStyle, color:'transparent'}}>.</FormLabel>
												<FormControl>
													<Input  
														type="number"
														slotProps={{
															input: {
																min:0
															}}
														}
														sx={inputStyle} 
														value={editedData?.calendarValueInterval ?? ''}
														onChange={(event) => handleChange('calendarValueInterval', event.target.valueAsNumber)}
													/>
												</FormControl>
											</Grid>
											<Grid xs={5}>
												<FormControl>
													<FormLabel sx={labelStyle}>AMM Reference:</FormLabel>
													<Input  
														sx={inputStyle} 
														value={editedData?.ammReferenceInterval ?? ''}
														onChange={(event) => handleChange('ammReferenceInterval', event.target.value)}
													/>
												</FormControl>
											</Grid>

											<Grid xs={2}>
												<FormControl sx={{...formControlStyle, ml:0.4}}>
													<Tooltip title='APU Flight Data Utilization'>
														<Checkbox 
															label=':APU Data'
															variant="outlined"
															color="neutral"
															sx={{
																fontSize:'14px',
																'--Checkbox-size':'15px',
																gap:0.4,
																'& span': {
																	borderRadius: '0px',
																	borderWidth:'1px',
																	borderColor:'black',
																	color:'black'
																}, 
															}}
															checked={editedData?.apuUtilization}
															onChange={(event) => handleChange('apuUtilization', event.target.checked)}
														/>
													</Tooltip>
												</FormControl>
											</Grid>
											<Grid xs={5}>
												<FormControl>
													<FormLabel sx={labelStyle}>DOC. Reference Data:</FormLabel>
													<Input  
														sx={inputStyle} 
														value={editedData?.docReferenceInterval ?? ''}
														onChange={(event) => handleChange('docReferenceInterval', event.target.value)}
													/>
												</FormControl>
											</Grid>
											<Grid xs={5}>
												<FormControl>
													<FormLabel sx={labelStyle}>Associated TC Reference:</FormLabel>
													<Autocomplete
														disableClearable
														options={tcReferenceList?.map(data=>({label:data}))}
														value={{ label: editedData?.tcReference ?? ''}}
														sx={inputStyle}
														onChange={(event, value) => handleChange('tcReference', value ? value.label : '')}
													/>
												</FormControl>
											</Grid>
										</Grid>
									</Box>
								</Box>
								<PartEffectivity/>
							</TabPanel>
							<TabPanel value="ST" sx={{p:'0px'}}>
								<Box sx={{display:'flex', flexDirection:'column'}}>
									<Box 
										sx={{
											position:'relative',
											border:'1px solid black',
											mt:1,
											pb:1
										}}
									>
										<Box 
											sx={{
												display:'flex', 
												position:'absolute',
												top:-12,
												left:5
											}}
										>
											<Typography
												sx={{
													bgcolor:'#d6d3ce',
													fontWeight:'600',
													color:'black',
													fontSize:14
												}}
											>
												Start Threshold:
											</Typography>
											<Typography
												sx={{
													ml:3,
													bgcolor:'#d6d3ce',
													color:'red'
												}}
											>
												*
											</Typography>
										</Box>
										
										<Grid container spacing={0}>
											<Grid xs={4}></Grid>
											<Grid xs={1}>
												<FormControl sx={{...formControlStyle,alignItems:'center'}}>
													<FormLabel sx={labelStyle}>DY:</FormLabel>
													<Tooltip title='Days Selector'>
														<Checkbox 
															variant="outlined"
															color="neutral"
															sx={{
																fontSize:'14px',
																'--Checkbox-size':'15px',
																gap:0.4,
																'& span': {
																	borderRadius: '0px',
																	borderWidth:'1px',
																	borderColor:'black',
																	color:'black'
																}, 
															}}
															checked={editedData?.calendarStart ==='DY'?true:false}
															onChange={(event) => handleChange('calendarStart', event.target.checked?'DY':'')}
														/>
													</Tooltip>
												</FormControl>
											</Grid>
											<Grid xs={1}>
												<FormControl sx={{...formControlStyle,alignItems:'center'}}>
													<FormLabel sx={labelStyle}>MO:</FormLabel>
													<Tooltip title='Months Selector'>
														<Checkbox 
															variant="outlined"
															color="neutral"
															sx={{
																fontSize:'14px',
																'--Checkbox-size':'15px',
																gap:0.4,
																'& span': {
																	borderRadius: '0px',
																	borderWidth:'1px',
																	borderColor:'black',
																	color:'black'
																}, 
															}}
															checked={editedData?.calendarStart ==='MO'?true:false}
															onChange={(event) => handleChange('calendarStart', event.target.checked?'MO':'')}
														/>
													</Tooltip>
												</FormControl>
											</Grid>
											<Grid xs={1}>
												<FormControl sx={{...formControlStyle,alignItems:'center'}}>
													<FormLabel sx={labelStyle}>YR:</FormLabel>
													<Tooltip title='Years Selector'>
														<Checkbox 
															variant="outlined"
															color="neutral"
															sx={{
																fontSize:'14px',
																'--Checkbox-size':'15px',
																gap:0.4,
																'& span': {
																	borderRadius: '0px',
																	borderWidth:'1px',
																	borderColor:'black',
																	color:'black'
																}, 
															}}
															checked={editedData?.calendarStart ==='YR'?true:false}
															onChange={(event) => handleChange('calendarStart', event.target.checked?'YR':'')}
														/>
													</Tooltip>
												</FormControl>
											</Grid>
											<Grid xs={5}/>			
											
											<Grid xs={2}>
												<FormControl>
													<FormLabel sx={labelStyle}>FH:</FormLabel>
													<Input  
														type="number"
														slotProps={{
															input: {
																min:0
															}}
														}
														sx={inputStyle} 
														value={editedData?.fhStart ?? ''}
														onChange={(event) => handleChange('fhStart', event.target.valueAsNumber)}
													/>
												</FormControl>
											</Grid>
											<Grid xs={2}>
												<FormControl>
													<FormLabel sx={labelStyle}>FC:</FormLabel>
													<Input  
														type="number"
														slotProps={{
															input: {
																min:0
															}}
														}
														sx={inputStyle} 
														value={editedData?.fcStart ?? ''}
														onChange={(event) => handleChange('fcStart', event.target.valueAsNumber)}
													/>
												</FormControl>
											</Grid>
											<Grid xs={1}></Grid>
											<Grid xs={2}>
												<FormLabel sx={{...labelStyle, color:'transparent'}}>.</FormLabel>
												<FormControl>
													<Input  
														type="number"
														slotProps={{
															input: {
																min:0
															}}
														}
														sx={inputStyle} 
														value={editedData?.calendarValueStart ?? ''}
														onChange={(event) => handleChange('calendarValueStart', event.target.valueAsNumber)}
													/>
												</FormControl>
											</Grid>
											<Grid xs={5}>
												<FormControl sx={{mt:2}}>
													<Tooltip title='Component Replacement Task is Required Y/N'>
														<Checkbox 
															label=':Replacement Task Required'
															variant="outlined"
															color="neutral"
															sx={{
																fontSize:'14px',
																'--Checkbox-size':'15px',
																fontWeight:'bold',
																gap:0.4,
																'& span': {
																	borderRadius: '0px',
																	borderWidth:'1px',
																	borderColor:'black',
																	color:'black'
																}, 
															}}
															checked={editedData?.replacementTaskStart === 'Y'?true:false}
															onChange={(event) => handleChange('replacementTaskStart', event.target.checked?'Y':'N')}
														/>
													</Tooltip>
												</FormControl>
											</Grid>
											<Grid xs={7}>
												<FormControl>
													<FormLabel sx={labelStyle}>DOC. Reference Data:</FormLabel>
													<Input  
														sx={inputStyle} 
														value={editedData?.docReferenceStart ?? ''}
														onChange={(event) => handleChange('docReferenceStart', event.target.value)}
													/>
												</FormControl>
											</Grid>
											<Grid xs={5}>
												<FormControl>
													<FormLabel sx={labelStyle}>AMM Reference:</FormLabel>
													<Input  
														sx={inputStyle} 
														value={editedData?.ammReferenceStart ?? ''}
														onChange={(event) => handleChange('ammReferenceStart', event.target.value)}
													/>
												</FormControl>
											</Grid>
										</Grid>
									</Box>
								</Box>
								<PartEffectivity/>
							</TabPanel>
							<TabPanel value="FT" sx={{p:'0px'}}>
								<Box sx={{display:'flex', flexDirection:'column'}}>
									<Box 
										sx={{
											position:'relative',
											border:'1px solid black',
											mt:1,
											pb:1
										}}
									>
										<Box 
											sx={{
												display:'flex', 
												position:'absolute',
												top:-12,
												left:5
											}}
										>
											<Typography
												sx={{
													bgcolor:'#d6d3ce',
													fontWeight:'600',
													color:'black',
													fontSize:14
												}}
											>
												Finish Treshold:
											</Typography>
											<Typography
												sx={{
													ml:3,
													bgcolor:'#d6d3ce',
													color:'red'
												}}
											>
												*
											</Typography>
										</Box>
										
										<Grid container spacing={0}>
											<Grid xs={4}></Grid>
											<Grid xs={1}>
												<FormControl sx={{...formControlStyle,alignItems:'center'}}>
													<FormLabel sx={labelStyle}>DY:</FormLabel>
													<Tooltip title='Days Selector'>
														<Checkbox 
															variant="outlined"
															color="neutral"
															sx={{
																fontSize:'14px',
																'--Checkbox-size':'15px',
																gap:0.4,
																'& span': {
																	borderRadius: '0px',
																	borderWidth:'1px',
																	borderColor:'black',
																	color:'black'
																}, 
															}}
															checked={editedData?.calendarFinish ==='DY'?true:false}
															onChange={(event) => handleChange('calendarFinish', event.target.checked?'DY':'')}
														/>
													</Tooltip>
												</FormControl>
											</Grid>
											<Grid xs={1}>
												<FormControl sx={{...formControlStyle,alignItems:'center'}}>
													<FormLabel sx={labelStyle}>MO:</FormLabel>
													<Tooltip title='Months Selector'>
														<Checkbox 
															variant="outlined"
															color="neutral"
															sx={{
																fontSize:'14px',
																'--Checkbox-size':'15px',
																gap:0.4,
																'& span': {
																	borderRadius: '0px',
																	borderWidth:'1px',
																	borderColor:'black',
																	color:'black'
																}, 
															}}
															checked={editedData?.calendarFinish ==='MO'?true:false}
															onChange={(event) => handleChange('calendarFinish', event.target.checked?'MO':'')}
														/>
													</Tooltip>
												</FormControl>
											</Grid>
											<Grid xs={1}>
												<FormControl sx={{...formControlStyle,alignItems:'center'}}>
													<FormLabel sx={labelStyle}>YR:</FormLabel>
													<Tooltip title='Years Selector'>
														<Checkbox 
															variant="outlined"
															color="neutral"
															sx={{
																fontSize:'14px',
																'--Checkbox-size':'15px',
																gap:0.4,
																'& span': {
																	borderRadius: '0px',
																	borderWidth:'1px',
																	borderColor:'black',
																	color:'black'
																}, 
															}}
															checked={editedData?.calendarFinish ==='YR'?true:false}
															onChange={(event) => handleChange('calendarFinish', event.target.checked?'YR':'')}
														/>
													</Tooltip>
												</FormControl>
											</Grid>
											<Grid xs={5}/>			
											
											<Grid xs={2}>
												<FormControl>
													<FormLabel sx={labelStyle}>FH:</FormLabel>
													<Input  
														type="number"
														slotProps={{
															input: {
																min:0
															}}
														}
														sx={inputStyle} 
														value={editedData?.fhFinish ?? ''}
														onChange={(event) => handleChange('fhFinish', event.target.valueAsNumber)}
													/>
												</FormControl>
											</Grid>
											<Grid xs={2}>
												<FormControl>
													<FormLabel sx={labelStyle}>FC:</FormLabel>
													<Input  
														type="number"
														slotProps={{
															input: {
																min:0
															}}
														}
														sx={inputStyle} 
														value={editedData?.fcFinish ?? ''}
														onChange={(event) => handleChange('fcFinish', event.target.valueAsNumber)}
													/>
												</FormControl>
											</Grid>
											<Grid xs={1}></Grid>
											<Grid xs={2}>
												<FormLabel sx={{...labelStyle, color:'transparent'}}>.</FormLabel>
												<FormControl>
													<Input  
														type="number"
														slotProps={{
															input: {
																min:0
															}}
														}
														sx={inputStyle} 
														value={editedData?.calendarValueFinish ?? ''}
														onChange={(event) => handleChange('calendarValueFinish', event.target.valueAsNumber)}
													/>
												</FormControl>
											</Grid>
											<Grid xs={5}>
												<FormControl sx={{mt:2}}>
													<Tooltip title='Component Replacement Task is Required Y/N'>
														<Checkbox 
															label=':Replacement Task Required'
															variant="outlined"
															color="neutral"
															sx={{
																fontSize:'14px',
																'--Checkbox-size':'15px',
																fontWeight:'bold',
																gap:0.4,
																'& span': {
																	borderRadius: '0px',
																	borderWidth:'1px',
																	borderColor:'black',
																	color:'black'
																}, 
															}}
															checked={editedData?.replacementTaskFinish === 'Y'?true:false}
															onChange={(event) => handleChange('replacementTaskFinish', event.target.checked?'Y':'N')}
														/>
													</Tooltip>
												</FormControl>
											</Grid>
											<Grid xs={7}>
												<FormControl>
													<FormLabel sx={labelStyle}>DOC. Reference Data:</FormLabel>
													<Input  
														sx={inputStyle} 
														value={editedData?.docReferenceFinish ?? ''}
														onChange={(event) => handleChange('docReferenceFinish', event.target.value)}
													/>
												</FormControl>
											</Grid>
											<Grid xs={5}>
												<FormControl>
													<FormLabel sx={labelStyle}>AMM Reference:</FormLabel>
													<Input  
														sx={inputStyle} 
														value={editedData?.ammReferenceFinish ?? ''}
														onChange={(event) => handleChange('ammReferenceFinish', event.target.value)}
													/>
												</FormControl>
											</Grid>
										</Grid>
									</Box>
								</Box>
								<PartEffectivity/>
							</TabPanel>
							<TabPanel value="RM" sx={{p:'0px'}}>
								{showReplmMaterialsEditore ? (
									<ReplmMaterialsEditor/>
								) : (
									<Box 
										sx={{
											position:'relative',
											border:'1px solid black',
											mt:1,
										}}
									>
										<Box 
											sx={{
												display:'flex', 
												position:'absolute',
												top:-12,
												left:5
											}}
										>
											<Typography
												sx={{
													bgcolor:'#d6d3ce',
													fontWeight:'600',
													color:'black',
													fontSize:14
												}}
											>
												Materials:
											</Typography>
										</Box>
										<Grid container spacing={0} sx={{ flexGrow: 1, height:380 }}>
											<Grid xs={12}>
												<Box
													sx={{
														border:'1px solid #d2dbe4',
														height:'350px',
														mx:1,
														mt:2.5,
														borderRadius:'0px',
														bgcolor:'#c6ffff',
														overflow:'auto'
													}}
												>
													{replmMaterialsList?.length === 0 ? (
														<Box>
															No Materials Were Found !
														</Box>
													) : (
														<TableJoy
															borderAxis="none"
															stickyHeader
															hoverRow
															sx={{
																'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
																'--TableCell-paddingY': '1px',
																"--TableCell-height": "24px",
																'--TableCell-paddingX': '5px',
																tableLayout: 'auto', 
																width:'auto',
																borderRadius:'0px',
																lineHeight:1
															}}
														>
															<tbody>
																{replmMaterialsList?.map((row) => (
																	<tr
																		key={row.id}
																		//onClick={() => setSelectedGroupForModule(row)}
																		style={{ 
																			//backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
																			//color: selectedGroupForModule === row ? 'white' : 'black',
																		}}
																	>
																		<td style={rowStyle}>{row.id}</td>
																		<td style={rowStyle}>{row.pn}</td>
																		<td style={rowStyle}>{row.description}</td>
																		<td style={rowStyle}>{row.qty}</td>
																		<td style={rowStyle}>{row.storeUnit}</td>
																		<td style={rowStyle}>{row.materialType}</td>
																		<td style={{width:'100%'}}></td>
																	</tr>
																))}
															</tbody>
														</TableJoy>
													)}
												</Box>
											</Grid>
											<Button
												onClick={()=>setShowReplmMaterialsEditore(true)}
												color='neutral'
												sx={{
													borderRadius:0,
													height: 25, 
													minHeight: 0,
													bgcolor:'#d6d3ce',
													color:'black',
													border:'2px outset grey',
													position:'absolute',
													right:9,
													top:-7
												}}
											>
												Edit
											</Button>
										</Grid>
									</Box>
								)}
							</TabPanel>
							<TabPanel value="RT" sx={{p:'0px'}}>
								{showReplmToolsEditore ? (
									<ReplmToolsEditor/>
								) :(
									<Box 
										sx={{
											position:'relative',
											border:'1px solid black',
											mt:1,
										}}
									>
										<Box 
											sx={{
												display:'flex', 
												position:'absolute',
												top:-12,
												left:5
											}}
										>
											<Typography
												sx={{
													bgcolor:'#d6d3ce',
													fontWeight:'600',
													color:'black',
													fontSize:14
												}}
											>
												Tools:
											</Typography>
										</Box>
										<Grid container spacing={0} sx={{ flexGrow: 1, height:380 }}>
											<Grid xs={12}>
											<Box
													sx={{
														border:'1px solid #d2dbe4',
														height:'350px',
														mx:1,
														mt:2.5,
														borderRadius:'0px',
														bgcolor:'#c6ffff',
														overflow:'auto'
													}}
												>
													{replmToolsList.length === 0 ? (
														<Box>
															No Tools Were Found !
														</Box>
													) : (
														<TableJoy
															aria-labelledby="tableTitle"
															borderAxis="none"
															stickyHeader
															hoverRow
															sx={{
																'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
																'--TableCell-paddingY': '1px',
																"--TableCell-height": "24px",
																'--TableCell-paddingX': '5px',
																tableLayout: 'auto', 
																width:'auto',
																borderRadius:'0px',
															}}
														>
															<tbody>
																{replmToolsList.map((row) => (
																	<tr
																		key={row.id}
																		//onClick={() => setSelectedGroupForModule(row)}
																		style={{ 
																			//backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
																			//color: selectedGroupForModule === row ? 'white' : 'black',
																		}}
																	>
																		<td style={rowStyle}>{row.id}</td>
																		<td style={rowStyle}>{row.oemPn}</td>
																		<td style={rowStyle}>{row.supplierPn}</td>
																		<td style={rowStyle}>{row.optionalPn}</td>
																		<td style={rowStyle}>{row.description}</td>
																		<td style={{width:'100%'}}></td>
																	</tr>
																))}
															</tbody>
														</TableJoy>
													)}
												</Box>
											</Grid>
											<Button
												onClick={()=>setShowReplmToolsEditore(true)}
												color='neutral'
												sx={{
													borderRadius:0,
													height: 25, 
													minHeight: 0,
													bgcolor:'#d6d3ce',
													color:'black',
													border:'2px outset grey',
													position:'absolute',
													right:9,
													top:-7
												}}
											>
												Edit
											</Button>
										</Grid>
									</Box>
								)}
							</TabPanel>
							<TabPanel value="RJIC" sx={{p:'0px'}}>
								<Box 
									sx={{
										position:'relative',
										border:'1px solid black',
										mt:1
									}}
								>
									<Box 
										sx={{
											display:'flex', 
											position:'absolute',
											top:-8,
											left:5
										}}
									>
										<Typography
											sx={{
												bgcolor:'#d6d3ce',
												fontWeight:'600',
												color:'black',
												fontSize:14,
												lineHeight:1
											}}
										>
											JIC Procedure Editor:
										</Typography>
									</Box>
									<Grid container spacing={0} sx={{ flexGrow: 1, height:570, pt:0.6, px:1 }}>
										<Grid xs={4} sx={{display:'flex', alignItems:'center'}}>
											<FormControl sx={formControlStyle}>
												<FormLabel sx={labelStyle}>Treatment:</FormLabel>
												<Autocomplete
													disableClearable
													options={[]}
													value={{ label: editedData?.treatment ?? ''}}
													sx={inputStyle}
												/>
											</FormControl>
										</Grid>
										<Grid xs={6}/>
										<Grid xs={2} sx={{display:'flex', alignItems:'center'}}>
											<Button
												//onClick={handleConfirm}
												color='neutral'
												sx={{
													borderRadius:0,
													height: 25, 
													minHeight: 0,
													bgcolor:'#d6d3ce',
													color:'black',
													border:'2px outset grey',
												}}
											>
												Save
											</Button>
										</Grid>

										<Grid xs={12}>
											<Box
												sx={{
													border:'1px solid #d2dbe4',
													height:'500px',
													mx:0.5,
													mt:0.1,
													borderRadius:'0px',
													bgcolor:'white',
													overflow:'auto'
												}}
											>
												{/** 
												<Table
													aria-labelledby="tableTitle"
													borderAxis="none"
													stickyHeader
													hoverRow
													sx={{
														'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
														'--TableCell-paddingY': '1px',
														"--TableCell-height": "24px",
														'--TableCell-paddingX': '5px',
														tableLayout: 'auto', 
														width:'auto',
														borderRadius:'0px'
													}}
												>
													<tbody>
														
														{moduleList.map((row) => (
															<tr
																key={row.id}
																onClick={() => setSelectedGroupForModule(row)}
																style={{ 
																	backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
																	color: selectedGroupForModule === row ? 'white' : 'black',
																}}
															>
																<td style={rowStyle}>{row.id}</td>
																<td style={rowStyle}>{row.groupName}</td>
																<td style={rowStyle}>{row.groupSta}</td>
																<td style={rowStyle}>{row.permission}</td>
																<td style={rowStyle}>{row.activate}</td>
																<td style={rowStyle}>{row.remarks}</td>
															</tr>
														))}
													</tbody>
												</Table>
												*/}
											</Box>
										</Grid>
									</Grid>
								</Box>
							</TabPanel>
							<TabPanel value="Inst" sx={{p:'0px'}}>
								<InstructionsEditor/>
							</TabPanel>
						</Tabs>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}